import { useCallback, useContext, useEffect, useState } from "react";
import { replaceWithParams } from "../utils/queryBuilder";
import { duckQuery } from "../services/api-server/deltashare";
import { CustomDashboardContext } from "../contexts/context";

interface useVisualDataProps {
	visual?: any;
	params?: any;
}

const useVisualData = ({ visual, params }: useVisualDataProps) => {
	const [data, setData] = useState<Array<any>>([]);
	const [loading, setLoading] = useState(false);
	const { state: dashboardState, data_unauthorized } = useContext(
		CustomDashboardContext
	);
	const { measures, loaded } = useContext(CustomDashboardContext);

	const updateData = useCallback(() => {
		if (loaded && visual?.measure && measures.length !== 0) {
			const measure = measures.find(
				(measure: any) => measure.id === visual?.measure
			);

			// QUERY STATEMENT
			let updatedQueryStringParams = replaceWithParams(
				measure?.queryStatement,
				params
			);

			setLoading(true);
			duckQuery(updatedQueryStringParams, data_unauthorized)
				.then((data: any) => {
					// if (data?.response.length !== 0) {
					setData(data?.response);
					setLoading(false);
					// }
				})
				.catch((error) => {
					setData([]);
					setLoading(false);
					// console.error(error);
				});
		}
	}, [visual?.measure, measures, params, loaded]);

	useEffect(() => {
		updateData();
	}, [updateData]);

	useEffect(() => {
		if (dashboardState.slice) {
			updateData();
		}
	}, [dashboardState.slice]);

	return { data, isLoading: loading };
};

export default useVisualData;
