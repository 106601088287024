import { Modal, ModalProps, Tabs } from "antd";
import { useCallback, useContext } from "react";
import ContentTab from "../ContentTab";
import { addVisual, updateVisual } from "../../services/api-server/visuals";
import Emitter from "../../services/EventEmitter";
import { CustomDashboardContext, VisualContext } from "../../contexts/context";
import { TargetProps } from "../TargetsConfig";

interface VisualModalProps extends ModalProps {}

const VisualModal = ({ ...restProps }: VisualModalProps) => {
	const { state: visualState, dispatch: visualDispatch } =
		useContext(VisualContext);
	const { state: customDashboardState } = useContext(CustomDashboardContext)

	const handleClose = useCallback(() => {
		visualDispatch({ type: "RESET_VISUAL_MODAL" });
	}, []);

	const handleSaveVisual = () => {
		const cleanedTargets = cleanTargets(visualState?.visual?.targets || {});
		const visual = {
			...visualState.visual,
			targets: cleanedTargets,
			data: { ...visualState.visual.data },
		}

		if (visualState.new) {
			// Handling saving new visual
			addVisual(visual, customDashboardState.menuKey).then((response) => {
				Emitter.emit("alert", {
					type: "success",
					message: "Visual created successfully",
					description: "You have successfully created a visual",
					timeout: 5000,
				});
				// console.log(response);
			})
				.catch((error) => {
					Emitter.emit("alert", {
						type: "error",
						message: "Unsuccessful saving visual",
						description: "There was an error while saving visual",
						timeout: 5000,
					});
					console.error(error);
				})
				.finally(() => {
					visualDispatch({ type: "RESET_VISUAL_MODAL" });
				});
		} else {
			// Handling saving existing visual
			updateVisual(visual, customDashboardState.menuKey).then((response) => {
				Emitter.emit("alert", {
					type: "success",
					message: "Your visual had been updated",
					description: "You have successfully updated a visual",
					timeout: 5000,
				});
				// console.error(error);
			})
				.catch((error) => {
					Emitter.emit("alert", {
						type: "error",
						message: "Unsuccessful saving visual",
						description: "There was an error while saving visual",
						timeout: 5000,
					});
					console.error(error);
				})
				.finally(() => {
					visualDispatch({ type: "RESET_VISUAL_MODAL" });
				});
		}
	};

	const tabItems = [
		{
			key: "content",
			label: "Content",
			children: <ContentTab />,
		},
		// {
		// 	key: "description",
		// 	label: "Description",
		// 	children: <DescriptionTab />,
		// },
	];

	const isSavable = useCallback(() => {
		const { title } = visualState.visual;

		let savable = false;
		if (title && title !== "") savable = true;

		return savable;
	}, [visualState.visual]);

	return (
		<Modal
			{...restProps}
			onCancel={handleClose}
			onOk={handleSaveVisual}
			okText={"Save"}
			okButtonProps={{ disabled: !isSavable() }}
			width={"70%"}
			title={visualState.new ? "New Visual" : "Edit Visual"}
			maskClosable={false}
			destroyOnClose
			zIndex={30001}
		>
			<Tabs items={tabItems} />
		</Modal>
	);
};

const cleanTargets = (targets: TargetProps) => {
	const targetsEntries = Object.entries(targets).map(([k, v]: any) => {
		const targetEntries = Object.entries(v);
		const cleanedTarget = targetEntries?.map(([_k, _v]) => {
			// excluded type casting the _id to number
			if (_k === "_id") {
				return [_k, _v];
			}
			return [_k, Number(_v) ? Number(_v) : 0];
		});

		const cleanedTargetFromEntries = Object.fromEntries(cleanedTarget);

		return [k, cleanedTargetFromEntries];
	});

	const targetsFromEntries = Object.fromEntries(targetsEntries);

	return targetsFromEntries;
};
export default VisualModal;
