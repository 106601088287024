import {
	DashboardOutlined,
	FullscreenOutlined,
	MoreOutlined,
} from "@ant-design/icons";
import { Dropdown, MenuProps } from "antd";
import { Visual } from "../types/Visual";
import { useContext } from "react";
import { CustomDashboardContext } from "../contexts/context";

type VisualActionsDropdownType = {
	visual: Visual;
};

const VisualActionsDropdown = ({ visual }: VisualActionsDropdownType) => {
	const { dispatch: customDashboardDispatch } = useContext(
		CustomDashboardContext
	);

	const handleShowMenusure = () => {
		customDashboardDispatch({ type: "EDIT_MEASURE", payload: visual.measure });
	};

	const items: MenuProps["items"] = [
		{
			key: "measure",
			label: "View measure",
			icon: <DashboardOutlined style={{ fontSize: 16 }} />,
			onClick: handleShowMenusure,
		},
	];
	return (
		<Dropdown menu={{ items }} trigger={["click"]}>
			<MoreOutlined />
		</Dropdown>
	);
};

export default VisualActionsDropdown;
