import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import {
	Button,
	Drawer,
	Dropdown,
	Layout,
	Popconfirm,
	Popover,
	Progress,
	Space,
	Spin,
	Tabs,
	Tooltip,
} from "antd";
import { capitalize } from "../utils/dataTools";
import {
	DRAWER_KEYS,
	initialState,
	reducer,
} from "../reducers/customDashboardReducer";
import MeasureModal from "../components/modal/MeasureModal";
import { CheckCircleOutlined, DownOutlined } from "@ant-design/icons";
import MeasureTab from "../components/MeasureTab";
import VisualTab from "../components/VisualTab";
import { Responsive, WidthProvider } from "react-grid-layout";
import { CustomDashboardContext } from "../contexts/context";
import useMenu from "../hooks/useMenu";
import { saveMenu, saveMenuItem } from "../services/api-server/menu";
import Emitter from "../services/EventEmitter";
import useCustomDashboard from "../hooks/useCustomDashboard";
import SlicerInputs from "../components/SlicerInputs";
import { initParameters } from "../utils/queryBuilder";
import VisualGroupsTab from "../components/VisualGroupsTab";
import { CustomLayout } from "../types/CustomLayout";
import DashboardItem from "../components/DashboardItem";
import { v4 as uuid } from "uuid";
import { updateVisual } from "../services/api-server/visuals";
import { updateVisualGroup } from "../services/api-server/visualsGroups";
import { socket } from "../utils/socket";
import { ComponentHook } from "../utils/components";
import { useMainContext } from "../contexts/MainContext";
import DrilldownModal from "../components/modal/DrilldownModal";
import { removeFromLS } from "../utils/utils";
import useLayoutChange from "../hooks/useLayoutChange";
const { Header } = Layout;

const ResponsiveGridLayout = WidthProvider(Responsive);
const CustomDashboard = (props: any) => {
	const [outerRendered, setOuterRendered] = useState(false);
	const {
		dashboard_layout,
		dashboard_layouts,
		data_unauthorized = [],
		schema_unauthorized = [],
		key = "",
	} = props.params.mitem;

	const { isOwner, isViewer, isAdmin } = props;
	const [state, dispatch] = useReducer(reducer, initialState);

	// handling saving to local storage when layout changes
	useLayoutChange(state.layout, state.editMode, state.dirty);

	const { loading, measures, visuals, progress, vessels, visualGroups } =
		useCustomDashboard(key);
	const { menu } = useMenu();
	const { metadatasLoading: metadataLoading, metadatas: tables } =
		useMainContext();

	// FUNCTIONS
	const closeMeasureModal = useCallback(() => {
		dispatch({ type: "MEASUREMODAL", payload: false });
	}, []);

	const handleButtonClick = useCallback((drawer: string) => {
		dispatch({ type: "DRAWER", payload: drawer });
	}, []);

	const handleEditDashboard = useCallback(() => {
		dispatch({ type: "EDIT_DASHBOARD", payload: !state.editMode });
	}, [state.editMode]);

	const handleDrawerChange = useCallback((activeKey: string) => {
		dispatch({ type: "DRAWER", payload: activeKey });
	}, []);

	const onDrop = (
		layout: ReactGridLayout.Layout[],
		item: ReactGridLayout.Layout,
		e: any
	) => {
		// check if the dropped item is a visual
		const visualId = e.dataTransfer.getData("visual_id");

		// TODO: handle updating visual's layoutIds
		// need to generate the layout id here and pass to ondrop
		const newLayoutId = uuid();
		const visual = state.visuals.find(
			(_visual: any) => _visual.id === visualId
		);
		if (visual) {
			const updatedVisual = {
				...visual,
				layoutIds: [...visual?.layoutIds, newLayoutId],
			};

			dispatch({ type: "UPDATE_VISUAL", payload: updatedVisual });
		}

		// TODO: handle dropping only visuals
		if (state.visuals?.find((_visual: any) => _visual.id === visualId)) {
			// console.log("drop");

			dispatch({
				type: "ON_DROP",
				payload: { layout, item, visualId, newLayoutId },
			});
		}
	};

	const handleSave = async () => {
		const { key = "" } = props?.params?.mitem;

		// filter "Add visual" tile before saving layout
		const filteredLayout = state.layout.filter((el: any) => !el?.add);

		const updatedDashboard = {
			...props?.params?.mitem,
			dashboard_layout: filteredLayout,
			dashboard_layouts: {
				...state.layouts,
				[state.breakpoint]: filteredLayout,
			},
		};

		try {
			const response = await saveMenuItem(updatedDashboard)

			for (const visual of state.visuals) {
				if (state.updatedVisualIds.includes(visual.id))
					await updateVisual(visual, key);
			}

			for (const group of state.groups) {
				if (state.updatedVisualGroupIds.includes(group.id))
					await updateVisualGroup(group);
			}

			Emitter.emit("alert", {
				type: "success",
				message: "Dashboard saved successfully",
				description: "You have successfully saved this dashboard",
				timeout: 5000,
			});

			socket.emit("UPDATE_MENU", key);
			// console.log(response);

			dispatch({ type: "DASHBOARD_SAVE_END", payload: false });
		} catch (error) {
			// console.error(error);
		}
	};

	const handleDiscard = useCallback(() => {
		dispatch({
			type: "DISCARD_CHANGES",
			payload: {
				prevLayout: dashboard_layout || [],
				prevLayouts: dashboard_layouts || {},
				preVisuals: visuals,
				prevVisualGroups: visualGroups,
			},
		});
	}, [dashboard_layout, dashboard_layouts, visuals, visualGroups]);

	const handleReturn = useCallback(() => {
		dispatch({ type: "EDIT_MODE", payload: false });
	}, []);

	const onLayoutChange = (
		currentLayout: ReactGridLayout.Layout[],
		allLayouts: ReactGridLayout.Layouts
	) => {
		// console.log({ currentLayout });
		// console.log({ allLayouts });

		dispatch({
			type: "LAYOUT_CHANGE",
			payload: { layout: currentLayout, allLayouts },
		});
	};

	const onBreakpointChange = useCallback((newBreakpoint: string) => {
		dispatch({ type: "ON_BREAKPOINT_CHANGE", payload: newBreakpoint });
	}, []);

	const sliceData = () => {
		dispatch({ type: "SLICE_DATA" });
	};

	/**
	 *
	 * @param item layout item
	 * @returns visual component
	 *
	 * finds the visual using with visual_id from the list of visuals
	 */
	const generateElement = (item: CustomLayout) => {
		return (
			<div
				key={item.i}
				style={{ display: "flex" }}
				className={`main-layout-${item.i}}`}
			>
				{outerRendered ? (
					<DashboardItem item={item} onLayoutChange={() => {}} />
				) : null}
			</div>
		);
	};

	const renderSaveEdit = () => {
		if (!isOwner) return null;

		if (!state.editMode)
			return (
				<Space>
					{/* Only component owners can see this setting button */}
					<Popover
						placement="left"
						overlayClassName="edit-mode-disabled-popover"
						content={
							state.breakpoint !== "lg"
								? "Dashboard edits can only be performed from a desktop application. Please resize your window."
								: ""
						}
					>
						<Button
							disabled={state.breakpoint !== "lg"}
							className="edit-button"
							onClick={handleEditDashboard}
						>
							Edit dashboard
						</Button>
					</Popover>
				</Space>
			);

		return (
			<Space>
				<Button
					loading={state.loading}
					disabled={!state.dirty}
					onClick={handleSave}
					type="primary"
					icon={<CheckCircleOutlined />}
				>
					Save
				</Button>
				{state.dirty ? (
					<Popconfirm
						title="Unsave changes"
						description="You're about to discard your changes. Are you sure you want to continue?"
						onConfirm={handleDiscard}
					>
						<Button>Cancel</Button>
					</Popconfirm>
				) : (
					<Button onClick={handleReturn}>Cancel</Button>
				)}
			</Space>
		);
	};

	// MEMO
	const tabItems = useMemo(() => {
		const items: Array<any> = [];
		for (const key in DRAWER_KEYS) {
			items.push({
				key: DRAWER_KEYS[key],
				label: capitalize(DRAWER_KEYS[key]),
				children: getTabContent(key),
			});
		}
		return items;
	}, [DRAWER_KEYS, state.drawerKey]);

	// EFFECTS
	// Logging purposes
	// useEffect(() => {
	// 	console.log(state);
	// }, [state]);

	useEffect(() => {
		if (vessels?.length !== 0) {
			const defaultSlicerParams = {
				...initParameters,
				vessel_id: vessels?.map((vessel) => vessel.id),
			};
			dispatch({
				type: "LOAD_DASHBOARD",
				payload: {
					layout: dashboard_layout || [],
					layouts: dashboard_layouts || { lg: [] },
					defaultSlicerParams,
				},
			});
		}
	}, [dashboard_layout, dashboard_layouts, vessels]);

	useEffect(() => {
		dispatch({ type: "SET_VISUAL_GROUPS", payload: visualGroups });
		dispatch({ type: "SET_VISUALS", payload: visuals });
		dispatch({ type: "SET_MENU_KEY", payload: key });
	}, [loading, visualGroups, visuals, key]);

	useEffect(() => {
		// clear any local storage layout
		removeFromLS("layout");

		const timeout = setTimeout(() => {
			setOuterRendered(true);
		}, 2000);

		return () => {
			clearTimeout(timeout);
		};
	}, []);

	return (
		<CustomDashboardContext.Provider
			value={{
				state,
				dispatch,
				visuals,
				measures,
				loaded: !loading,
				metadataLoading,
				tables,
				menu,
				data_unauthorized,
				schema_unauthorized,
				isAdmin,
				isOwner,
				isViewer,
				visual_groups: visualGroups,
			}}
		>
			<ComponentHook menuProps={props.params.mitem} />
			{loading ? (
				<Space
					style={{
						width: "100%",
						height: "100%",
						justifyContent: "center",
					}}
				>
					{progress !== 100 ? (
						<Progress type="dashboard" percent={progress} />
					) : (
						<Spin />
					)}
				</Space>
			) : (
				<Layout
					style={{ background: "transparent" }}
					key={key}
					className="custom_dashboard_layout"
				>
					<Header
						style={{
							// padding: "8px 24px",
							padding: 16,
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
							border: "1px solid #112A45",
							borderTop: "none",
						}}
					>
						<Space>
							{/* params that were set in the measure
						won't be used in the dashboard */}
							<SlicerInputs
								onChange={(values) => {
									dispatch({
										type: "SLICER_VAL_CHANGE",
										payload: values,
									});
								}}
								menuItem={props.params.mitem}
							/>

							<Button
								rootClassName={`slicer-run-button ${!state.slicerModified ? "success" : ""
									}`}
								onClick={sliceData}
								type={"primary"}
								disabled={Object.keys(state.tempSliceValues).length === 0}
							>
								Run
							</Button>
						</Space>
						<Space>
							<Space>{renderSaveEdit()}</Space>
							<Dropdown
								disabled={metadataLoading}
								trigger={["click"]}
								menu={{
									items: [
										{
											key: "measures",
											label: "Measures",
											onClick: () => handleButtonClick(DRAWER_KEYS.MEASURES),
										},
										{
											key: "visuals",
											label: "Visuals",
											onClick: () => handleButtonClick(DRAWER_KEYS.VISUALS),
										},
										{
											key: "groups",
											label: "Groups",
											onClick: () =>
												handleButtonClick(DRAWER_KEYS.VISUAL_GROUPS),
										},
									],
								}}
							>
								<Button loading={metadataLoading}>
									View elements <DownOutlined />
								</Button>
							</Dropdown>
						</Space>
					</Header>
					<MeasureModal
						open={state.measureModal}
						onCancel={closeMeasureModal}
						closeModal={closeMeasureModal}
						measureId={state.currentMeasure}
						editMode={!state.newMeasure}
					/>
					<DrilldownModal />
					<Drawer
						rootClassName="custom-dashboard-drawer"
						open={state.drawer}
						width={"50%"}
						onClose={() => dispatch({ type: "DRAWER", payload: null })}
						mask={false}
						styles={{
							footer: { display: "flex", justifyContent: "end" },
							body: { height: "inherit" },
						}}
					>
						<Spin
							spinning={loading}
							style={{ height: "100%", position: "initial" }}
						>
							<Tabs
								style={{ height: "100%" }}
								items={tabItems}
								activeKey={state.drawerKey}
								onChange={handleDrawerChange}
							/>
						</Spin>
					</Drawer>
					<ResponsiveGridLayout
						className="layout"
						isDraggable={state.editMode}
						isDroppable={state.editMode}
						isResizable={state.editMode}
						style={{ height: "100%", overflowY: "scroll" }}
						onDrop={onDrop}
						onLayoutChange={onLayoutChange}
						layouts={{ lg: state.layouts.lg }} // always supply
						onBreakpointChange={onBreakpointChange}
						rowHeight={80}
						breakpoints={{ lg: 1200, md: 768, sm: 576 }}
						cols={{ lg: 6, md: 3, sm: 1 }}
						onResizeStart={() => dispatch({ type: "IS_RESIZE", payload: true })}
						onResizeStop={() => dispatch({ type: "IS_RESIZE", payload: false })}
						onDragStart={() => dispatch({ type: "IS_DRAG", payload: true })}
						onDragStop={() => dispatch({ type: "IS_DRAG", payload: false })}
						draggableCancel=".draggableCancel"
						droppingItem={{ i: "__dropping-elem__", h: 3, w: 1 }}
					>
						{state?.layout?.map(generateElement)}
					</ResponsiveGridLayout>
				</Layout>
			)}
		</CustomDashboardContext.Provider>
	);
};

// returns the updated menu
const updateMenuItemByKey = (
	menu: any,
	key: string,
	updatedProperties: any
) => {
	return menu.map((menuItem: any) => {
		if (menuItem.key === key) {
			return { ...menuItem, ...updatedProperties };
		} else if (menuItem.children) {
			return {
				...menuItem,
				children: updateMenuItemByKey(
					menuItem.children,
					key,
					updatedProperties
				),
			};
		}
		return menuItem;
	});
};

// render the content depending on the drawer key selected
const getTabContent = (key: string) => {
	switch (DRAWER_KEYS[key]) {
		case DRAWER_KEYS.DASHBOARD:
			return DRAWER_KEYS.DASHBOARD;
		case DRAWER_KEYS.VISUALS:
			return <VisualTab />;
		case DRAWER_KEYS.MEASURES:
			return <MeasureTab />;
		case DRAWER_KEYS.VISUAL_GROUPS:
			return <VisualGroupsTab />;
		default:
			break;
	}
};

export default CustomDashboard;
