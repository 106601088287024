import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Dropdown, Input, MenuProps, Table, TableProps } from "antd";
import { compareSelectOptions } from "../utils/queryBuilder";

interface CustomSelectDropdownProps {
	onChange?: (selectKeys: any) => void;
	options?: Array<any>;
	fieldNames?: { label: string; value: any };
	value?: any;
	style?: React.CSSProperties;
	overlayStyle?: React.CSSProperties;
	showName?: boolean;
}

const CustomSelectDropdown = ({
	onChange = (selectKeys: React.Key[]) => {},
	options = [],
	fieldNames,
	value,
	style,
	overlayStyle,
	showName,
}: CustomSelectDropdownProps) => {
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

	const dataSource = useMemo(() => {
		return (
			options
				?.map((option) => ({ key: option.value, ...option }))
				.sort(compareSelectOptions) || []
		);
	}, [options]);

	const columns = useMemo(() => {
		const cols: Array<any> = [];

		const sampleData = options?.[0] || {};
		for (const field in sampleData) {
			if (fieldNames?.value === field) {
				cols.push({ dataIndex: field, key: field, title: fieldNames?.label });
			}
		}

		return cols;
	}, [options]);

	const onSelectChange = useCallback(
		(newSelectedRowKeys: React.Key[]) => {
			setSelectedRowKeys(newSelectedRowKeys);
			if (typeof onChange === "function") onChange(newSelectedRowKeys);
		},
		[onChange]
	);

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
	};

	const tableProps: TableProps<any> = {
		dataSource,
		columns,
		style: { width: 212 },
		rowSelection,
		pagination: false,
		size: "small",
	};

	const items: MenuProps["items"] = useMemo(() => {
		if (options?.length === 0) return [];
		return [
			{
				label: <Table {...tableProps} />,
				value: "table",
				type: "group",
			},
		];
	}, [options, tableProps]);

	const getTextValue = useMemo(() => {
		if (selectedRowKeys.length === options.length) {
			return "All";
		} else if (selectedRowKeys.length > 1) {
			return "Multiple Vessels";
		} else if (selectedRowKeys.length === 1) {
			const found = dataSource?.find((d) => selectedRowKeys?.includes(d?.key));
			return showName ? found?.label : found?.key;
		} else {
			return "None";
		}
	}, [selectedRowKeys, options, dataSource]);

	useEffect(() => {
		setSelectedRowKeys(value);
	}, [value]);

	return (
		<Dropdown menu={{ items }} trigger={["click"]} overlayStyle={overlayStyle}>
			<Input value={getTextValue} style={style} />
		</Dropdown>
	);
};

export default CustomSelectDropdown;
