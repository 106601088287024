import { Breadcrumb, Input, Space, Tabs, Typography } from "antd";
import SchemaTable from "./SchemaTable";
import DataSourceTable from "./DataSourceTable";
import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import { MeasureContext } from "../contexts/context";
import { ACTIONTYPES, MODE_TYPE } from "../reducers/measureReducer";
import FieldTable from "./FieldTable";
import FieldSelectionTable from "./FieldSelectionTable";
import PreviewTable from "./PreviewTable";

const { Title, Link } = Typography;
const { Search } = Input;

const DataExplorer = () => {
	const [searchVal, setSearchVal] = useState("");
	const { state: measureState, dispatch: measureDispatch } =
		useContext(MeasureContext);
	const getPlaceholder = useMemo(() => {
		switch (measureState.mode) {
			case MODE_TYPE.SCHEMA:
				return "schema";
			case MODE_TYPE.DATASET:
				return "dataset";
			case MODE_TYPE.FIELD:
				return "field";
			default:
				return "";
		}
	}, [measureState.mode]);

	const handleBreadcrumbClick = useCallback((mode: string) => {
		switch (mode) {
			case MODE_TYPE.SCHEMA:
				measureDispatch({ type: ACTIONTYPES.VIEW_SCHEMAS });
				break;
			case MODE_TYPE.DATASET:
				measureDispatch({ type: ACTIONTYPES.VIEW_DATASETS });

				break;
			case MODE_TYPE.FIELD:
				measureDispatch({ type: ACTIONTYPES.VIEW_FIELDS });
				break;

			default:
				break;
		}
	}, []);

	const handleSearchChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			if (e.target.value === "") {
				handleSearch(e.target.value);
			}
			setSearchVal(e.target.value);
		},
		[]
	);

	const handleSearch = useCallback((searchVal: string) => {
		measureDispatch({ type: ACTIONTYPES.ONSEARCH, payload: searchVal });
	}, []);

	const handleTabChange = useCallback((activeKey: string) => {
		measureDispatch({ type: ACTIONTYPES.TAB, payload: activeKey });
	}, []);

	const renderTable = (mode: string) => {
		switch (mode) {
			case MODE_TYPE.SCHEMA:
				return <SchemaTable />;
			case MODE_TYPE.DATASET:
				return <DataSourceTable />;
			case MODE_TYPE.FIELD:
				return (
					<>
						<Tabs
							onChange={handleTabChange}
							items={[
								{ key: "fields", label: "Fields", children: <FieldTable /> },
								{
									key: "top_hundred",
									label: "Top 100",
									children: (
										<PreviewTable
											title={() => "Top 100"}
											data={measureState.topHundredData}
										/>
									),
								},
							]}
						/>

						<FieldSelectionTable />
					</>
				);
			default:
				return null;
		}
	};

	// Resets search value when displaying different table
	useEffect(() => {
		setSearchVal("");
	}, [measureState.mode]);

	return (
		<Space direction="vertical" style={{ width: "100%" }}>
			<Title style={{ marginTop: "0.5em" }} level={5}>
				Data Explorer
			</Title>
			<Search
				placeholder={`Search ${getPlaceholder}...`}
				allowClear
				onChange={handleSearchChange}
				onSearch={handleSearch}
				value={searchVal}
				style={{ marginBottom: 8 }}
				styles={{ affixWrapper: { height: 32 } }}
				disabled={measureState.activeTab === "top_hundred"}
			/>
			<Breadcrumb
				items={measureState.history.map((h: any) => ({
					key: h.mode,
					title: (
						<Link onClick={() => handleBreadcrumbClick(h.mode)}>{h.title}</Link>
					),
				}))}
			/>
			{renderTable(measureState.mode)}
		</Space>
	);
};

export default DataExplorer;
