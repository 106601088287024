import React, { useCallback, useContext } from "react";
import Visual from "./Visual";
import StaggeredGrid from "./StaggeredGrid";
import { CustomDashboardContext } from "../contexts/context";

type VisualsListProps = {
	draggable?: boolean;
	onVisualDragStart?: (
		e: React.DragEvent<HTMLDivElement>,
		visualId: any
	) => void;
	visuals?: Array<any>;
};

const VisualsList = ({
	draggable = false,
	onVisualDragStart = () => {},
	visuals = [],
}: VisualsListProps) => {
	const { measures } = useContext(CustomDashboardContext);
	const onDragStart = useCallback(
		(e: React.DragEvent<HTMLDivElement>, visualId: string) => {
			onVisualDragStart(e, visualId);
		},
		[]
	);

	if (visuals?.length === 0) return null;

	return (
		<StaggeredGrid
			items={visuals.map((visual: any) => {
				const measure = measures?.find((el: any) => el.id === visual.measure);
				return (
					<div
						className="droppable-element"
						// draggable={false}
						draggable={draggable}
						onDragStart={(e) => {
							onDragStart(e, visual.id);
							e.dataTransfer.setData("visual_id", visual.id);
						}}
					>
						<Visual
							key={visual.id}
							visual={visual}
							plotStyle={{ maxHeight: 250 }}
							showEdit
							showDelete
							staticPlot
							params={measure?.params}
							showDescription={false}
						/>
					</div>
				);
			})}
		/>
	);
};

export default VisualsList;
