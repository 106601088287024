import { Button, Select, Space } from "antd";
import React, { useCallback, useContext, useMemo } from "react";
import {
	commonSelectProps,
	compareSelectOptions,
	orders,
} from "../../../utils/queryBuilder";
import { CustomDashboardContext } from "../../../contexts/context";
import { GetAntIcon } from "../../../utils/ant_icons";

interface OrderByEntryProps {
	column?: any;
	columns?: Array<any>;
	tableOptions?: Array<any>;
	showDelete?: boolean;
	hideTable?: boolean;
	onChange?: (id: string, column: any) => void;
	onRemove?: (id: string) => void;
}

const OrderByEntry = ({
	column,
	columns,
	tableOptions,
	showDelete,
	hideTable = false,
	onChange = () => {},
	onRemove = () => {},
}: OrderByEntryProps) => {
	const { tables } = useContext(CustomDashboardContext);

	const options = useMemo(() => {
		const columnsWithSameTable = columns?.filter(
			(col: any) => col?.table?.name === column?.table?.name
		);

		const selectedFields = columnsWithSameTable
			?.map((col: any) => col?.name)
			.filter((field: string) => field);

		const foundTable = tables?.find(
			(tbl: any) => tbl?.name === column?.table?.name
		);
		if (!foundTable) return [];

		const { fields = [] } = foundTable;
		return fields
			?.filter((field: any) => !selectedFields?.includes(field?.name))
			?.map((field: any) => ({
				label: field?.name,
				value: field?.name,
			}));
	}, [tables, column, columns]);

	const handleChange = useCallback(
		(key: string, value: any) => {
			const { id } = column;
			const updatedColumn = { ...column, [key]: value };
			onChange(id, updatedColumn);
		},
		[column, onChange]
	);

	const handleRemove = useCallback(() => {
		const { id } = column;
		onRemove(id);
	}, [column, onRemove]);

	return (
		<Space.Compact
			style={{ width: "100%", display: "flex", whiteSpace: "nowrap" }}
		>
			{!hideTable && (
				<Select
					{...commonSelectProps}
					placeholder="Table"
					options={tableOptions}
					onChange={(value) => handleChange("table", { name: value })}
					value={column?.table?.name}
				/>
			)}

			<Select
				{...commonSelectProps}
				placeholder="Column"
				options={options}
				filterSort={compareSelectOptions}
				onChange={(value) => handleChange("name", value)}
				value={column?.name}
			/>
			<Select
				{...commonSelectProps}
				options={orders}
				placeholder="Order"
				onChange={(value) => handleChange("order", value)}
				value={column.order}
			/>
			<Button
				style={{ display: showDelete ? "block" : "none" }}
				danger
				icon={GetAntIcon("delete")}
				onClick={handleRemove}
			/>
		</Space.Compact>
	);
};

export default OrderByEntry;
