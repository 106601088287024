import { LinkOutlined } from "@ant-design/icons";
import {
	Button,
	Col,
	Divider,
	Flex,
	Form,
	Input,
	Row,
	Space,
	Typography,
} from "antd";
import { useEffect, useState } from "react";
import {
	updatePercentBasedOnTolerance,
	updateToleranceBasedOnPercent,
} from "../utils/utils";

const { Text } = Typography;

export type TargetType = {
	target: number;
	tolerance: number;
	tolerance_percent: number;
};

export type TargetProps = {
	[key: string]: TargetType;
};

type TargetsConfigProps = {
	onChange?: (targets: TargetProps) => void;
	value?: TargetProps | undefined;
	positiveIndicator?: string;
};

const TargetsConfig = ({
	onChange = () => {},
	value,
	positiveIndicator = "above",
}: TargetsConfigProps) => {
	const targets =
		value ||
		generateModesIntialData(["year", "quarter", "month", "week", "day"]);

	// to determine which input that are able to be updated either tolerance percent or tolerance itself
	const [isPercent, setIsPercent] = useState(true);

	const handleChange = (key: string, value: any) => {
		const updatedTarget = {
			...targets,
			[key]: value,
		};

		const updatedTargets = isPercent
			? Object.entries(updatedTarget).reduce((acc, [key, value]) => {
				return {
					...acc,
					...updateToleranceBasedOnPercent({ [key]: value }, key),
				};
			}, {})
			: Object.entries(updatedTarget).reduce((acc, [key, value]) => {
				return {
					...acc,
					...updatePercentBasedOnTolerance({ [key]: value }, key),
				};
			}, {});

		onChange(updatedTargets);
	};

	return (
		<>
			<Text>Targets</Text>
			<Row style={{ marginTop: 8 }} gutter={8}>
				<Col span={5}>
					<Form.Item label="Period" colon={false} style={{ marginBottom: 8 }}>
						<Flex justify="end">
							<Text>Target</Text>
						</Flex>
					</Form.Item>
					{Object.entries(targets).map(([key, value]) => {
						const { target } = value;

						return (
							<Form.Item
								label={getLabel(key)}
								colon={false}
								style={{ marginBottom: 8 }}
							>
								<Input
									value={target}
									onChange={(e) =>
										handleChange(key, {
											...targets[key],
											target: e.target.value,
										})
									}
								/>
							</Form.Item>
						);
					})}
				</Col>
				<Divider type="vertical" style={{ height: "auto" }} />
				<Col>
					<Form.Item style={{ marginBottom: 8 }}>
						<Flex justify="space-between">
							<Button
								className={isPercent ? "" : "custom-hover"}
								type="text"
								size="small"
								onClick={() => setIsPercent(false)}
							>
								Tolerance
							</Button>
							<Button
								className={isPercent ? "custom-hover" : ""}
								type="text"
								size="small"
								onClick={() => setIsPercent(true)}
							>
								Tolerance %
							</Button>
						</Flex>
					</Form.Item>
					{Object.entries(targets).map(([key, value]) => {
						const { tolerance, tolerance_percent, target } = value;
						return (
							<Form.Item style={{ marginBottom: 8 }}>
								<Space>
									<Input
										disabled={isPercent || (!target && target !== 0)}
										value={tolerance}
										style={{ width: 100 }}
										onChange={(e) =>
											handleChange(key, {
												...targets[key],
												tolerance: e.target.value,
											})
										}
									/>
									<LinkOutlined />
									<Input
										disabled={!isPercent || (!target && target !== 0)}
										value={tolerance_percent}
										style={{ width: 100 }}
										onChange={(e) =>
											handleChange(key, {
												...targets[key],
												tolerance_percent: e.target.value,
											})
										}
									/>
								</Space>
							</Form.Item>
						);
					})}
				</Col>
				<Divider type="vertical" style={{ height: "auto" }} />

				<Col span={5}>
					<Form.Item style={{ marginBottom: 8 }}>
						<Flex justify="end">
							<Text>Range</Text>
						</Flex>
					</Form.Item>
					{Object.entries(targets).map(([key, value]) => {
						const { target, tolerance_percent } = value;

						// The min and max are calculated with the indicator, target and the tolerance_percentage
						let { min, max } =
							!target && target !== 0 // check if target is filled
								? { min: undefined, max: undefined }
								: tolerance_percent !== null
									? getRange(positiveIndicator, target, tolerance_percent)
									: { min: 0, max: 0 };

						return (
							<Form.Item colon={false} style={{ marginBottom: 8 }}>
								<Space.Compact>
									<Input disabled value={min} />
									<Input disabled value={max} />
								</Space.Compact>
							</Form.Item>
						);
					})}
				</Col>
			</Row>
		</>
	);
};

const getLabel = (key: string) => {
	switch (key) {
		case "year":
			return "Yearly";
		case "quarter":
			return "Quarterly";
		case "month":
			return "Monthly";
		case "week":
			return "Weekly";
		case "day":
			return "Daily";

		default:
			return "";
	}
};

const getRange = (
	indicator: string,
	target: number,
	tolerance_percent: number = 0
): { min: number; max: number } => {
	const _target = Number(target);
	const _tolerance_percent = Number(tolerance_percent);

	let r1 = 0;
	let r2 = 0;

	if (_tolerance_percent === 0 && _target === 0) {
		r1 = _target;
		r2 = _target;
	} else if (indicator === "above") {
		r2 = _target;
		r1 = _target - (Math.abs(_target) * _tolerance_percent) / 100;
	} else {
		r2 = _target + (Math.abs(_target) * _tolerance_percent) / 100;
		r1 = _target;
	}

	const [min, max] = [r1, r2].sort((a, b) => a - b)

	return { min, max };
};

const generateModesIntialData = (modes: Array<string>) => {
	let data: TargetProps = {};
	modes.forEach((mode) => {
		data[mode] = { target: 0, tolerance: 0, tolerance_percent: 0 };
	});
	return data;
};
export default TargetsConfig;
