import { Button, Flex, Input, Layout, Typography } from "antd";
import { useContext, useState } from "react";
import MeasureList from "./MeasureList";
import { GetAntIcon } from "../utils/ant_icons";
import { CustomDashboardContext } from "../contexts/context";

const { Header } = Layout;
const { Text } = Typography;

interface MeasureContentProps {}

const MeasureTab = ({}: MeasureContentProps) => {
	const [searchVal, setSearchVal] = useState<string | undefined>("");
	const {
		isOwner,
		dispatch: customDashboardDispatch,
		state: customDashboardState,
		measures,
	} = useContext(CustomDashboardContext);
	const actionable = !customDashboardState.editMode;

	const handleEditMeasure = (id: string) => {
		customDashboardDispatch({ type: "EDIT_MEASURE", payload: id });
	};

	const handleCreateMeasure = () => {
		customDashboardDispatch({ type: "CREATE_MEASURE", payload: true });
	};

	const renderwMeasureButton = () => {
		if (!isOwner) return null;

		return (
			<Button
				disabled={!actionable}
				icon={GetAntIcon("plus")}
				onClick={handleCreateMeasure}
			>
				New measure
			</Button>
		);
	};

	const getMeasures = () => {
		return searchVal
			? measures?.filter((_measure) => {
					return (
						_measure?.name?.toLowerCase()?.includes(searchVal?.toLowerCase()) ||
						_measure?.description
							?.toLowerCase()
							?.includes(searchVal?.toLowerCase())
					);
			  })
			: measures;
	};

	return (
		<Layout style={{ background: "transparent", height: "inherit" }}>
			<Header
				style={{
					padding: 0,
					background: "transparent",
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<Text style={{ fontSize: 24 }}>Measures</Text>
				{renderwMeasureButton()}
			</Header>
			<Input
				placeholder="Search..."
				onChange={(e) => setSearchVal(e.target.value)}
				value={searchVal}
				style={{ borderRadius: 2, marginBottom: 16 }}
				allowClear
			/>
			<MeasureList onEdit={handleEditMeasure} measures={getMeasures()} />
		</Layout>
	);
};

export default MeasureTab;
