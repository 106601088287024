import { useEffect, useState } from "react";
import { loadMenu } from "../services/api-server/menu";
import Emitter from "../services/EventEmitter";

const useMenu = () => {
	const [menu, setMenu] = useState<object[]>([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const updateMenu = () => {
			setLoading(true);
			loadMenu()
				.then((data: any) => {
					if (data !== undefined) {
						setMenu(data.menu);
					}
				})
				.catch((error: any) => {
					// console.error(error.message);
				})
				.finally(() => {
					setLoading(false);
				});
		};

		Emitter.on("MenuSaved", updateMenu);

		updateMenu();

		return () => {
			Emitter.off("MenuSaved", updateMenu);
		};
	}, []);

	return { loading, menu };
};

export default useMenu;
