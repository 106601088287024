import { Tenant } from "./_exports";
import Emitter from "../EventEmitter";
import { api } from "../../contexts/AuthContext";

export const getMeasures = () => {
	return new Promise((resolve, reject) => {
		api.get(`/measures/getMeasures`, {})
			.then((response) => {
				resolve(response.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const addMeasure = (measure: Measure, menuKey?: string) => {
	return new Promise((resolve, reject) => {
		api.post(`/measures/addMeasure`, {
			tenantid: Tenant,
			menuKey,
			measure,
		})
			.then((response) => {
				Emitter.emit("MEASURES_UPDATED", response);
				resolve(response);
			})
			.catch((e: any) => {
				// console.log("err", e);
				reject(e);
			});
	});
};

export const updateMeasure = (measure: Measure, menuKey?: string) => {
	return new Promise((resolve, reject) => {
		api.put(`/measures/updateMeasure`, {
			tenantid: Tenant,
			menuKey,
			measure,
		})
			.then((response) => {
				Emitter.emit("MEASURES_UPDATED", response);
				resolve(response);
			})
			.catch((e: any) => {
				// console.log("err", e);
				reject(e);
			});
	});
};

export const deleteMeasure = (measureId: string, menuKey?: string) => {
	return new Promise((resolve, reject) => {
		api.delete(`/measures/deleteMeasure`, {
			data: { id: measureId, tenantid: Tenant, menuKey },
		})
			.then((_data) => {
				Emitter.emit("MEASURES_UPDATED", _data);
				resolve(_data);
			})
			.catch((e: any) => {
				// console.log("err", e);
				reject(e);
			});
	});
};
