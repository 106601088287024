import {
	CaretDownFilled,
	CaretUpFilled,
	CloseCircleFilled,
	SafetyCertificateOutlined,
} from "@ant-design/icons";
import { Space, SpaceProps, Tag, Tooltip, Typography } from "antd";
import { PresetColorType } from "antd/es/_util/colors";
import { TargetProps } from "./TargetsConfig";
import dayjs from "dayjs";
import { useContext } from "react";
import { CustomDashboardContext } from "../contexts/context";

const { Text } = Typography;
const DECIMAL_PLACES = 2;

type SliceUnitType = "year" | "quarter" | "month" | "week" | "day" | null;

type CustomStatisticProps = SpaceProps & {
	valueField?: string;
	indicator_value?: number;
	targets?: TargetProps;
	unit?: SliceUnitType;
	indicator?: "above" | "below";
	valueObj?: any;
	measure?: Measure;
	target_multiplier?: string;
	target_based_on?: string;
};

const CustomStatistic = ({
	valueObj = {},
	indicator_value,
	valueField = "",
	targets,
	unit = "day",
	indicator = "above",
	measure,
	target_multiplier = "None",
	target_based_on = "",
	...restProps
}: CustomStatisticProps) => {
	const { state: customDashboardState } = useContext(CustomDashboardContext);
	const value = valueObj?.[valueField];
	if (value === undefined) {
		return <Text type="danger">"{valueField}" is not found</Text>;
	}

	if (typeof value !== "number" && !value) {
		return <Text>No results found</Text>;
	}

	const targetUnit = targets?.[`${unit}`];
	const getVerifiedContent = () => {
		if (!measure?.verified) return null;

		return `Verified by ${measure?.verifiedBy} on ${dayjs(
			measure?.verifiedAt
		).format("DD-MM-YYYY hh:mm:ss a")}`;
	};

	return (
		<Space
			{...restProps}
			style={{ width: "100%", height: "100%" }}
			direction="vertical"
		>
			<Space>
				<Text style={{ fontSize: "clamp(1rem, 2rem, 32px)" }}>
					{typeof value === "number"
						? Number(value).toFixed(DECIMAL_PLACES)
						: value}
				</Text>
				{measure?.verified ? (
					<Tooltip
						overlayInnerStyle={{ fontSize: 16 }}
						title={getVerifiedContent}
						placement="right"
					>
						<SafetyCertificateOutlined style={{ fontSize: 14 }} />
					</Tooltip>
				) : null}
			</Space>
			{targetUnit && customDashboardState.sliceValues?.useTarget ? (
				<TargetHandler
					target={getTarget(
						targetUnit.target,
						target_multiplier,
						target_based_on,
						customDashboardState?.sliceValues
					)}
					value={indicator_value ?? value}
					indicator={indicator}
					tolerancePercent={targetUnit.tolerance_percent}
				/>
			) : null}
		</Space>
	);
};

type TargetHandlerProps = {
	value: number;
	target: number;
	indicator?: string;
	tolerancePercent: number;
};

const TargetHandler = ({
	value,
	target,
	indicator,
	tolerancePercent,
}: TargetHandlerProps) => {
	const difference = calPercentageDifference(Number(value), Number(target));
	const color = getDifferencecolor(
		Number(Number(value).toFixed(DECIMAL_PLACES)),
		target,
		tolerancePercent,
		indicator
	);

	return (
		<>
			<CustomDiffereceRenderer difference={difference} color={color} />
			<Text>vs target {Number(target).toFixed(DECIMAL_PLACES)}</Text>
		</>
	);
};

const CustomDiffereceRenderer = ({
	difference = 0,
	color,
}: {
	difference?: number | string;
	color?: PresetColorType;
}) => {
	return (
		<Space size={4} style={{ color }}>
			<Tag color={color}>
				<Space>
					{color === "red" ? (
						<CloseCircleFilled />
					) : color === "green" ? (
						<CaretUpFilled />
					) : (
						<CaretDownFilled />
					)}
					{difference}
				</Space>
			</Tag>
		</Space>
	);
};

const calPercentageDifference = (value: number, target: number) => {
	if (target === 0) {
		if (value === 0) {
			return `${Number(0).toFixed(1)}%`; // Both value and target are 0, no difference
		} else {
			return "> 100%"; // Target is 0, any non-zero value leads to infinite difference
		}
	}
	let difference = ((value - target) / target) * 100;
	return `${Number(difference).toFixed(1)}%`;
};

const getDifferencecolor = (
	value: number,
	target: number,
	tolerance_percent: number,
	indicator?: string
): PresetColorType => {
	if (target === value) {
		return 'green'
	}


	if (target === 0 && value === 0) {
		if (indicator === "above") {
			return 'green'
		} else {
			return 'red'
		}
	}

	// Handle the edge case where both min and max are zero (target 0)
	if (target === 0) {
		if (indicator === "below") {
			return value < target ? 'green' : 'red'
		} else {
			return value > target ? 'green' : 'red'
		}
	}

	if (indicator === "below") {
		const [newMin, newMax] = [target + (target * tolerance_percent) / 100, target].sort((a, b) => a - b) // sort min max
		if (value >= newMax) {
			return "red";
		} else if (value < newMax && value >= newMin) {
			return "orange";
		} else {
			return "green";
		}
	} else {
		const [newMin, newMax] = [target, target - (target * tolerance_percent) / 100].sort((a, b) => a - b) // sort min max
		if (value >= newMax) {
			return "green";
		} else if (value < newMax && value >= newMin) {
			return "orange";
		} else {
			return "red";
		}
	}
};

const getTarget = (
	target: any,
	target_multiplier: string,
	target_based_on: string,
	sliceValues: any
) => {
	if (target && target_multiplier === "multiply") {
		if (sliceValues?.[target_based_on])
			return target * sliceValues?.[target_based_on]?.length;
	}

	return target;
};

export default CustomStatistic;
