import { useEffect, useState } from "react";
import { duckQuery } from "../services/api-server/deltashare";
import { replaceWithParams } from "../utils/queryBuilder";
import { socket } from "../utils/socket";

type useQueryProps = {
	query: string;
	unauthorized_table?: Array<string>;
	params?: any;
};

const useQuery = ({
	query = "",
	unauthorized_table = [],
	params,
}: useQueryProps) => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState<undefined | Array<any>>(undefined);

	const runQuery = (data?: any) => {
		/**
		 * if it's a refresh of db, do not show loading,
		 * no interuption on the client side to be expected,
		 * only updated values will be shown
		 */
		if (data !== "DONE") setLoading(true);

		let updatedQueryStringParams = replaceWithParams(query, params);

		duckQuery(updatedQueryStringParams, unauthorized_table)
			.then((data: any) => {
				setData(data?.response);
			})
			.catch((err) => {
				// console.error(err);
			})
			.finally(() => {
				setLoading(false);
			});
	};
	useEffect(() => {
		if (query) {
			runQuery();
		} else setData(undefined);

		socket.on("REFRESH_DATA", runQuery);

		return () => {
			socket.off("REFRESH_DATA", runQuery);
		};
	}, [query, params]);

	return { loading, data };
};

export default useQuery;
