import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useReducer,
	useState,
} from "react";
import {
	ExclamationCircleOutlined,
	RedoOutlined,
	SafetyCertificateOutlined,
} from "@ant-design/icons";
import {
	Badge,
	Button,
	Dropdown,
	Flex,
	Form,
	Modal,
	ModalProps,
	Popconfirm,
	Radio,
	RadioChangeEvent,
	Result,
	Select,
	Space,
	Spin,
	Switch,
	Typography,
} from "antd";
import {
	ACTIONTYPES,
	INITIALSTATE,
	PAGE_TYPE,
	reducer,
} from "../../reducers/measureReducer";
import {
	duckQuery,
	getAllTables,
	getShares,
} from "../../services/api-server/deltashare";
import "react-quill/dist/quill.snow.css";
import { themes } from "../../utils/codemirrrortheme";
import PreviewTable from "../PreviewTable";
import StatusHandler from "../StatusHandler";
import QueryBuilder from "../QueryBuilder";
import { GetAntIcon } from "../../utils/ant_icons";
import {
	clauses,
	generateCompletions,
	getErrorMessage,
	replaceWithParams,
	validateParams,
} from "../../utils/queryBuilder";
import { v4 as uuid } from "uuid";
import NameDescriptionModal from "./NameDescriptionModal";
import QueryParameters from "../QueryParameters";
import CustomEditor from "../CustomEditor";
import { CustomDashboardContext, MeasureContext } from "../../contexts/context";
import DataExplorer from "../DataExplorer";

const { Text, Link, Title } = Typography;

interface MeasureModalProps extends ModalProps {
	onSave?: (response: any) => void;
	closeModal?: () => void;
	measureId?: string;
	editMode?: boolean;
}

const MeasureModal = ({
	open = false,
	onCancel = () => {},
	onSave = () => {},
	closeModal = () => {},
	measureId = "",
	editMode = false,
	...restProps
}: MeasureModalProps) => {
	const { isOwner, isAdmin, measures, tables, data_unauthorized } = useContext(
		CustomDashboardContext
	);

	// configurations
	const allowModeChange = false;
	const showDataExplorer = isOwner;

	const [state, dispatch] = useReducer(reducer, INITIALSTATE);
	const [theme, setTheme] = useState("vscodeDark");

	// CALLBACKS
	const handleRunQuery = useCallback(() => {
		const { params = {}, queryStatement = "" } = state.measure;

		dispatch({
			type: ACTIONTYPES.QUERYLOADING,
			payload: true,
		});

		// QUERY STATEMENT
		let updatedQueryStringParams: string = replaceWithParams(
			queryStatement,
			params
		);
		// check if limit was added
		if (updatedQueryStringParams.indexOf("limit") === -1) {
			// check for query termination
			const queryTerminator = ";";

			const terminatorIndex = updatedQueryStringParams.indexOf(queryTerminator);

			if (terminatorIndex !== -1) {
				updatedQueryStringParams = `${updatedQueryStringParams.substring(
					0,
					terminatorIndex
				)} limit 5000${queryTerminator}`;
			} else {
				updatedQueryStringParams += " limit 5000";
			}
		}

		duckQuery(updatedQueryStringParams, data_unauthorized)
			.then((response) => {
				dispatch({
					type: ACTIONTYPES.PREVIEWDATA,
					payload: response,
				});
			})
			.catch((error) => {
				console.log(error);

				dispatch({
					type: ACTIONTYPES.ERROR,
					payload: error,
				});
			});
	}, [state.measure, state.query]);

	const handleMeasureChange = useCallback(
		(key: string, value: any) => {
			dispatch({
				type: ACTIONTYPES.UPDATE_MEASURE,
				payload: { key, value },
			});
		},
		[state.measure]
	);

	const handleQueryType = useCallback((ev: RadioChangeEvent) => {
		const queryType = ev.target.value;
		handleMeasureChange("allowStringQuery", queryType === "sql" ? true : false);
	}, []);

	const handleCloseModal = useCallback(
		(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			onCancel(ev);
			dispatch({ type: ACTIONTYPES.RESET });
		},
		[]
	);

	const handleAddClause = useCallback((ev: any) => {
		// handle clauses that need to have an initial item in an array
		if (ev.target.id === "orderBy") {
			dispatch({
				type: ACTIONTYPES.ADD_CLAUSE,
				payload: {
					[ev.target.id]: {
						columns: [{ id: uuid(), name: null, order: "ASC" }],
					},
				},
			});
		} else if (ev.target.id === "where") {
			dispatch({
				type: ACTIONTYPES.ADD_CLAUSE,
				payload: {
					[ev.target.id]: {
						columns: [{ id: uuid(), name: null, op: null, value: null }],
					},
				},
			});
		} else if (ev.target.id === "select" || ev.target.id === "groupBy") {
			dispatch({
				type: ACTIONTYPES.ADD_CLAUSE,
				payload: {
					[ev.target.id]: {
						columns: [{ id: uuid(), name: null }],
					},
				},
			});
		} else if (ev.target.id === "from") {
			dispatch({
				type: ACTIONTYPES.ADD_CLAUSE,
				payload: {
					[ev.target.id]: {
						tables: [{ id: uuid(), name: null }],
					},
				},
			});
		} else if (ev.target.id === "limitHint") {
			dispatch({
				type: ACTIONTYPES.ADD_CLAUSE,
				payload: {
					[ev.target.id]: 5000,
				},
			});
		} else {
			dispatch({
				type: ACTIONTYPES.ADD_CLAUSE,
				payload: { [ev.target.id]: {} },
			});
		}
	}, []);

	const renderQueryInputs = () => {
		if (state?.measure?.allowStringQuery)
			return (
				<CustomEditor
					readOnly={!isOwner}
					value={state?.measure?.queryStatement}
					onChange={(value) => {
						handleMeasureChange("queryStatement", value);
					}}
					onKeyDownCapture={(ev) => {
						if (ev.ctrlKey && ev.code === "Enter") {
							handleRunQuery();
							ev.preventDefault();
						}
					}}
					completions={generateCompletions(state.tables, tables)}
				/>
			);
		return <QueryBuilder />;
	};

	// MEMO
	const dropDownItems = useMemo(() => {
		const { queryOptions = {} } = state.measure;
		const filteredClauses = clauses.filter(
			(clause) => !Object.keys(queryOptions).includes(clause)
		);
		return filteredClauses.map((clause) => {
			return {
				key: clause,
				label: (
					<Link id={clause} onClick={handleAddClause}>
						{clause}
					</Link>
				),
			};
		});
	}, [state.measure]);

	const handleNext = useCallback(() => {
		dispatch({ type: ACTIONTYPES.GO_NEXT });
	}, []);

	const renderFooter = () => {
		return (
			<Flex justify="space-between">
				<Space>
					<Switch
						disabled={!isAdmin || state.loading}
						checked={state?.measure?.verified}
						onChange={(checked) =>
							dispatch({
								type: ACTIONTYPES.UPDATE_MEASURE,
								payload: { key: "verified", value: checked },
							})
						}
					/>
					Verified
					<SafetyCertificateOutlined style={{ fontSize: 16 }} />
				</Space>
				<Space>
					<Button disabled={state.loading} onClick={handleCloseModal}>
						Cancel
					</Button>

					{isOwner && (
						<>
							{state.paramsOk ? (
								<Button
									type="primary"
									disabled={state.loading}
									onClick={handleNext}
								>
									Next
								</Button>
							) : (
								<Popconfirm
									title="Error"
									icon={<ExclamationCircleOutlined style={{ color: "red" }} />}
									description={<pre>{getErrorMessage(state.required)}</pre>}
									cancelButtonProps={{ style: { display: "none" } }}
									overlayStyle={{ zIndex: 30003 }}
									disabled={state.loading}
									placement="topLeft"
								>
									<Button type="primary" disabled={state.loading}>
										Next
									</Button>
								</Popconfirm>
							)}
						</>
					)}
				</Space>
			</Flex>
		);
	};

	// EFFECTS
	useEffect(() => {
		dispatch({ type: ACTIONTYPES.LOADING, payload: true });
		duckQuery("SHOW TABLES")
			.then((data: any) => {
				const { response: tableNameArr = [] } = data;

				getShares()
					.then((shares: any) => {
						const promises = shares.map(getAllTables);

						Promise.all(promises).then((tablesArr: any) => {
							let tables: Array<any> = [];
							tablesArr.forEach((tableArr: any) => {
								tables = [...tables, ...tableArr];
							});
							tables = tables.filter((tbl: any) =>
								tableNameArr.find((t: any) => t.name === tbl.name)
							);
							dispatch({ type: ACTIONTYPES.TABLES, payload: tables });
							dispatch({ type: ACTIONTYPES.LOADING, payload: false });
						});
					})
					.catch((error) => {
						// console.error(error);
					});
			})
			.catch((error) => {
				// console.error(error);
			});
	}, []);

	useEffect(() => {
		if (open) {
			const measure: Measure = measures?.find(
				(measure: any) => measure.id === measureId
			);

			if (measure) {
				dispatch({
					type: ACTIONTYPES.EDIT_MEASURE,
					payload: measure,
				});
			} else {
				dispatch({ type: ACTIONTYPES.CREATE_MEASURE });
			}
		}
	}, [open, measureId, measures]);

	useEffect(() => {
		const {
			allowStringQuery,
			queryStatement,
			queryOptions,
			params: { end_date, start_date, vessel_id },
		} = state.measure;

		// only select the required params which is only end_date, start_date and vessel_id
		const requiredParams = { end_date, start_date };

		const i = validateParams(
			allowStringQuery ? queryStatement : queryOptions,
			requiredParams
		);

		dispatch({ type: ACTIONTYPES.PARAMS_OK, payload: i });
	}, [state.measure]);

	return (
		<MeasureContext.Provider value={{ state, dispatch }}>
			<Modal
				{...restProps}
				width={isOwner ? "85%" : "70%"}
				title={
					editMode ? (isOwner ? "Edit Measure" : "View Measure") : "New Measure"
				}
				onCancel={handleCloseModal}
				destroyOnClose
				maskClosable={false}
				style={{ top: 24 }}
				styles={{
					body: { padding: 0 },
				}}
				open={open}
				className="measure-modal"
				footer={renderFooter()}
			>
				<Spin spinning={state.loading} style={{ width: "100%" }}>
					<Form layout="vertical" className="create-measure-form">
						<div
							style={{
								display: "grid",
								gridTemplateColumns: showDataExplorer
									? "repeat(2, 50%)"
									: "repeat(1, 100%)",
							}}
						>
							<Space direction="vertical" style={{ padding: 24 }}>
								<Title style={{ fontSize: 16 }}>Query Builder</Title>
								<Form.Item label={<Text>Parameters</Text>}>
									<QueryParameters
										parameters={state.measure?.params}
										onChange={(params) => {
											dispatch({
												type: ACTIONTYPES.UPDATE_MEASURE,
												payload: { key: "params", value: params },
											});
										}}
									/>
								</Form.Item>
								<Form.Item
									className="query-input"
									label={
										<div
											style={{
												display: "flex",
												justifyContent: "space-between",
												width: "100%",
											}}
										>
											<Text>Query</Text>
											{allowModeChange && (
												<Radio.Group
													className="quer-type-radio-group"
													onChange={handleQueryType}
													size="small"
													value={
														state?.measure?.allowStringQuery
															? "sql"
															: "low_code"
													}
												>
													<Radio.Button
														style={{ borderRadius: 2 }}
														value={"low_code"}
													>
														Low Code
													</Radio.Button>
													<Radio.Button
														style={{ borderRadius: 2 }}
														value={"sql"}
													>
														SQL
													</Radio.Button>
												</Radio.Group>
											)}
										</div>
									}
								>
									<Space direction="vertical" style={{ width: "100%" }}>
										{false && (
											<Space>
												<Select
													style={{ width: 150 }}
													size="small"
													options={themes.map((theme) => ({
														label: theme,
														value: theme,
													}))}
													dropdownStyle={{ zIndex: 30003 }}
													placeholder="theme"
													value={theme}
													onChange={(value) => setTheme(value)}
												/>
											</Space>
										)}
										{renderQueryInputs()}
										{isOwner ? (
											<Space size={12} align="start">
												{!state.measure?.allowStringQuery && isOwner && (
													<Dropdown
														overlayStyle={{ zIndex: 30003 }}
														menu={{ items: dropDownItems }}
													>
														<Button
															size="small"
															type="primary"
															icon={GetAntIcon("plus")}
														>
															Add clause
														</Button>
													</Dropdown>
												)}
												<Button
													onClick={handleRunQuery}
													type="primary"
													size="small"
													icon={<RedoOutlined />}
													loading={state.queryloading}
													style={{ width: "max-content" }}
												>
													Run
												</Button>
												<StatusHandler
													dirty={state.querydirty}
													loading={state.queryloading}
													errorMessage={state.errorMsg}
												/>
											</Space>
										) : null}
									</Space>
								</Form.Item>
								{isOwner ? (
									<Form.Item
										label={
											<Space>
												<Text>Result</Text>
												<Badge
													color="#52c41a"
													count={state.previewdata.length}
													showZero={false}
													overflowCount={5000}
													style={{ color: "#fff" }}
												/>
											</Space>
										}
									>
										{state.querydirty ? (
											<PreviewTable
												data={state.previewdata}
												rootClassName="preview-table"
												style={{ height: "100%" }}
											/>
										) : (
											<Result
												className="clean-result"
												title="Please complete your query to view the results"
											/>
										)}
									</Form.Item>
								) : null}
							</Space>
							<Space
								className="dataset-explorer"
								direction="vertical"
								style={{
									display: showDataExplorer ? undefined : "none",
									background: "#141414",
									padding: 24,
								}}
								styles={{
									item: {
										width: "100%",
									},
								}}
							>
								<DataExplorer />
							</Space>
						</div>
					</Form>
				</Spin>
			</Modal>
			<NameDescriptionModal
				open={state.page === PAGE_TYPE.NAMEDESC}
				onCancel={() => dispatch({ type: ACTIONTYPES.GO_PREV })}
				onSave={onSave}
				closeModal={closeModal}
				maskClosable={false}
				style={{ zIndex: 30004 }}
				destroyOnClose
			/>
		</MeasureContext.Provider>
	);
};

export default MeasureModal;
