import { AutoComplete, Button, Select, Space, Tag } from "antd";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
	commonSelectProps,
	compareSelectOptions,
	operators,
} from "../../../utils/queryBuilder";
import {
	CustomDashboardContext,
	MeasureContext,
} from "../../../contexts/context";
import { duckQuery } from "../../../services/api-server/deltashare";
import dayjs from "dayjs";
import { GetAntIcon } from "../../../utils/ant_icons";

interface WhereEntryProps {
	column?: any;
	tableOptions?: Array<any>;
	showDelete?: boolean;
	showBitOp?: boolean;
	hideTable?: boolean;
	onChange?: (id: string, column: any) => void;
	onRemove?: (id: string) => void;
}

const WhereEntry = ({
	column,
	tableOptions,
	showDelete = false,
	showBitOp = false,
	hideTable = false,
	onChange = () => {},
	onRemove = () => {},
}: WhereEntryProps) => {
	const { tables } = useContext(CustomDashboardContext);
	const { state: measureState } = useContext(MeasureContext);
	const [valueOptions, setValueOptions] = useState<Array<any>>([]);

	const handleChange = useCallback(
		(key: string, value: any) => {
			const { id } = column;
			const updatedColumn = { ...column, [key]: value };
			onChange(id, updatedColumn);
		},
		[column, onChange]
	);

	const options = useMemo(() => {
		const foundTable = tables?.find(
			(tbl: any) => tbl?.name === column?.table?.name
		);

		if (!foundTable) return [];
		const { fields = [] } = foundTable;
		return fields?.map((field: any) => ({
			label: field?.name,
			value: field?.name,
		}));
	}, [tables, column]);

	useEffect(() => {
		if (column?.table?.name && column?.name) {
			const queryStatement = `SELECT DISTINCT "${column?.table?.name}"."${column?.name}" FROM ${column?.table?.name} LIMIT 5000`;
			duckQuery(queryStatement)
				.then((data: any) => {
					const params = measureState?.measure?.params || [];
					const paramOptions: Array<any> = [];
					for (const field in params) {
						paramOptions.push({
							label: `@${field}`,
							value: `@${field}`,
							param: params[field],
						});
					}

					let options = [
						{ label: renderTitle("Parameters"), options: paramOptions },
					];

					const valueIsMeasure = checkMeasure(
						data?.response[0]?.[column?.name]
					);

					if (!valueIsMeasure) {
						options = [
							...options,
							{
								label: renderTitle("Values"),
								options: data?.response
									?.filter((row: any) => row[column?.name])
									?.map((row: any) => {
										const value = row[column?.name];
										return {
											label: value,
											value,
										};
									}),
							},
						];
					}

					setValueOptions(options);
				})
				.catch((error) => {
					// console.log(error);
				});
		} else {
			setValueOptions([]);
		}
	}, [column, measureState.measure.params]);

	const renderValueInput = () => {
		if (column?.op === "IS NULL" || column?.op === "IS NOT NULL") return null;
		return (
			<AutoComplete
				options={valueOptions}
				placeholder="Value"
				onChange={(value) => handleChange("value", value)}
				dropdownStyle={{ zIndex: 30003 }}
				value={column?.value}
				allowClear
			/>
		);
	};

	const handleRemove = useCallback(() => {
		const { id } = column;

		if (typeof onRemove === "function") onRemove(id);
	}, [column, onRemove]);

	return (
		<Space.Compact
			style={{ width: "100%", display: "flex", whiteSpace: "nowrap" }}
		>
			<Tag
				style={{
					display: showBitOp ? "flex" : "none",
					justifyContent: "center",
					alignItems: "center",
					minWidth: 40,
				}}
				color="blue"
			>
				{column?.bitOp}
			</Tag>
			{!hideTable && (
				<Select
					{...commonSelectProps}
					placeholder="Table"
					options={tableOptions}
					onChange={(value) => handleChange("table", { name: value })}
					value={column?.table?.name}
				/>
			)}
			<Select
				{...commonSelectProps}
				placeholder="Column"
				options={options}
				filterSort={compareSelectOptions}
				onChange={(value) => handleChange("name", value)}
				value={column?.name}
			/>
			<Select
				{...commonSelectProps}
				options={operators}
				placeholder="Operator"
				onChange={(value) => handleChange("op", value)}
				value={column?.op}
			/>
			{renderValueInput()}

			<Button
				style={{ display: showDelete ? "block" : "none" }}
				danger
				icon={GetAntIcon("delete")}
				onClick={handleRemove}
			/>
		</Space.Compact>
	);
};

const checkMeasure = (value: any) => {
	// console.log("Value: " + value);
	return dayjs(value).isValid() || typeof value === "number";
};

const renderTitle = (title: string) => <span>{title}</span>;

export default WhereEntry;
