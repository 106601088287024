import { useCallback, useContext, useMemo } from "react";
import { CustomDashboardContext } from "../../../contexts/context";
import { Space } from "antd";
import FromEntry from "./FromEntry";

interface FromClauseProps {
	entries?: Array<any>;
	onChange?: (entries: any) => void;
}

// List of from entries (joins)
const FromClause = ({ entries = [], onChange = () => {} }: FromClauseProps) => {
	const { tables } = useContext(CustomDashboardContext);

	const options = useMemo(() => {
		return tables
			?.filter((table: any) => {
				return !entries?.find((e: any) => e?.name === table?.name);
			})
			?.map((table: any) => ({
				value: table.name,
				label: table.name,
			}));
	}, [tables, entries]);

	const joinTableOptions = useMemo(() => {
		return entries
			?.map((e: any) => ({
				value: e.name,
				label: e.name,
			}))
			.filter((option: any) => option.value);
	}, [entries]);

	const handleChange = useCallback(
		(id: string, updatedTable: string) => {
			const updatedEntries = entries?.map((e: any) => {
				if (e.id === id) {
					return updatedTable;
				}
				return e;
			});

			onChange({ tables: updatedEntries });
		},
		[entries]
	);

	const handleRemove = useCallback(
		(id: string) => {
			const updatedEntries = entries?.filter((e: any) => e.id !== id);
			onChange({ tables: updatedEntries });
		},
		[entries]
	);

	return (
		<Space direction="vertical" style={{ width: "100%" }}>
			{entries?.map((table: any, i: number) => (
				<FromEntry
					key={i}
					table={table}
					options={options}
					joinTableOptions={joinTableOptions}
					onChange={handleChange}
					onRemove={handleRemove}
					showJoin={i !== 0}
				/>
			))}
		</Space>
	);
};

export default FromClause;
