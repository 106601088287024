import { Button, Select, Space, Tag } from "antd";
import { useCallback, useContext } from "react";
import FromTag from "./FromTag";
import { commonSelectProps } from "../../../utils/queryBuilder";
import { GetAntIcon } from "../../../utils/ant_icons";
import { CustomDashboardContext } from "../../../contexts/context";

interface FromEntryProps {
	table?: any;
	options?: Array<any>;
	joinTableOptions?: Array<any>;
	showJoin?: boolean;
	onChange?: (id: string, table: any) => void;
	onRemove?: (id: string) => void;
}

const FromEntry = ({
	table,
	options,
	joinTableOptions,
	showJoin,
	onChange = () => {},
	onRemove = () => {},
}: FromEntryProps) => {
	const { tables } = useContext(CustomDashboardContext);
	const handleChange = useCallback(
		(key: string, value: any) => {
			const { id } = table;
			const updatedTable = { ...table, [key]: value };
			onChange(id, updatedTable);
		},
		[table, onChange]
	);

	const handleRemove = useCallback(() => {
		const { id } = table;
		onRemove(id);
	}, [table, onRemove]);

	const getFieldOptions = useCallback(
		(table: string) => {
			const foundTable = tables?.find((tbl: any) => tbl.name === table);

			return foundTable?.fields?.map((field: any) => ({
				label: field.name,
				value: field.name,
			}));
		},
		[tables]
	);

	// for join entries
	if (showJoin) {
		return (
			<Space.Compact
				style={{ width: "100%", display: "flex", whiteSpace: "nowrap" }}
			>
				<FromTag style={{ minWidth: 80 }}>{table?.type?.toUpperCase()}</FromTag>
				<Select
					{...commonSelectProps}
					placeholder={"Table"}
					value={table.name}
					options={options}
					onChange={(value) => handleChange("name", value)}
				/>
				<FromTag style={{ marginInlineStart: 8 }}>ON</FromTag>
				<Select
					{...commonSelectProps}
					placeholder={"Table"}
					value={table?.left?.name}
					options={joinTableOptions}
					onChange={(value) => handleChange("left", { name: value })}
				/>
				<Select
					{...commonSelectProps}
					placeholder={"Column"}
					value={table?.leftKey}
					options={getFieldOptions(table?.left?.name)}
					onChange={(value) => handleChange("leftKey", value)}
				/>
				<FromTag style={{ marginInlineStart: 8 }}>=</FromTag>
				<Select
					{...commonSelectProps}
					placeholder={"Table"}
					value={table?.right?.name}
					options={joinTableOptions}
					onChange={(value) => handleChange("right", { name: value })}
				/>
				<Select
					{...commonSelectProps}
					placeholder={"Column"}
					value={table?.rightKey}
					options={getFieldOptions(table?.right?.name)}
					onChange={(value) => handleChange("rightKey", value)}
				/>
				<Button danger icon={GetAntIcon("delete")} onClick={handleRemove} />
			</Space.Compact>
		);
	}

	return (
		<Space.Compact
			style={{ width: "100%", display: "flex", whiteSpace: "nowrap" }}
		>
			<Select
				{...commonSelectProps}
				placeholder={"Table"}
				value={table.name}
				options={options}
				onChange={(value) => handleChange("name", value)}
			/>
		</Space.Compact>
	);
};

export default FromEntry;
