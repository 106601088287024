import { Tenant } from "./_exports";
import Emitter from "../EventEmitter";
import { api } from "../../contexts/AuthContext";

export const getVisuals = () => {
	return new Promise((resolve, reject) => {
		api.get(`/visuals/getVisuals`, {})
			.then((response) => {
				resolve(response.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const addVisual = (visual: any, menuKey?: string) => {
	return new Promise((resolve, reject) => {
		api.post(`/visuals/addVisual`, {
			tenantid: Tenant,
			menuKey,
			visual,
		})
			.then((response) => {
				Emitter.emit("VISUALS_UPDATED", response);
				resolve(response);
			})
			.catch((e: any) => {
				// console.log("err", e);
				reject(e);
			});
	});
};

export const updateVisual = (visual: any, menuKey?: string) => {
	return new Promise((resolve, reject) => {
		api.put(`/visuals/updateVisual`, {
			tenantid: Tenant,
			menuKey,
			visual,
		})
			.then((response) => {
				Emitter.emit("VISUALS_UPDATED", response);
				resolve(response);
			})
			.catch((e: any) => {
				// console.log("err", e);
				reject(e);
			});
	});
};

export const deleteVisual = (id: string, menuKey?: string) => {
	return new Promise((resolve, reject) => {
		api.delete(`/visuals/deleteVisual`, {
			data: { id, tenantid: Tenant, menuKey },
		})
			.then((_data) => {
				Emitter.emit("VISUALS_UPDATED", _data);
				resolve(_data);
			})
			.catch((e: any) => {
				// console.log("err", e);
				reject(e);
			});
	});
};
