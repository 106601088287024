import { useCallback, useEffect, useState } from "react";
import useVisuals from "./useVisuals";
import useMeasures from "./useMeasures";
import { socket } from "../utils/socket";
import { duckQuery } from "../services/api-server/deltashare";
import useMenu from "./useMenu";
import useVessels from "./useVessels";
import useVisualGroups from "./useVisualGroups";
import Emitter from "../services/EventEmitter";

/**
 * handles all loading of data for custom dashboard
 */
const useCustomDashboard = (menuKey: string) => {
	const [loading, setLoading] = useState(false);
	const { loading: visualsLoading, visuals } = useVisuals();
	const { loading: visualGroupsLoading, visualGroups } =
		useVisualGroups(menuKey);
	const { loading: measuresLoading, measures } = useMeasures();
	const [progress, setProgress] = useState(0); // keep track of the progress of the tasks
	const [dataLoading, setDataLoading] = useState(true);
	const [tasks, setTasks] = useState<any>({});
	const { loading: vesselLoading, vessels } = useVessels();

	const handleLoading = useCallback((name: string, data: any) => {
		// handle data loading here for special case
		if (name === "data") {
			const { total = 0, completed = 0 } = data;
			if (total === completed) {
				setDataLoading(false);
			} else {
				setLoading(true);
				setDataLoading(true);
			}
		}
		setTasks((prev: any) => ({ ...prev, [name]: data }));
	}, []);

	useEffect(() => {
		setLoading(true);

		if (!visualsLoading) {
			handleLoading("visuals", { total: 1, completed: 1 });
		}
		if (!visualGroupsLoading) {
			handleLoading("visualGroups", { total: 1, completed: 1 });
		}
		if (!measuresLoading) {
			handleLoading("measures", { total: 1, completed: 1 });
		}

		if (
			!visualsLoading &&
			!measuresLoading &&
			!dataLoading &&
			!vesselLoading &&
			!visualGroupsLoading
		) {
			setLoading(false);
		} else {
			setLoading(true);
		}

	}, [
		visualsLoading,
		measuresLoading,
		dataLoading,
		vesselLoading,
		visualGroupsLoading,
	]);

	// calculation of progress when there are new tasks added
	useEffect(() => {
		const reduced: any = Object.values(tasks).reduce(
			(acc: any, curr: any, i: any) => {
				const { total = 0, completed = 0 } = curr;
				acc.total += total;
				acc.completed += completed;
				return acc;
			},
			{ total: 0, completed: 0 }
		);
		const { total = 0, completed = 0 } = reduced;

		setProgress(Math.round((completed / total) * 100));
	}, [tasks]);

	useEffect(() => {
		duckQuery("SHOW TABLES")
			.then((data: any) => {
				if (data?.response?.length !== 0) {
					setDataLoading(false);
				} else {
					setDataLoading(true);
				}
			})
			.catch((error) => {
				// Emitter.emit("alert", {
				// 	type: "error",
				// 	message: "Unsuccessful query",
				// 	description: "Please refresh the page or try again later.",
				// });
				// console.error(error);
			});

		socket.on("DATA_LOADING", (data: any) => handleLoading("data", data));

		return () => {
			socket.off("DATA_LOADING", (data: any) => handleLoading("data", data));
		};
	}, []);

	return { loading, visuals, measures, vessels, visualGroups, progress };
};

export default useCustomDashboard;
