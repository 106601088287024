import {
	Col,
	Divider,
	Form,
	Input,
	Radio,
	Row,
	Select,
	Space,
	Spin,
	Typography,
} from "antd";
import {
	ChangeEvent,
	useCallback,
	useContext,
	useEffect,
	useMemo,
} from "react";
import PlotInputs from "./PlotInputs";
import { duckQuery } from "../services/api-server/deltashare";
import PreviewTable from "./PreviewTable";
import Visual from "./Visual";
import { replaceWithParams } from "../utils/queryBuilder";
import { CustomDashboardContext, VisualContext } from "../contexts/context";
import TargetsConfig from "./TargetsConfig";
import { RadioButtonProps } from "antd/es/radio/radioButton";

interface ContentTabProps {}

const { Text } = Typography;

const ContentTab = ({}: ContentTabProps) => {
	const { state: visualState, dispatch: visualDispatch } =
		useContext(VisualContext);
	const { measures, state: customDashboardState } = useContext(
		CustomDashboardContext
	);

	// MEMOS
	const getMeasureOptions = useMemo(() => {
		return measures.map((measure: any) => {
			return { key: measure.id, label: measure.name, value: measure.id };
		});
	}, [measures]);

	// CALLBACKS
	const handleMeasureChange = useCallback(
		(value: any) => {
			visualDispatch({ type: "SELECT_MEASURE", payload: value });
			const measure = measures.find((measure: any) => measure.id === value);
			if (measure) queryMeasure(measure);
		},
		[measures]
	);

	const handleDrilldownChange = useCallback((value: any) => {
		visualDispatch({
			type: "UPDATE_VISUAL",
			payload: { name: "drilldown", value },
		});
	}, []);

	const queryMeasure = useCallback((measure: any) => {
		visualDispatch({ type: "LOAD_VISUAL_START" });

		const { queryStatement = "", params } = measure;

		// QUERY STATEMENT
		let updatedQueryStringParams = replaceWithParams(queryStatement, params);

		// limiting to 5000 rows as sample data for building visual
		duckQuery(updatedQueryStringParams)
			.then((data: any) => {
				if (data?.response.length) {
					visualDispatch({ type: "DATA", payload: data?.response });
				}
			})
			.catch((error) => {
				// console.error(error);
			})
			.finally(() => {
				visualDispatch({ type: "LOADING", payload: false });
			});
	}, []);

	const handleVisualChange = useCallback(
		(ev: ChangeEvent<HTMLInputElement>) => {
			visualDispatch({
				type: "UPDATE_VISUAL",
				payload: { name: ev.target.name, value: ev.target.value },
			});
		},
		[]
	);

	const measure = useMemo(() => {
		if (visualState?.visual?.measure) {
			return measures.find((el: any) => el.id === visualState?.visual?.measure);
		}
	}, [visualState?.visual?.measure, measures]);

	// using slicer params that are a type of array as options
	const getMultiplierOptions = useMemo(() => {
		const optionsArray = Object.entries(
			customDashboardState?.sliceValues
		)?.reduce((acc: any, [k, v]: any) => {
			if (Array.isArray(v)) {
				acc.push(k);
			}
			return acc;
		}, []);

		return optionsArray?.map((param: any) => ({ label: param, value: param }));
	}, [customDashboardState?.sliceValues]);

	// EFFECTS
	useEffect(() => {
		const measure = measures.find(
			(measure: any) => measure.id === visualState.visual.measure
		);
		if (measure) queryMeasure(measure);
	}, [visualState.visual.measure, measures]);

	return (
		<Spin spinning={visualState.loading}>
			<Row>
				<Col span={24}>
					<Form layout="vertical">
						<Row gutter={24}>
							<Col flex={1}>
								<Form.Item label="Measure">
									<Select
										placeholder="Measure"
										options={getMeasureOptions}
										dropdownStyle={{ zIndex: 30003 }}
										onChange={handleMeasureChange}
										value={visualState?.visual?.measure}
									/>
								</Form.Item>
								<PlotInputs />
								{visualState?.data?.length ? (
									<Form.Item label={"Preview"}>
										<PreviewTable data={visualState?.data} />
									</Form.Item>
								) : null}
								<Form.Item label="Positive Result Indicator">
									<Radio.Group
										value={visualState?.visual?.indicator}
										name="indicator"
										onChange={(e: any) =>
											visualDispatch({
												type: "UPDATE_VISUAL",
												payload: { name: "indicator", value: e.target.value },
											})
										}
									>
										<Radio.Button
											style={{ borderRadius: "2px 0 0 2px" }}
											value={"above"}
										>
											Above
										</Radio.Button>
										<Radio.Button
											style={{ borderRadius: "0 2px 2px 0" }}
											value={"below"}
										>
											Below
										</Radio.Button>
									</Radio.Group>
								</Form.Item>
							</Col>
							<Divider type="vertical" style={{ height: "auto" }} />
							<Col span={12}>
								<Form.Item>
									<Visual
										showDescription={false}
										plotStyle={{ height: 300 }}
										visual={visualState?.visual}
										params={measure?.params}
										// dispatch={dispatch}
									/>
								</Form.Item>

								<Form.Item label="Title">
									<Input
										name="title"
										value={visualState?.visual?.title}
										placeholder="Title"
										onChange={handleVisualChange}
									/>
								</Form.Item>

								<Form.Item
									label={
										<Space>
											Subtitle
											<Text className="custom-optional-text">(optional)</Text>
										</Space>
									}
								>
									<Input
										name="subtitle"
										value={visualState?.visual?.subtitle}
										placeholder="Subtitle"
										onChange={handleVisualChange}
									/>
								</Form.Item>
								<Form.Item
									label={
										<Space>
											Drill Down Measure
											<Text className="custom-optional-text">(optional)</Text>
										</Space>
									}
								>
									<Select
										placeholder="Drill down"
										options={getMeasureOptions}
										onChange={handleDrilldownChange}
										value={visualState?.visual?.drilldown}
										allowClear
									/>
								</Form.Item>
							</Col>
						</Row>
					</Form>
					<Divider />
					<Form labelAlign="left" labelCol={{ span: 8 }}>
						<Row>
							<Col span={24}>
								<TargetsConfig
									onChange={(target) => {
										visualDispatch({
											type: "UPDATE_VISUAL",
											payload: { name: "targets", value: target },
										});
									}}
									value={visualState?.visual?.targets}
									positiveIndicator={visualState?.visual?.indicator}
								/>
							</Col>
						</Row>
						{/* TARGET MULTIPLIER ADD-ON */}
						<Row style={{ marginTop: 16 }}>
							<Col span={8}>
								<Form.Item label="Target multiplier">
									<Radio.Group
										style={{ width: "100%" }}
										value={visualState?.visual?.target_multiplier}
										name="target_multiplier"
										onChange={(e: any) =>
											visualDispatch({
												type: "UPDATE_VISUAL",
												payload: {
													name: "target_multiplier",
													value: e.target.value,
												},
											})
										}
									>
										<MultiplierButton value={"none"}>None</MultiplierButton>
										<MultiplierButton value={"multiply"}>
											Multiply
										</MultiplierButton>
									</Radio.Group>
								</Form.Item>
							</Col>
						</Row>
						{visualState?.visual?.target_multiplier === "multiply" ? (
							<Row>
								<Col span={8}>
									<Form.Item label="Multiplier based on">
										<Select
											value={visualState?.visual?.target_based_on}
											allowClear
											options={getMultiplierOptions}
											onChange={(value: any) =>
												visualDispatch({
													type: "UPDATE_VISUAL",
													payload: {
														name: "target_based_on",
														value: value || "",
													},
												})
											}
										/>
									</Form.Item>
								</Col>
							</Row>
						) : null}
					</Form>
				</Col>
			</Row>
		</Spin>
	);
};

const MultiplierButton = ({ children, ...restProps }: RadioButtonProps) => {
	return (
		<Radio.Button
			{...restProps}
			style={{
				borderRadius: "2px 0 0 2px",
				textAlign: "center",
				width: "50%",
			}}
		>
			{children}
		</Radio.Button>
	);
};

export default ContentTab;
