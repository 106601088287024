import { Space } from "antd";
import SelectEntry from "./SelectEntry";
import { useCallback, useContext, useMemo } from "react";
import { MeasureContext } from "../../../contexts/context";

interface SelectClauseProps {
	entries?: Array<any>;
	onChange?: (entries: any) => void;
}

// List of select entries
const SelectClause = ({ entries, onChange = () => {} }: SelectClauseProps) => {
	const { state: measureState } = useContext(MeasureContext);

	const handleChange = useCallback(
		(id: string, updatedColumn: string) => {
			const updatedEntries = entries?.map((e: any) => {
				if (e.id === id) {
					return updatedColumn;
				}
				return e;
			});

			onChange({ columns: updatedEntries });
		},
		[entries]
	);

	const tableOptions = useMemo(() => {
		const selectedTables = measureState?.measure?.queryOptions?.from?.tables;
		return selectedTables
			?.filter((table: any) => table?.name)
			?.map((table: any) => ({
				label: table?.name,
				value: table?.name,
			}));
	}, [measureState]);

	const handleRemove = useCallback(
		(id: string) => {
			const updatedEntries = entries?.filter((e: any) => e.id !== id);
			onChange({ columns: updatedEntries });
		},
		[entries]
	);

	return (
		<Space direction="vertical" style={{ width: "100%" }}>
			{entries?.map((column: any, i: number) => (
				<SelectEntry
					key={column?.id || i}
					column={column}
					columns={entries}
					tableOptions={tableOptions}
					onChange={handleChange}
					onRemove={handleRemove}
					showDelete={entries?.length > 1}
					hideTable={tableOptions?.length <= 1}
				/>
			))}
		</Space>
	);
};

export default SelectClause;
