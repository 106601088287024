import { Form, Typography } from "antd"
import DataAccess from "./DataAccess"

const { Text } = Typography

type CustomDashboardInputsProps = {
    data_unauthorized?: any[], schema_unauthorized?: any
    onDataAccessChange?: (
        unauthorizedDataset: Array<string>,
        unauthorizedSchemas: Array<string>
    ) => void
}

const CustomDashboardInputs = ({ data_unauthorized = [], schema_unauthorized = [], onDataAccessChange = () => { } }: CustomDashboardInputsProps) => {
    return (
        <>
            <Form.Item>
                <Text style={{ fontSize: 20, marginBottom: 8 }}>
                    Dashboard Access Settings
                </Text>
            </Form.Item>
            <DataAccess
                onChange={onDataAccessChange}
                unauthorized_dataset={
                    data_unauthorized
                }
                unauthorized_schema={
                    schema_unauthorized
                }
            />
        </>
    )
}

export default CustomDashboardInputs