import {
	Button,
	Card,
	CardProps,
	Dropdown,
	Flex,
	MenuProps,
	Space,
	Tooltip,
} from "antd";
import { CustomLayout } from "../types/CustomLayout";
import DashboardItemContainer from "./DashboardItemContainer";
import { EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useContext, useMemo } from "react";
import { CustomDashboardContext } from "../contexts/context";
import { Layout, Layouts } from "react-grid-layout";
import { v4 as uuid } from "uuid";
import { getColors } from "../utils/utils";
import { DRAWER_KEYS } from "../reducers/customDashboardReducer";
import VisualActionsDropdown from "./VisualActionsDropdown";

type DashboardItemProps = CardProps & {
	item: CustomLayout;
	onLayoutChange?: (layout: CustomLayout) => void;
};

const DashboardItem = ({
	item,
	onLayoutChange = () => {},
	...restProps
}: DashboardItemProps) => {
	const { dispatch: customDashboardDispatch, state: customDashboardState } =
		useContext(CustomDashboardContext);
	const { children = [] } = item;

	// ? This is only applicable for group
	const group = customDashboardState.groups?.find((_group) =>
		_group?.layoutIds?.find((layoutId: string) => layoutId === item?.i)
	);

	const visual = customDashboardState.visuals.find((_visual) =>
		_visual?.layoutIds?.find(
			(layoutId: string) => layoutId === item?.children?.[0]?.i
		)
	);

	const onChange = (layout: Layout[], allLayouts: Layouts) => {
		const updateLayout: CustomLayout[] = customDashboardState.layout.map(
			(_layout) => {
				if (_layout.i === item.i) {
					return { ..._layout, children: layout, layouts: allLayouts };
				}
				return _layout;
			}
		);

		customDashboardDispatch({
			type: "UPDATE_LAYOUT",
			payload: updateLayout,
		});
	};

	const handleRemoveLayout = () => {
		customDashboardDispatch({ type: "REMOVE_LAYOUT", payload: item });
	};

	const handleAddGroup = (group: any) => {
		// find layout that is attached to a visual group
		// child.id = group id

		const { layoutIds = [] } = group;

		const destinationLayout = customDashboardState?.layout?.find((_item: any) =>
			layoutIds.includes(_item.i)
		);

		// if (!destinationLayout) {
		// }
		const destinationId = destinationLayout?.i || uuid();

		customDashboardDispatch({
			type: "ADD_TO_GROUP",
			payload: {
				source: { id: item.i },
				destination: { id: destinationId },
				group,
			},
		});
	};

	const defaultItems: MenuProps["items"] = useMemo(() => {
		return [
			{
				key: "delete",
				label: "Delete",
				onClick: handleRemoveLayout,
			},
		];
	}, []);

	const items: MenuProps["items"] =
		item.isGroup || customDashboardState.groups?.length === 0
			? defaultItems
			: [
					...defaultItems,
					{
						key: "add_group",
						label: "Add to group",
						children: customDashboardState.groups?.map((child: any) => ({
							key: child.id,
							label: child.name,
							onClick: () => handleAddGroup(child),
						})),
					},
			  ];

	const title = item.add ? null : item.isGroup ? group?.name : visual?.title;

	const cardStyles: CardProps = {
		style: item.isGroup
			? {
					background: getColors(group?.colour).backgroundColor,
					border: `solid 1px ${getColors(group?.colour).borderColor}`,
					borderRadius: 2,
					borderColor: getColors(group?.colour).borderColor,
			  }
			: {
					background: getColors(group?.colour).backgroundColor,
					border: `solid 1px ${getColors(group?.colour).borderColor}`,
					borderRadius: 2,
					borderColor: getColors(group?.colour).borderColor,
			  },
		styles: {
			body: {
				background: getColors(group?.colour).innerBackgroundColor,
			},
			header: item.isGroup
				? {
						background: getColors(group?.colour).headerColor,
						borderColor: getColors(group?.colour).borderColor,
						borderRadius: 2,
				  }
				: {
						background: getColors(group?.colour).headerColor,
						borderColor: getColors(group?.colour).borderColor,
						borderRadius: 2,
				  },
		},
	};

	const openVisualModal = () => {
		customDashboardDispatch({ type: "DRAWER", payload: DRAWER_KEYS.VISUALS });
	};

	return !item.add ? (
		<Card
			{...restProps}
			className="main-layout-card"
			style={{
				display: "flex",
				flexDirection: "column",
				width: "100%",
				...cardStyles.style,
			}}
			classNames={{
				body: item.isGroup || !visual?.drilldown ? "" : "viz-body-clickable",
			}}
			styles={{
				body: {
					height: "calc(100% - 56px)",
					padding: item.isGroup ? 0 : 16,
				},
				header: {
					...cardStyles.styles?.header,
				},
			}}
			title={
				!item.isGroup ? (
					<Tooltip title={visual?.subtitle} key={item.i}>
						{title}
					</Tooltip>
				) : (
					title
				)
			}
			extra={
				<Space>
					{!item.isGroup ? <VisualActionsDropdown visual={visual} /> : null}
					{item.add ? null : customDashboardState.editMode ? (
						<Dropdown
							trigger={["click"]}
							menu={{ items, rootClassName: "draggableCancel" }}
						>
							<EditOutlined />
						</Dropdown>
					) : null}
				</Space>
			}
			onMouseUp={() => {
				if (customDashboardState.editMode)
					customDashboardDispatch({ type: "IS_DRAG", payload: false });
			}}
		>
			<DashboardItemContainer
				item={item}
				layout={children}
				onLayoutChange={onChange}
				isGroup={item.isGroup}
				style={cardStyles.style}
				styles={{ body: { background: cardStyles.styles?.body?.background } }}
				onClick={() => {
					// this is non visual group visuals
					if (!item.isGroup && visual.drilldown)
						customDashboardDispatch({
							type: "SHOW_DRILLDOWN",
							payload: visual.drilldown,
						});
				}}
			/>
		</Card>
	) : (
		<Button
			onClick={openVisualModal}
			style={{ height: "100%", width: "100%", ...cardStyles.style }}
			type="link"
		>
			<Flex
				style={{ height: "inherit", width: "inherit" }}
				align="center"
				justify="center"
				vertical
				gap={8}
			>
				<PlusCircleOutlined style={{ fontSize: 32 }} />
				Add Item
			</Flex>
		</Button>
	);
};

export default DashboardItem;
