import { Modal, Space, Spin, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";

type DocumentViewerProps = {
	open: boolean;
	title: string;
	url: string;
	handleClose: () => void;
	handleOpen: () => void;
};

const { Text } = Typography;

const USER_AGENT = window.navigator.userAgent;
const isChrome = /Chrome/.test(USER_AGENT);
const isFirefox = /Firefox/.test(USER_AGENT);
const isSafari = /Safari/.test(USER_AGENT) && /Apple Computer/.test(USER_AGENT);
const isEdge = /Edg/.test(USER_AGENT);

const DocumentViewer = ({
	open,
	title,
	url,
	handleClose,
	handleOpen,
}: DocumentViewerProps) => {
	const iframeRef: any = useRef();
	const [isLoading, setIsLoading] = useState(false);
	const ismobile = useSelector((state: any) => state.ismobile);

	// show modal title depending on browsers
	const showTitle = ismobile || isEdge || isFirefox || isSafari;

	// console.log({ user_agent: USER_AGENT });
	// console.log({ isChrome, isFirefox, isSafari, isEdge });

	useEffect(() => {
		setIsLoading(true);
	}, [url]);
	return (
		<Modal
			destroyOnClose
			onCancel={handleClose}
			open={open}
			wrapClassName="document-viewer"
			className="document-viewer"
			width={"100%"}
			styles={{
				body: {
					height: "96vh",
					borderRadius: "2px",
					padding: "0",
					paddingTop: showTitle ? "0" : "48px",
				},
			}}
			style={{ top: 10 }}
			footer={null}
			title={
				showTitle ? (
					<div
						className="document-viewer-title"
						style={{ paddingRight: "24px" }}
					>
						<Text>{title}</Text>
					</div>
				) : null
			}
		>
			{isLoading && (
				<Space
					style={{
						display: "flex",
						height: "100%",
						width: "100%",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Spin spinning size="large" />
				</Space>
			)}
			<iframe
				ref={iframeRef}
				title={title}
				onLoad={() => {
					setIsLoading(false);
					// console.log(iframeRef.current);
				}}
				seamless={true}
				src={`${url}`}
				width="100%"
				height="100%"
				style={{
					overflow: "hidden",
					opacity: isLoading ? 0 : 1,
				}}
			>
				<a href={`${url}#toolbar=0`}>to the PDF!</a>
			</iframe>
		</Modal>
	);
};

const mapStateToProps = (state: any) => {
	return {
		ismobile: state.ismobile,
	};
};

export default connect(mapStateToProps)(DocumentViewer);
