import { Space, Table, TableProps, Typography } from "antd";
import { useCallback, useContext, useMemo } from "react";
import Column from "antd/es/table/Column";
import { Dictionary } from "lodash";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import MeasureDeleteHandler from "./MeasureDeleteHandler";
import { CustomDashboardContext } from "../contexts/context";
import CustomParagraph from "./CustomParagraph";
dayjs.extend(utc);
dayjs.extend(timezone);

const { Link } = Typography;

interface MeasureListProps extends TableProps<any> {
	onEdit?: Function;
	measures?: Array<any>;
}

const columnDict: Dictionary<string> = {
	name: "Name",
	description: "Description",
	lastUpdatedAt: "Latest update",
	// lastUpdatedBy: "Latest update by",
	// createdAt: "Created",
	createdBy: "Created by",
	actions: "Actions",
};

const MeasureList = ({
	onEdit = () => {},
	measures = [],
	...restProps
}: MeasureListProps) => {
	const {
		isOwner,
		isViewer,
		visuals,
		state: customDashboardState,
	} = useContext(CustomDashboardContext);
	const actionable = !customDashboardState.editMode;

	const ds = useMemo(() => {
		const sortedMeasures = measures.sort((a, b) => {
			if (dayjs(a?.lastUpdatedAt).isAfter(b?.lastUpdatedAt)) return -1
			return 1
		})
		return generateDataSource(sortedMeasures);
	}, [measures]);

	const renderColumns = useMemo(() => {
		const cols: any = [];
		for (const field in columnDict) {
			if (field === "actions") {
				if (isOwner) {
					cols.push(
						<Column
							className="measure-list-column"
							dataIndex={field}
							key={field}
							title={columnDict[field]}
							render={(_, record: any) => <Space style={{ width: "max-content" }}>
								<Link
									disabled={!actionable}
									onClick={() => handleEdit(record.id)}
								>
									Edit
								</Link>
								<MeasureDeleteHandler targetId={record.id} />
							</Space>}
						/>
					);
				} else if (isViewer) {
					cols.push(
						<Column
							className="measure-list-column"
							dataIndex={field}
							key={field}
							title={columnDict[field]}
							render={(_, record: any) => {
								return (
									<Space style={{ width: "max-content" }}>
										<Link onClick={() => handleEdit(record.id)}>View</Link>
									</Space>
								);
							}}
						/>
					);
				}
			} else if (field === "description") {
				const colRender = (value: any) => {
					return <CustomParagraph>{value}</CustomParagraph>;
				};
				cols.push(
					<Column
						className="measure-list-column"
						dataIndex={field}
						key={field}
						title={columnDict[field]}
						render={colRender}
					/>
				);
			} else if (field === "lastUpdatedAt") {
				const colRender = (value: any) => {
					return dayjs(value).format("DD-MM-YYYY hh:mm:ss a");
				};
				cols.push(
					<Column
						className="measure-list-column"
						dataIndex={field}
						key={field}
						title={columnDict[field]}
						render={colRender}
					/>
				);
			} else {
				cols.push(
					<Column
						className="measure-list-column"
						dataIndex={field}
						key={field}
						title={columnDict[field]}
					/>
				);
			}
		}
		return cols;
	}, [measures, visuals]);

	const handleEdit = useCallback((id: string) => {
		onEdit(id);
	}, []);

	return (
		<Table
			className="measure-list-table"
			dataSource={ds}
			bordered
			size="small"
			pagination={false}
			tableLayout="fixed"
			style={{ overflowY: "scroll" }}
			{...restProps}
		>
			{renderColumns}
		</Table>
	);
};

const generateDataSource = (measures: Measure[]) => {
	if (measures?.length === 0) return [];
	return measures.map((measure) => ({ ...measure, key: measure.id }));
};

export default MeasureList;
