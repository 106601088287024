import { Card, CardProps, Dropdown, Space, Tooltip } from "antd";
import { useContext, useEffect, useMemo, useRef } from "react";
import { Layout, Layouts, Responsive, WidthProvider } from "react-grid-layout";
import { CustomDashboardContext } from "../contexts/context";
import Viz from "./Viz";
import { EditOutlined } from "@ant-design/icons";
import { Visual } from "../types/Visual";
import { CONTAINER_PADDING } from "../reducers/customDashboardReducer";
import useResizeObserver from "../hooks/useResizeObserver";
import VisualActionsDropdown from "./VisualActionsDropdown";

type DashboardItemContainerProps = CardProps & {
	item: Layout & { layouts?: Layouts };
	onLayoutChange?: (layout: Layout[], allLayouts: Layouts) => void;
	layout: Layout[];
	isGroup?: boolean;
};

let timeout: any = null;

const DashboardItemContainer = ({
	item,
	layout,
	onLayoutChange = () => {},
	isGroup,
	...restProps
}: DashboardItemContainerProps) => {
	const wrapperRef = useRef(null);
	const dimensions = useResizeObserver(wrapperRef);

	const ResponsiveGridLayout = useMemo(
		() => WidthProvider(Responsive),
		[item.w]
	);

	const { state: customDashboardState, dispatch: customDashboardDispatch } =
		useContext(CustomDashboardContext);

	const renderOne = () => {
		const visual = customDashboardState?.visuals?.find((_visual) =>
			_visual?.layoutIds?.find((layoutId: string) => layoutId === layout[0]?.i)
		);

		return (
			<Card
				{...restProps}
				key={layout[0].i}
				style={{
					...restProps.style,
					border: "none",
					height: "100%",
				}}
				styles={{ body: { padding: 0, height: "100%" } }}
			>
				{visual ? (
					<Viz
						visual={visual}
						style={{
							background: restProps?.style?.background,
							borderColor: restProps?.style?.borderColor,
						}}
					/>
				) : null}
			</Card>
		);
	};

	// ? Only applicable to visuals that are in groups
	const handleMove = (layout: Layout, visual: Visual) => {
		customDashboardDispatch({
			type: "MOVE_VISUAL_TO_MAIN",
			payload: { layout, visual },
		});
	};

	const handleDelete = (layout: Layout, visual: Visual) => {
		customDashboardDispatch({
			type: "DELETE_VISUAL_FROM_GROUP",
			payload: { layout, visual },
		});
	};

	useEffect(() => {
		if (!customDashboardState.isResize && !customDashboardState.isDrag) {
			timeout = setTimeout(() => {
				customDashboardDispatch({
					type: "RESIZE_GROUP",
					payload: { item, dimensions },
				});
			}, 100);
		}

		return () => {
			clearTimeout(timeout);
		};
	}, [dimensions, customDashboardState.isResize, customDashboardState.isDrag]);

	return isGroup ? (
		<div ref={wrapperRef} id={`inner-layout-wrapper-${item.i}`}>
			<ResponsiveGridLayout
				className={`inner-layout-${item.i}`}
				layouts={item.layouts}
				onDragStart={(layout, oldItem, newItem, placeholder, e) => {
					e.stopPropagation();
					customDashboardDispatch({ type: "IS_DRAG", payload: true });
				}}
				onDragStop={() => {
					customDashboardDispatch({ type: "IS_DRAG", payload: false });
				}}
				onLayoutChange={onLayoutChange}
				isDraggable={customDashboardState.editMode}
				isResizable={customDashboardState.editMode}
				rowHeight={80}
				containerPadding={[CONTAINER_PADDING, CONTAINER_PADDING]}
				breakpoints={{ lg: 800, md: 400, sm: 200 }}
				cols={{ lg: 3, md: 2, sm: 1 }}
				draggableCancel=".draggableCancel"
			>
				{layout?.map((child) => {
					const visual = customDashboardState.visuals.find((_visual) =>
						_visual?.layoutIds?.find((layoutId: string) => layoutId === child.i)
					);

					return (
						<div key={child.i}>
							<Card
								{...restProps}
								style={{
									...restProps.style,
									background: restProps.styles?.body?.background,
									height: "100% ",
								}}
								title={
									<Tooltip title={visual?.subtitle} key={child.i}>
										{visual?.title}
									</Tooltip>
								}
								styles={{
									body: {
										height: "calc(100% - 56px)",
									},
									header: {
										...restProps.styles?.header,
										borderColor: restProps.style?.borderColor,
									},
								}}
								classNames={{
									body: visual?.drilldown ? "viz-body-clickable" : "",
								}}
								extra={
									<Space>
										<VisualActionsDropdown visual={visual} />
										{customDashboardState.editMode && visual ? (
											<Dropdown
												trigger={["click"]}
												menu={{
													rootClassName: "draggableCancel",
													items: [
														{
															key: "delete",
															label: "Delete",
															onClick: () => handleDelete(child, visual),
														},
														{
															key: "move",
															label: "Move to Main Layout",
															onClick: () => handleMove(child, visual),
														},
													],
												}}
											>
												<EditOutlined />
											</Dropdown>
										) : null}
									</Space>
								}
							>
								{visual ? (
									<Viz
										visual={visual}
										style={{
											background: restProps?.styles?.body?.background,
											borderColor: restProps?.style?.borderColor,
										}}
									/>
								) : null}
							</Card>
						</div>
					);
				})}
			</ResponsiveGridLayout>
		</div>
	) : (
		renderOne()
	);
};

export default DashboardItemContainer;
