import { Button, Select, Space } from "antd";
import { useCallback, useContext, useMemo } from "react";
import {
	commonSelectProps,
	compareSelectOptions,
	functions,
} from "../../../utils/queryBuilder";
import { CustomDashboardContext } from "../../../contexts/context";
import { GetAntIcon } from "../../../utils/ant_icons";

interface SelectEntryProps {
	column?: any;
	columns?: Array<any>;
	tableOptions?: Array<any>;
	showDelete?: boolean;
	hideTable?: boolean;
	onChange?: (id: string, column: any) => void;
	onRemove?: (id: string) => void;
}

const SelectEntry = ({
	column,
	columns,
	tableOptions,
	showDelete,
	hideTable,
	onChange = () => {},
	onRemove = () => {},
}: SelectEntryProps) => {
	const { tables } = useContext(CustomDashboardContext);

	const options = useMemo(() => {
		// Filtering all selected columns that are using using the same table
		const columnsWithSameTable = columns?.filter(
			(col: any) => col?.table?.name === column?.table?.name
		);

		// Filtering tables by name that is not null
		const selectedFields = columnsWithSameTable
			?.map((col: any) => col?.name)
			.filter((field: string) => field);

		// Getting the columns with the same table only
		const tablesHasFunction = columnsWithSameTable?.filter(
			(col: any) => col?.function
		);

		// Columns with same function
		const tablesWithSameFunction = columnsWithSameTable?.filter(
			(col: any) => column?.function === col?.function
		);

		// Fields that need to be allowed
		const isAllowed = (field: any) => {
			// Handles when there are entries with same functions
			const thisFields = tablesWithSameFunction
				?.filter((col: any) => col?.name)
				?.map((col: any) => col?.name);

			if (tablesHasFunction?.length === 0) {
				return !selectedFields?.includes(field?.name);
			} else {
				return !thisFields?.includes(field?.name);
			}
		};

		// Getting the whole table object that contains fields by name
		const foundTable = tables?.find(
			(tbl: any) => tbl?.name === column?.table?.name
		);

		if (!foundTable) return [];

		const { fields = [] } = foundTable;
		return fields
			?.filter((field: any) => isAllowed(field))
			.map((field: any) => ({
				label: field?.name,
				value: field?.name,
			}));
	}, [tables, column, columns]);

	const isFunctionRemovable = useMemo(() => {
		// Filtering all selected columns that are using using the same table
		const columnsWithSameTable = columns?.filter(
			(col: any) => col?.table?.name === column?.table?.name
		);

		// Getting the columns with the same table only
		const allTablesWithSameField = columnsWithSameTable?.filter(
			(col: any) => col?.name === column?.name
		);
		// Getting the columns with the same table only
		const tablesWithSameField = columnsWithSameTable?.filter(
			(col: any) =>
				col?.id === column?.id &&
				col?.name === column?.name &&
				col?.name !== undefined
		);

		return (
			tablesWithSameField?.length === 0 ||
			allTablesWithSameField?.every((col: any) => col?.function)
		);
	}, [columns, column]);

	const functionOptions = useMemo(() => {
		// Filtering all selected columns that are using using the same table
		const columnsWithSameTable = columns?.filter(
			(col: any) => col?.table?.name === column?.table?.name
		);

		// Getting the columns with the same table only
		const allTablesWithSameField = columnsWithSameTable?.filter(
			(col: any) => col?.name === column?.name
		);

		// Getting the columns with the same table only
		const tablesWithSameField = columnsWithSameTable?.filter(
			(col: any) => col?.id !== column?.id && col?.name === column?.name
		);

		if (tablesWithSameField?.length === 0) return functions;

		return functions?.filter((func: any) => {
			const selectedFunctions = allTablesWithSameField?.map(
				(col: any) => col?.function
			);

			if (!selectedFunctions?.includes(func?.value)) return func;
		});
	}, [columns, functions, column]);

	const handleChange = useCallback(
		(key: string, value: any) => {
			const { id } = column;
			const updatedColumn = { ...column, [key]: value };
			onChange(id, updatedColumn);
		},
		[column, onChange]
	);

	const handleRemove = useCallback(() => {
		const { id } = column;
		onRemove(id);
	}, [column, onRemove]);

	return (
		<Space.Compact
			style={{ width: "100%", display: "flex", whiteSpace: "nowrap" }}
		>
			{!hideTable && (
				<Select
					{...commonSelectProps}
					placeholder="Table"
					options={tableOptions}
					onChange={(value) => handleChange("table", { name: value })}
					value={column?.table?.name}
				/>
			)}
			<Select
				{...commonSelectProps}
				placeholder="Column"
				options={options}
				filterSort={compareSelectOptions}
				onChange={(value) => handleChange("name", value)}
				value={column?.name}
			/>
			<Select
				{...commonSelectProps}
				placeholder="Function"
				options={functionOptions}
				onChange={(value) => handleChange("function", value)}
				value={column?.function}
				allowClear={isFunctionRemovable}
			/>
			<Button
				style={{ display: showDelete ? "block" : "none" }}
				danger
				icon={GetAntIcon("delete")}
				onClick={handleRemove}
			/>
		</Space.Compact>
	);
};

export default SelectEntry;
