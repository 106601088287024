import { Table, TableProps } from "antd";
import dayjs from "dayjs";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { capitalize } from "../utils/dataTools";
import { MeasureContext } from "../contexts/context";
import { ACTIONTYPES } from "../reducers/measureReducer";

interface FieldTableProps extends TableProps<any> {}

const FieldTable = ({ ...restProps }: FieldTableProps) => {
	const { state: measureState, dispatch: measureDispatch } =
		useContext(MeasureContext);
	const dataSource = useMemo(() => {
		let filteredFields = measureState?.selectedDataset?.fields;
		if (!filteredFields) return [];
		if (measureState.searchText !== "") {
			filteredFields = filteredFields.filter((field: any) =>
				field.name.includes(measureState.searchText)
			);
		}
		return generateDataSource(filteredFields);
	}, [measureState?.selectedDataset?.fields, measureState.searchText]);

	const columns = useMemo(() => {
		if (!dataSource) return [];
		return generateColumns(dataSource);
	}, [dataSource]);

	const onRow = useCallback(
		(record: any) => {
			return {
				onClick: (ev: any) => {
					measureDispatch({
						type: ACTIONTYPES.FIELD,
						payload: record.key,
					});
				},
				className:
					measureState.selectedfield === record?.key ? "selected-field" : "",
				style: { cursor: "pointer" },
			};
		},
		[measureState.selectedfield, measureState.selectedDataset.name]
	);

	const scrollTo = useCallback((position: number) => {
		const table = document.querySelector(".field-table div.ant-table-body");
		if (table) table.scrollTop = position;
	}, []);

	useEffect(() => {
		const row = document.querySelector(".field-table-row");
		if (row && measureState.selectedfield) {
			const index = measureState?.selectedDataset?.fields?.findIndex(
				(field: any) => field.name === measureState.selectedfield
			);
			const scrollTop = row.clientHeight * index;
			scrollTo(scrollTop);
		} else {
			scrollTo(0);
		}
	}, [
		measureState?.selectedDataset?.fields,
		measureState.selectedfield,
		measureState.searchText,
	]);

	if (!measureState?.selectedDataset?.fields) return null;

	return (
		<Table
			dataSource={dataSource}
			columns={columns}
			size="small"
			pagination={false}
			rowClassName={"field-table-row"}
			tableLayout="auto"
			bordered
			scroll={{ y: 212 }}
			onRow={onRow}
			title={() => "Fields"}
			rootClassName="field-table"
			style={{ maxHeight: 300 }}
			{...restProps}
		/>
	);
};

const generateDataSource = (data: Array<any>) => {
	const date = dayjs().toISOString();
	return data?.map((d, i) => {
		const { nullable, metadata, ...restData } = d;
		return { key: d.name, ...restData, latest_value: date };
	});
};

const generateColumns = (data: Array<any>) => {
	if (data?.length === 0) return;
	const { key, ...restData } = data?.[0];
	const columns: any = [];
	for (const field in restData) {
		switch (field) {
			case "name":
				columns.push({ dataIndex: field, key: field, title: "Field" });
				break;
			case "type":
				columns.push({ dataIndex: field, key: field, title: "Data Type" });
				break;

			default:
				columns.push({
					dataIndex: field,
					key: field,
					title: capitalize(field),
				});
				break;
		}
	}
	return columns;
};

export default FieldTable;
