import { v4 as uuid } from "uuid";
import {
	checkTable,
	initParameters,
	replaceTable,
} from "../utils/queryBuilder";

const ACTIONTYPES = {
	LOADING: "LOADING",
	TABLES: "TABLES",
	METADATAS: "METADATAS",
	DATASET: "DATASET",
	QUERY: "QUERY",
	ERROR: "ERROR",
	PREVIEWDATA: "PREVIEWDATA",
	QUERYLOADING: "QUERYLOADING",
	FIELD: "FIELD",
	INCLUDEFIELD: "INCLUDEFIELD",
	QUERYTEMPLATESMODAL: "QUERYTEMPLATESMODAL",
	INSERTQUERY: "INSERTQUERY",
	MEASURE: "MEASURE",
	UPDATE_MEASURE: "UPDATE_MEASURE",
	SAVE_MEASURE_START: "SAVE_MEASURE_START",
	SAVE_MEASURE_END: "SAVE_MEASURE_END",
	RESET: "RESET",
	CREATE_MEASURE: "CREATE_MEASURE",
	EDIT_MEASURE: "EDIT_MEASURE",
	HISTORY: "HISTORY",
	SEARCH: "SEARCH",
	ADD_CLAUSE: "ADD_CLAUSE",
	DELETE_CLAUSE: "DELETE_CLAUSE",
	UPDATE_QUERY_OPTIONS: "UPDATE_QUERY_OPTIONS",
	REMOVE_CLAUSE: "REMOVE_CLAUSE",
	INSERT_CLAUSE: "INSERT_CLAUSE",
	SAVABLE: "SAVABLE",
	DIRTY: "DIRTY",
	ALLOW_STRING_QUERY: "ALLOW_STRING_QUERY",
	NAME_ERR: "NAME_ERR",
	TOP_HUNDRED_DATA: "TOP_HUNDRED_DATA",
	GO_NEXT: "GO_NEXT",
	GO_PREV: "GO_PREV",
	PARAMS_OK: "PARAMS_OK",
	SCHEMA: "SCHEMA",
	VIEW_SCHEMAS: "VIEW_SCHEMAS",
	VIEW_DATASETS: "VIEW_DATASETS",
	VIEW_FIELDS: "VIEW_FIELDS",
	ONSEARCH: "ONSEARCH",
	TAB: "TAB",
};

const MODE_TYPE = {
	FIELD: "FIELD",
	DATASET: "DATASET",
	SCHEMA: "SCHEMA",
};

const PAGE_TYPE = {
	QUERY: "QUERY",
	NAMEDESC: "NAMEDESC",
};

const tableId = uuid();

const INITIALSTATE = {
	loading: false,
	tables: [],
	metadatas: [],
	datasource: null,
	query: "",
	errorMsg: "",
	previewdata: [],
	fieldselectiondata: [],
	queryloading: false,
	querydirty: false,
	table: "",
	selectedvalue: null,
	includefield: true,
	querytemplatesmodal: false,
	measure: {
		id: "",
		name: null,
		description: null,
		allowStringQuery: true,
		queryOptions: {
			from: {
				tables: [{ id: tableId, name: null }],
			},
		},
		queryStatement: "",
		params: initParameters,
	},
	savemeasureloading: false,
	mode: MODE_TYPE.SCHEMA,
	history: [{ mode: MODE_TYPE.SCHEMA, title: "Schema" }],
	editMode: false,
	savable: false,
	dirty: false,
	errorMessage: "",
	topHundredData: [],
	page: PAGE_TYPE.QUERY,
	paramsOk: false,
	required: [],
	selectedfield: null,
	selectedSchema: "",
	selectedDataset: { fields: [], name: "" },
	searchText: "",
	activeTab: "",
};

const reducer = (state: any, action: any) => {
	switch (action.type) {
		case ACTIONTYPES.TAB:
			return { ...state, activeTab: action.payload };

		case ACTIONTYPES.VIEW_SCHEMAS:
			return {
				...state,
				mode: MODE_TYPE.SCHEMA,
				history: INITIALSTATE.history,
				searchText: INITIALSTATE.searchText,
				topHundredData: INITIALSTATE.topHundredData,
				selectedSchema: INITIALSTATE.selectedSchema,
				selectedDataset: INITIALSTATE.selectedDataset,
				selectedfield: INITIALSTATE.selectedfield,
			};

		case ACTIONTYPES.VIEW_DATASETS:
			const index1 = state.history.findIndex(
				(h: any) => h.mode === MODE_TYPE.DATASET
			);

			return {
				...state,
				mode: MODE_TYPE.DATASET,
				history: state.history.slice(0, index1),
				searchText: INITIALSTATE.searchText,
				topHundredData: INITIALSTATE.topHundredData,
				selectedDataset: INITIALSTATE.selectedDataset,
				selectedfield: INITIALSTATE.selectedfield,
			};

		case ACTIONTYPES.VIEW_FIELDS:
			const index2 = state.history.findIndex(
				(h: any) => h.mode === MODE_TYPE.FIELD
			);

			return {
				...state,
				mode: MODE_TYPE.FIELD,
				history: state.history.slice(0, index2),
				selectedfield: INITIALSTATE.selectedfield,
				searchText: INITIALSTATE.searchText,
			};

		case ACTIONTYPES.SCHEMA:
			return {
				...state,
				selectedSchema: action.payload,
				mode: MODE_TYPE.DATASET,
				searchText: INITIALSTATE.searchText,
				history: state.history?.map((h: any) => {
					if (h.mode === MODE_TYPE.SCHEMA) {
						return { ...h, title: action.payload };
					}
					return h;
				}),
			};

		case ACTIONTYPES.DATASET:
			return {
				...state,
				selectedDataset: action.payload,
				mode: MODE_TYPE.FIELD,
				searchText: INITIALSTATE.searchText,
				history: [
					...state.history.filter((h: any) => h.mode),
					{ mode: MODE_TYPE.DATASET, title: action.payload.name },
				],
				table: action.payload.name,
				fieldselectiondata: INITIALSTATE.fieldselectiondata,
				selectedvalue: INITIALSTATE.selectedvalue,
			};

		case ACTIONTYPES.FIELD:
			return {
				...state,
				selectedfield: action.payload,
				mode: MODE_TYPE.FIELD,
				history: state.history.find((h: any) => h.mode === MODE_TYPE.FIELD)
					? state.history
							.filter((h: any) => h.mode)
							.map((h: any) => {
								if (h.mode === MODE_TYPE.FIELD) {
									return { mode: MODE_TYPE.FIELD, title: action.payload };
								}
								return h;
							})
					: [
							...state.history.filter((h: any) => h.mode),
							{ mode: MODE_TYPE.FIELD, title: action.payload },
					  ],
			};

		case ACTIONTYPES.ONSEARCH:
			return { ...state, searchText: action.payload };
		case ACTIONTYPES.PARAMS_OK:
			const { paramsOk, required } = action.payload;
			return { ...state, paramsOk, required };

		case ACTIONTYPES.GO_NEXT:
			return { ...state, page: PAGE_TYPE.NAMEDESC };

		case ACTIONTYPES.GO_PREV:
			return { ...state, page: PAGE_TYPE.QUERY };

		case ACTIONTYPES.ALLOW_STRING_QUERY:
			return {
				...state,
				measure: { ...state.measure, allowStringQuery: action.payload },
			};

		case ACTIONTYPES.INSERT_CLAUSE:
			const { clause, value } = action.payload;

			let singleTable = true;

			if (clause !== "from") {
				singleTable = state.measure?.queryOptions["from"]?.tables?.length === 1;
			}

			switch (clause) {
				case "select":
				case "groupBy":
					let newClause: any = { id: uuid(), name: null };
					if (singleTable)
						newClause = {
							...newClause,
							table: state?.tables?.find((tbl: any) => {
								return (
									tbl?.name ===
									state?.measure?.queryOptions["from"]?.tables[0]?.name
								);
							}),
						};

					return {
						...state,
						measure: {
							...state.measure,
							queryOptions: {
								...state.measure.queryOptions,
								[clause]: {
									...state.measure.queryOptions[clause],
									columns: [
										...state.measure.queryOptions[clause].columns,
										newClause,
									],
								},
							},
						},
					};

				case "from":
					return {
						...state,
						measure: {
							...state.measure,
							queryOptions: {
								...state.measure.queryOptions,
								[clause]: {
									...state.measure.queryOptions[clause],
									tables: [
										...state.measure.queryOptions[clause].tables,
										{ id: uuid(), name: null, type: value },
									],
								},
							},
						},
					};

				case "where":
					let newWhere: any = { id: uuid(), name: null, op: null, value: null };
					if (value) newWhere = { ...newWhere, bitOp: value };
					if (singleTable)
						newWhere = {
							...newWhere,
							table: state?.tables?.find((tbl: any) => {
								return (
									tbl?.name ===
									state.measure?.queryOptions["from"]?.tables[0]?.name
								);
							}),
						};

					return {
						...state,
						measure: {
							...state.measure,
							queryOptions: {
								...state.measure.queryOptions,
								[clause]: {
									...state.measure.queryOptions[clause],
									columns: [
										...state.measure.queryOptions[clause].columns,
										newWhere,
									],
								},
							},
						},
					};

				case "orderBy":
					return {
						...state,
						measure: {
							...state.measure,
							queryOptions: {
								...state.measure.queryOptions,
								[clause]: {
									...state.measure.queryOptions[clause],
									columns: [
										...state.measure.queryOptions[clause].columns,
										singleTable
											? {
													id: uuid(),
													name: null,
													order: "ASC",
													table: state?.tables?.find((tbl: any) => {
														return (
															tbl?.name ===
															state.measure?.queryOptions["from"]?.tables[0]
																?.name
														);
													}),
											  }
											: { id: uuid(), name: null, order: "ASC" },
									],
								},
							},
						},
					};

				default:
					return state;
			}

		case ACTIONTYPES.REMOVE_CLAUSE:
			if (action.payload.clause === "from") {
				const updatedFrom = {
					[action.payload.clause]: {
						...state.measure.queryOptions[action.payload.clause],
						tables: [
							...state.measure.queryOptions[
								action.payload.clause
							].tables.filter((table: any) => table.id !== action.payload.id),
						],
					},
				};

				let updatedOptions = {
					...state.measure.queryOptions,
					...updatedFrom,
				};

				updatedOptions = checkTable(updatedOptions);

				return {
					...state,
					measure: { ...state.measure, queryOptions: updatedOptions },
				};
			}

			return {
				...state,
				measure: {
					...state.measure,
					queryOptions: {
						...state.measure.queryOptions,
						[action.payload.clause]: {
							...state.measure.queryOptions[action.payload.clause],
							columns: [
								...state.measure.queryOptions[
									action.payload.clause
								].columns.filter(
									(column: any) => column.id !== action.payload.id
								),
							],
						},
					},
				},
			};

		case ACTIONTYPES.UPDATE_QUERY_OPTIONS:
			let updatedOptions: any = {
				...state.measure.queryOptions,
				[action.payload.key]: action.payload.value,
			};

			// handling from clause separately here
			if (action.payload.key === "from") {
				const entries = action?.payload?.value?.tables;
				const updatedEntries = entries?.map((entry: any) => {
					let updatedEntry = { ...entry };
					if (!entries?.find((e: any) => e?.name === entry?.left?.name)) {
						updatedEntry = {
							...updatedEntry,
							left: { name: null },
							leftKey: null,
						};
					}
					if (!entries?.find((e: any) => e?.name === entry?.right?.name)) {
						updatedEntry = {
							...updatedEntry,
							right: { name: null },
							rightKey: null,
						};
					}
					return updatedEntry;
				});
				updatedOptions = {
					...updatedOptions,
					[action.payload.key]: { tables: updatedEntries },
				};
			}

			// special case for from clause as other clauses depends on the from clauses
			updatedOptions = checkTable(updatedOptions);
			updatedOptions = replaceTable(updatedOptions, state?.tables);

			return {
				...state,
				measure: { ...state.measure, queryOptions: updatedOptions },
				dirty: true,
			};

		case ACTIONTYPES.DELETE_CLAUSE:
			let newQueryOptions = {};
			for (const field in state.measure.queryOptions) {
				if (field !== action.payload) {
					newQueryOptions = {
						...newQueryOptions,
						[field]: state.measure.queryOptions[field],
					};
				}
			}

			return {
				...state,
				measure: { ...state.measure, queryOptions: newQueryOptions },
				dirty: true,
			};

		case ACTIONTYPES.ADD_CLAUSE:
			let updated = { ...state.measure.queryOptions, ...action.payload };

			updated = replaceTable(updated, state?.tables);

			return {
				...state,
				measure: { ...state.measure, queryOptions: updated },
			};

		case ACTIONTYPES.LOADING:
			return { ...state, loading: action.payload };

		case ACTIONTYPES.TABLES:
			return { ...state, tables: action.payload };

		case ACTIONTYPES.METADATAS:
			return { ...state, metadatas: action.payload };

		case ACTIONTYPES.QUERY:
			return {
				...state,
				query: action.payload,
			};

		case ACTIONTYPES.ERROR:
			return {
				...state,
				errorMsg: action.payload,
				previewdata: [],
				querydirty: true,
				queryloading: false,
			};

		case ACTIONTYPES.PREVIEWDATA:
			return {
				...state,
				errorMsg: "",
				previewdata: action.payload.response,
				query: action.payload.query,
				querydirty: true,
				queryloading: false,
			};

		case ACTIONTYPES.TOP_HUNDRED_DATA:
			return {
				...state,
				topHundredData: action.payload.response,
			};

		case ACTIONTYPES.QUERYLOADING:
			return {
				...state,
				queryloading: action.payload,
			};

		case ACTIONTYPES.INCLUDEFIELD:
			return { ...state, includefield: action.payload };

		case ACTIONTYPES.QUERYTEMPLATESMODAL:
			return { ...state, querytemplatesmodal: action.payload };

		case ACTIONTYPES.INSERTQUERY:
			return { ...state, querytemplatesmodal: false, query: action.payload };

		case ACTIONTYPES.MEASURE:
			return { ...state, measure: action.payload, dirty: true };

		case ACTIONTYPES.UPDATE_MEASURE:
			const updatedMeasure = {
				...state.measure,
				[action.payload.key]: action.payload.value,
			};
			return {
				...state,
				measure: updatedMeasure,
				dirty: true,
			};

		case ACTIONTYPES.SAVE_MEASURE_START:
			return { ...state, savemeasureloading: true };

		case ACTIONTYPES.SAVE_MEASURE_END:
			return { ...state, savemeasureloading: false };

		case ACTIONTYPES.CREATE_MEASURE:
			const newMeasure = INITIALSTATE.measure;
			return {
				...state,
				measure: {
					...newMeasure,
					id: uuid(),
				},
			};

		case ACTIONTYPES.SAVABLE:
			if (action.payload) {
				return {
					...state,
					savable: action.payload,
					errorMessage: INITIALSTATE.errorMessage,
				};
			}
			return { ...state, savable: action.payload };

		case ACTIONTYPES.NAME_ERR:
			return { ...state, errorMessage: action.payload, savable: false };

		case ACTIONTYPES.DIRTY:
			return { ...state, dirty: action.payload };

		case ACTIONTYPES.HISTORY:
			return { ...state, history: action.payload };

		case ACTIONTYPES.SEARCH:
			return {
				...state,
				fieldselectiondata: INITIALSTATE.fieldselectiondata,
				selectedfield: INITIALSTATE.selectedfield,
				history: state.history?.filter((h: any) => h.mode !== MODE_TYPE.FIELD),
			};

		case ACTIONTYPES.EDIT_MEASURE:
			if (action.payload) {
				const { description = null, ...restProps } = action.payload;

				if (description) {
					return {
						...state,
						measure: {
							description,
							...restProps,
						},
						editMode: true,
					};
				}
				return {
					...state,
					measure: { ...restProps },
					editMode: true,
				};
			}
			return state;

		case ACTIONTYPES.RESET:
			const { metadatas, tables, ...restState } = INITIALSTATE;
			return { ...state, ...restState };

		default:
			return state;
	}
};

export { ACTIONTYPES, INITIALSTATE, reducer, MODE_TYPE, PAGE_TYPE };
