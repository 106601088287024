import { useCallback, useState } from "react";
import {
	Button,
	Flex,
	Space,
	Switch,
	Table,
	TableColumnsType,
	Typography,
} from "antd";
import { useMainContext } from "../../contexts/MainContext";

type DataAccessProps = {
	onChange?: (unauthorized: Array<string>, disabledSchemas: Array<any>) => void;
	unauthorized_schema?: Array<string>;
	unauthorized_dataset?: Array<string>;
};

interface DataType {
	key: React.Key;
	schema: string;
	description: string;
	access: boolean;
}

interface ExpandedDataType {
	key: React.Key;
	name: string;
	description?: string;
	access: boolean;
}

const { Text } = Typography;

const DataAccess = ({
	onChange = () => {},
	unauthorized_dataset = [],
	unauthorized_schema = [],
}: DataAccessProps) => {
	const { metadatas, metadatasLoading } = useMainContext();
	const [unauthorizedDatasets, setUnauthorizedDatasets] =
		useState<Array<string>>(unauthorized_dataset);
	const [unauthorizedSchemas, setUnauthorizedSchemas] =
		useState<Array<string>>(unauthorized_schema);
	const [expandedRowKeys, setExpandedRowKeys] = useState<Array<string>>([]);

	// Handles dataset
	const expandedRowRender = (
		record: any,
		index: any,
		indent: any,
		expanded: any
	) => {
		// console.log({ record, index, indent, expanded });

		const columns: TableColumnsType<ExpandedDataType> = [
			{
				title: "Dataset",
				dataIndex: "name",
				key: "name",
				width: "300px",
			},
			{
				title: "Description",
				dataIndex: "description",
				key: "description",
			},
			{
				title: "Access",
				key: "access",
				width: "80px",
				render: (_value, _record) => {
					return (
						<Switch
							defaultChecked
							onChange={(checked) => {
								handleDatasetAccess(checked, _value.name, _record);
							}}
							checked={!unauthorized_dataset?.includes(_value.name)}
							disabled={unauthorizedSchemas?.includes(record?.schema)}
						/>
					);
				},
			},
		];

		let data: Array<any> = [];
		data = metadatas
			?.filter((m) => m?.schema === record?.schema)
			?.map((m) => ({
				key: m?.id as string,
				name: m?.name as string,
				schema: m?.schema as string,
				description: m?.metadata?.description as string,
				access: true,
			}));

		return <Table columns={columns} dataSource={data} pagination={false} />;
	};

	const handleSchemaAccess = useCallback(
		(checked: boolean, schema: string) => {
			if (checked) {
				const authorizedDatasets = metadatas
					?.filter((m) => m.schema === schema)
					?.map((m) => m.name);
				setUnauthorizedDatasets((prev) =>
					prev.filter((d) => !authorizedDatasets?.includes(d))
				);
				setUnauthorizedSchemas((prev) => prev?.filter((d) => d !== schema));

				onChange(
					unauthorizedDatasets.filter((d) => !authorizedDatasets?.includes(d)),
					unauthorizedSchemas.filter((d) => d !== schema)
				);
			} else {
				const _unauthorizedDatasets = metadatas
					?.filter((m) => m.schema === schema)
					?.map((m) => m.name);
				setUnauthorizedDatasets((prev) => [...prev, ..._unauthorizedDatasets]);
				setUnauthorizedSchemas((prev) => [...prev, schema]);

				onChange(
					[...unauthorizedDatasets, ..._unauthorizedDatasets],
					[...unauthorizedSchemas, schema]
				);
			}
		},

		[metadatas, unauthorizedDatasets, unauthorizedSchemas]
	);

	const handleDatasetAccess = useCallback(
		(checked: boolean, dataset: string, record: any) => {
			const unautorized = checked
				? unauthorizedDatasets.filter((d) => d !== dataset)
				: [...unauthorizedDatasets, dataset];

			setUnauthorizedDatasets(unautorized);

			// available datasets of this schema
			const schemaDatasets = metadatas.filter(
				(m) => m?.schema === record?.schema && !unautorized.includes(m.name)
			);

			if (schemaDatasets.length === 0) {
				setUnauthorizedSchemas([...unauthorizedSchemas, record.schema]);
			}

			onChange(
				unautorized,
				schemaDatasets.length === 0
					? [...unauthorizedSchemas, record.schema]
					: unauthorizedSchemas
			);
		},
		[metadatas, unauthorizedDatasets, unauthorizedSchemas]
	);

	const getSchemaChecked = useCallback(
		(schema: string) => {
			// All datasets that belongs to this schema
			const datasets = metadatas
				?.filter((m) => m?.schema === schema)
				?.map((m) => m?.name);
			return datasets?.some((d) => !unauthorized_dataset?.includes(d));
		},
		[metadatas, unauthorized_dataset]
	);

	const handleExpandAll = useCallback(() => {
		const distinctSchemas = metadatas?.reduce(
			(acc: any, curr: any, index: any) => {
				if (!acc?.includes(curr?.schema)) {
					acc?.push(curr?.schema);
				}

				return acc;
			},
			[]
		);
		setExpandedRowKeys(distinctSchemas);
	}, [metadatas]);

	const handleCollapseAll = () => {
		setExpandedRowKeys([]);
	};

	const onExpand = (expanded: boolean, record: any) => {
		if (expanded) {
			setExpandedRowKeys((prev) => [...prev, record.key]);
		} else {
			setExpandedRowKeys((prev) => prev.filter((key) => key !== record.key));
		}
	};

	// Handles Schema
	const columns: TableColumnsType<DataType> = [
		{
			title: "Schema",
			dataIndex: "schema",
			key: "schema",
			width: "300px",
		},
		{
			title: "Description",
			dataIndex: "description",
			key: "description",
		},
		{
			title: "Access",
			key: "access",
			width: "80px",
			render: (value, record, index) => {
				// console.log({ value, record, index });

				return (
					<Switch
						defaultChecked
						onChange={(checked) => {
							handleSchemaAccess(checked, value.schema);
						}}
						checked={getSchemaChecked(value.schema)}
					/>
				);
			},
		},
	];
	let data: DataType[] = [];
	data = metadatas?.reduce((acc, curr, index) => {
		if (!acc?.find((m: any) => m.schema === curr.schema)) {
			acc.push({
				key: curr.schema,
				schema: curr.schema,
				description: curr?.metadata?.description || "",
				access: true,
			});
		}

		return acc;
	}, []);

	return (
		<>
			<Flex justify="space-between" style={{ padding: "16px 8px" }}>
				<Text style={{ fontSize: 16 }}>Data access</Text>
				<Space>
					<Button disabled={metadatasLoading} onClick={handleCollapseAll}>
						Collapse All
					</Button>
					<Button disabled={metadatasLoading} onClick={handleExpandAll}>
						Expand All
					</Button>
				</Space>
			</Flex>
			<Table
				loading={metadatasLoading}
				className="white-table"
				style={{ color: "rgba(0,0,0,0.88)", background: "white" }}
				columns={columns}
				dataSource={data}
				expandable={{ expandedRowRender, expandedRowKeys, onExpand }}
			/>
		</>
	);
};

export default DataAccess;
