import React, { useMemo } from "react";
import MaximoModal from "./MaximoModal";
import {
	Button,
	Divider,
	Drawer,
	Space,
	Spin,
	Tabs,
	Tooltip,
	Input,
	Table,
	Empty,
} from "antd";
import { GetAntIcon } from "../../utils/ant_icons";

const { Search } = Input;

const DigitalTwinRightDrawer = ({
	canvasRef,
	setSelectedKeys,
	setMaximoWORecord,
	setMaximoEFRRecord,
	setMaximoModificationsRecord,
	isMaximoView,
	rightDrawer,
	rightDrawerTabKey,
	rightDrawerKey,
	rightDrawerData,
	setRightDrawer,
	setRightDrawerData,
	setRightDrawerTabKey,
	setRightDrawerKey,
	setMaximoView,
	maximoAssetData,
	maximoWOData,
	maximoEFRData,
	maximoTotalIncidentDowntime,
	maximoWODataList,
	isMaximoWOLoading,
	maximoWODuckList,
	setFilteredMaximoWODataList,
	filteredMaximoWODataList,
	setMaximoWOLoading,
	getMaximoXMLData,
	currentMaximoKeyList,
	currentMaximoKey,
	rig_code,
	abortControllerRef,
	rightDrawerKeyRef,
	setMaximoWODataList,
	maximoEFRDataList,
	isMaximoEFRLoading,
	setFilteredMaximoEFRDataList,
	filteredMaximoEFRDataList,
	setMaximoEFRLoading,
	maximoModificationDataList,
	setMaximoModificationDataList,
	isMaximoModificationsLoading,
	setFilteredMaximoModificationDataList,
	filteredMaximoModificationDataList,
	setMaximoModificationsLoading,
	maximoWORecord,
	maximoEFRRecord,
	maximoModificationsRecord,
}: any) => {
	// Maximo asset Work order table renderer keys
	const MaximoWOListTableColumnKeys: any = [
		{
			title: "Work Order",
			dataIndex: "WONUM",
			key: "Work Order",
			render: (value: any, record: any) => {
				return (
					<a
						onClick={() => {
							setMaximoWORecord(record);
						}}
					>
						{value}
					</a>
				);
			},
		},
		{
			title: "Description",
			dataIndex: "DESCRIPTION",
			key: "Description",
		},
		{
			title: "Work Type",
			dataIndex: "WORKTYPE",
			key: "Type",
		},
		// {
		// 	title: "Job Plan",
		// 	dataIndex: "JPNUM",
		// 	key: "Job Plan",
		// },
		{
			title: "Criticality",
			dataIndex: "STN_MNT_CRITICALITY",
			key: "Criticality",
		},
		{
			title: "Target Start",
			dataIndex: "TARGSTARTDATE",
			key: "Target Start",
			width: "120px",
			render: (text: any) => {
				var date = new Date(text);
				return date.toLocaleDateString("en-GB", {
					year: "numeric",
					month: "short",
					day: "numeric",
				});
			},
		},
		{
			title: "Target Finish",
			dataIndex: "TARGCOMPDATE",
			key: "Target Finish",
			width: "100px",
			render: (text: any) => {
				var date = new Date(text);
				return date.toLocaleDateString("en-GB", {
					year: "numeric",
					month: "short",
					day: "numeric",
				});
			},
		},
		{
			title: "Status",
			dataIndex: "STATUS",
			key: "Status",
		},
	];

	// Maximo asset EFR table renderer keys
	const MaximoEFRColumnKeys: any = [
		{
			title: "Incident",
			dataIndex: "STN_CASENUM",
			key: "Incident",
			render: (value: any, record: any) => {
				return (
					<a
						onClick={() => {
							setMaximoEFRRecord(record);
						}}
					>
						{value}
					</a>
				);
			},
		},
		{
			title: "Description",
			dataIndex: "DESCRIPTION",
			key: "Description",
		},
		{
			title: "Reported Date",
			dataIndex: "ACTUALSTART",
			key: "Reported Date",
			render: (text: any) => {
				var date = new Date(text);
				return date.toLocaleDateString("en-GB", {
					year: "numeric",
					month: "short",
					day: "numeric",
				});
			},
		},
		{
			title: "Reported By",
			dataIndex: "REPORTEDBY",
			key: "Reported By",
		},
		{
			title: "Last Update",
			dataIndex: "STATUSDATE",
			key: "Last Update",
			width: "100px",
			render: (text: any) => {
				var date = new Date(text);
				return date.toLocaleDateString("en-GB", {
					year: "numeric",
					month: "short",
					day: "numeric",
				});
			},
		},
		{
			title: "Status",
			dataIndex: "STATUS",
			key: "Status",
		},
	];

	// Maximo asset modifications table renderer keys
	const MaximoModificationColumnKeys: any = [
		{
			title: "Modification",
			dataIndex: "WONUM",
			key: "Modification",
			render: (value: any, record: any) => {
				return (
					<a
						onClick={() => {
							setMaximoModificationsRecord(record);
						}}
					>
						{value}
					</a>
				);
			},
		},
		{
			title: "Description",
			dataIndex: "DESCRIPTION",
			key: "Description",
		},
		{
			title: "Level",
			dataIndex: "STN_MRLEVEL",
			key: "Level",
		},
		{
			title: "Last Update",
			dataIndex: "STATUSDATE",
			key: "Last Update",
			width: "100px",
			render: (text: any) => {
				var date = new Date(text);
				return date.toLocaleDateString("en-GB", {
					year: "numeric",
					month: "short",
					day: "numeric",
				});
			},
		},
		{
			title: "Reported By",
			dataIndex: "REPORTEDBY",
			key: "Reported By",
		},
		{
			title: "Status",
			dataIndex: "STATUS",
			key: "Status",
		},
	];

	const setRightDrawerContent = () => {
		if (isMaximoView) {
			return (
				<Spin
					className="maximo-antd-spin"
					wrapperClassName="maximo-loading-wrapper"
					spinning={false}
					tip={"Fetching Maximo data"}
				>
					{setMaximoContent(rightDrawerTabKey)}
				</Spin>
			);
		} else {
			switch (rightDrawerKey) {
				case "Crew":
					let data = rightDrawerData;
					return (
						<div
							className="crew-detail-generic-font"
							style={{ height: "100%", padding: "0px 16px" }}
						>
							<Space size={16} direction="vertical" style={{ width: "100%" }}>
								<Space
									direction="horizontal"
									style={{
										width: "100%",
										alignItems: "normal",
										justifyContent: "space-between",
									}}
								>
									<Space
										size={0}
										direction="vertical"
										style={{
											width: "100%",
										}}
									>
										<span className="crew-detail-generic-title">
											Occupation
										</span>
										{data.occupation}
										<span></span>
									</Space>
									{data.occupation?.includes("(Temp)") ? (
										<span className="crew-detail-generic-title">Temporary</span>
									) : null}
								</Space>
								<Space size={0} direction="vertical">
									<span className="crew-detail-generic-title">Area</span>
									<span>{data.area}</span>
								</Space>
							</Space>
							<Divider className="small-divider" />
							<div>
								<Space direction="vertical" size={0}>
									<span className="crew-detail-generic-title">Name</span>
									<span>{data.full_name}</span>
								</Space>
							</div>
							<Divider className="small-divider" />
							<Space direction="vertical" size={16} style={{ width: "100%" }}>
								<Space direction="vertical" size={0}>
									<span className="crew-detail-generic-title">
										Arrival Date
									</span>
									<span>{data.arrival_date}</span>
								</Space>
								<Space direction="vertical" size={0}>
									<span className="crew-detail-generic-title">
										Departure Date
									</span>
									<span>{data.departure_date}</span>
								</Space>
								<Space direction="vertical" size={0}>
									<span className="crew-detail-generic-title">
										Days onboard
									</span>
									<span>{Math.trunc(data.days_on_rig).toString()}</span>
								</Space>
							</Space>
							<Divider className="small-divider" />
							<Space
								direction="horizontal"
								style={{ width: "100%" }}
								styles={{ item: { flex: "1" } }}
							>
								<Space direction="vertical" size={0}>
									<span className="crew-detail-generic-title">
										Cabin / Bunk
									</span>
									<span>{data.cabin}</span>
								</Space>
								<Space direction="vertical" size={0}>
									<span className="crew-detail-generic-title">Life Boat</span>
									<span>{data.lifeboat_name}</span>
								</Space>
							</Space>
							<Divider className="small-divider" />
							<Space direction="vertical" style={{ width: "100%" }} size={16}>
								<Space direction="vertical" size={0}>
									<span className="crew-detail-generic-title">
										Company Contact
									</span>
									<span>{data.company_contact_no}</span>
								</Space>
								<Space direction="vertical" size={0}>
									<span className="crew-detail-generic-title">
										Emergency Team
									</span>
									<span>{data.emergency_team}</span>
								</Space>
								{data.email !== "-" ? (
									<Button
										icon={GetAntIcon("mail")}
										onClick={() => {
											window.open(`mailto:${data.email}`);
										}}
										title="Send Email"
									>
										Send Email
									</Button>
								) : (
									<></>
								)}
							</Space>
						</div>
					);
			}
		}
	};

	const setRightDrawerTitle = () => {
		if (isMaximoView) {
			return "Maximo Data";
		} else {
			switch (rightDrawerKey) {
				case "Asset":
					return "Maximo Data";
				case "Crew":
					return "Crew Details";
			}
		}
	};

	const setMaximoContent = (tabKey: any) => {
		const setDetail = (type: any, prop: any, typeKey: any = null) => {
			const renderValue = (record: any, type: any = null) => {
				if (record === "" || !record) {
					return "-";
				}
				switch (type) {
					case "date":
						var date = new Date(record);
						return date.toLocaleDateString("en-GB", {
							year: "numeric",
							month: "short",
							day: "numeric",
						});
					case "duration":
						var hours = Math.floor(record);

						// Get the decimal part (minutes)
						var decimalPart = record - hours;
						var minutes = Math.round(decimalPart * 60);

						// Format the string
						var result = hours + " h " + minutes + " min";
						return result;

					default:
						return record || "-";
				}
			};

			switch (type) {
				case "ASSET":
					return renderValue(maximoAssetData?.[0]?.[prop]?.[0], typeKey);
				case "WORKORDER":
					return renderValue(maximoWOData?.[0]?.[prop]?.[0], typeKey);
				case "EFR":
					return renderValue(maximoEFRData?.[0]?.[prop]?.[0], typeKey);
				default:
					return "-";
			}
		};

		switch (tabKey) {
			case "overview":
				return (
					<div
						style={{
							display: "flex",
							padding: "16px",
							alignItems: "flex-start",
							gap: "24px",
							flex: 1,
							alignSelf: "stretch",
						}}
					>
						<div
							style={{
								width: "60%",
								fontSize: "14px",
								fontWeight: "600",
								lineHeight: "22px",
								display: "flex",
								flexDirection: "column",
								alignItems: "flex-start",
								gap: "8px",
								alignSelf: "strech",
								color: "rgba(255,255,255,0.85)",
							}}
						>
							<Spin
								className="maximo-antd-spin"
								wrapperClassName="maximo-loading-wrapper"
								spinning={!maximoAssetData}
							>
								<div className="digital-twin-maximo-overview-content-container">
									<span style={{ fontSize: "16px" }}>Asset Details</span>

									<div className="digital-twin-maximo-overview-content-data">
										<Tooltip title="Describes the asset">
											<span>Description</span>{" "}
										</Tooltip>
										<span className="digital-twin-maximo-value">
											{setDetail("ASSET", "DESCRIPTION")}
										</span>
									</div>
									<div className="digital-twin-maximo-overview-content-data">
										<Tooltip title="The selected asset number">
											<span>Number</span>
										</Tooltip>
										<span className="digital-twin-maximo-value">
											{setDetail("ASSET", "ASSETNUM")}
										</span>
									</div>
									<div className="digital-twin-maximo-overview-content-data">
										<span>Parent</span>
										<span className="digital-twin-maximo-value">
											{setDetail("ASSET", "PARENT")}
										</span>
									</div>
									<div className="digital-twin-maximo-overview-content-data">
										<span>Children</span>
										<span className="digital-twin-maximo-value">
											{setDetail("ASSET", "CHILDREN")}
										</span>
									</div>
								</div>
							</Spin>
							<Divider style={{ margin: "4px 0" }}></Divider>
							<Spin
								className="maximo-antd-spin"
								wrapperClassName="maximo-loading-wrapper"
								spinning={!maximoAssetData}
							>
								<div className="digital-twin-maximo-overview-content-container">
									<span style={{ fontSize: "16px" }}>Criticality</span>
									<div className="digital-twin-maximo-overview-content-data">
										<span>Safety</span>
										<span className="digital-twin-maximo-value">
											{setDetail("ASSET", "STN_SFTY_CRITICALITY")}
										</span>
									</div>
									<div className="digital-twin-maximo-overview-content-data">
										<span>Environment</span>
										<span className="digital-twin-maximo-value">
											{setDetail("ASSET", "STN_ENV_CRITICALITY")}
										</span>
									</div>
									<div className="digital-twin-maximo-overview-content-data">
										<span>Production</span>
										<span className="digital-twin-maximo-value">
											{setDetail("ASSET", "STN_PROD_CRITICALITY")}
										</span>
									</div>
									<div className="digital-twin-maximo-overview-content-data">
										<span>Equipment</span>
										<span className="digital-twin-maximo-value">
											{setDetail("ASSET", "STN_EQ_CRITICALITY")}
										</span>
									</div>
								</div>
							</Spin>
							<Divider style={{ margin: "4px 0" }}></Divider>
							<Spin
								className="maximo-antd-spin"
								wrapperClassName="maximo-loading-wrapper"
								spinning={!maximoWOData}
							>
								<div className="digital-twin-maximo-overview-content-container">
									<span style={{ fontSize: "16px" }}>Maintenance</span>
									<div className="digital-twin-maximo-overview-content-data">
										<span>Next Due Date</span>
										<span className="digital-twin-maximo-value">
											{setDetail("WORKORDER", "TARGSTARTDATE", "date")}
										</span>
									</div>
									<div className="digital-twin-maximo-overview-content-data">
										<span>Criticality</span>
										<span className="digital-twin-maximo-value">
											{setDetail("WORKORDER", "STN_MNT_CRITICALITY")}
										</span>
									</div>
									<div className="digital-twin-maximo-overview-content-data">
										<span>Estimated Duration</span>
										<span className="digital-twin-maximo-value">
											{setDetail("WORKORDER", "ESTDUR", "duration")}
										</span>
									</div>
									<div className="digital-twin-maximo-overview-content-data">
										<span>Work Group</span>
										<span className="digital-twin-maximo-value">
											{setDetail("WORKORDER", "PERSONGROUP")}
										</span>
									</div>
									<div className="digital-twin-maximo-overview-content-data">
										<span>Days Remaining</span>
										<span className="digital-twin-maximo-value">-</span>
									</div>
									<div className="digital-twin-maximo-overview-content-data">
										<span>Last Associated Maintenance</span>
										<span className="digital-twin-maximo-value">
											{setDetail("WORKORDER", "ACTFINISH", "date")}
										</span>
									</div>
								</div>
							</Spin>
						</div>
						<Divider style={{ height: "100%" }} type={"vertical"}></Divider>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								gap: "8px",
								flex: 1,
								fontSize: "14px",
								fontWeight: "600",
								lineHeight: "22px",
							}}
						>
							<Spin
								className="maximo-antd-spin"
								wrapperClassName="maximo-loading-wrapper"
								spinning={!maximoEFRData}
							>
								<div className="digital-twin-maximo-overview-content-container">
									<span style={{ fontSize: "16px" }}>EFR</span>
									<div
										className="digital-twin-maximo-overview-content-data"
										style={{ flexDirection: "column" }}
									>
										<span>Last Incident</span>
										<span className="digital-twin-maximo-value text-start">
											{setDetail("EFR", "ACTUALSTART", "date")}
										</span>
									</div>
									<div
										className="digital-twin-maximo-overview-content-data"
										style={{ flexDirection: "column" }}
									>
										<span>Total Incidents</span>
										<span className="digital-twin-maximo-value text-start">
											{maximoEFRData?.length || "-"}
										</span>
									</div>
								</div>
							</Spin>
							<Divider style={{ margin: "4px 0" }} />
							<Spin
								className="maximo-antd-spin"
								wrapperClassName="maximo-loading-wrapper"
								spinning={!maximoEFRData}
							>
								<div className="digital-twin-maximo-overview-content-container">
									<div
										className="digital-twin-maximo-overview-content-data"
										style={{ flexDirection: "column" }}
									>
										<span>Total Downtime</span>
										<span className="digital-twin-maximo-value text-start">
											{maximoTotalIncidentDowntime || "-"}
										</span>
									</div>
								</div>
							</Spin>
						</div>
					</div>
				);
			case "maintenance":
				return (
					<Spin
						className="maximo-antd-spin"
						wrapperClassName="maximo-loading-wrapper"
						spinning={!maximoWODataList || isMaximoWOLoading}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								width: "100%",
							}}
						>
							<Search
								className="grey-search-bar"
								key="woSearch"
								placeholder="Search for work order..."
								allowClear
								onSearch={(value: any) => {
									if (value === "") {
										setFilteredMaximoWODataList(null);
									} else {
										const filtered = maximoWODataList.filter((wo: any) =>
											wo.WONUM[0].includes(value)
										);
										setFilteredMaximoWODataList(filtered);
									}
								}}
							/>
							<div className="flex-column-table" style={{ width: "100%" }}>
								<Table
									locale={{
										emptyText: (
											<div style={{ padding: "20px" }}>
												<Empty
													description={
														isMaximoWOLoading || !maximoWODataList
															? "Downloading Data"
															: "No Data"
													}
												/>
											</div>
										),
									}}
									rowClassName={(record: any, index: any) => {
										return maximoWODuckList.includes(record.WONUM[0])
											? "digital-twin-maximo-wo-red-row"
											: "digital-twin-maximo-wo-grey-row";
									}}
									rootClassName="digitaltwin-table"
									bordered
									style={{ display: "flex", flexDirection: "column" }}
									dataSource={
										filteredMaximoWODataList || maximoWODataList || []
									}
									columns={MaximoWOListTableColumnKeys}
									pagination={false}
								/>
							</div>
							<div style={{ padding: "16px", display: "flex" }}>
								<Button
									style={{ width: "fit-content", marginLeft: "auto" }}
									onClick={() => {
										setMaximoWOLoading(true);
										const maxItems: any = maximoWODataList.length + 25;
										let modifiedList = currentMaximoKeyList.map(
											(s: any) => `'${s}'`
										);
										let assetNumArrayString = modifiedList.join(", ");
										getMaximoXMLData(
											null,
											currentMaximoKey,
											assetNumArrayString,
											rig_code,
											"STN_PORTAL_WO",
											" and status !='CAN' and ISTASK='0' and TARGCOMPDATE !='' order by ACTFINISH asc, TARGCOMPDATE ASC",
											maxItems.toString(),
											abortControllerRef?.current?.signal
										)
											.then((element: any) => {
												setMaximoWODataList(element?.WORKORDER || []);
											})
											.catch((error: any) => {
												console.log(error);
											})
											.finally(() => {
												setMaximoWOLoading(false);
											});
									}}
								>
									Load More
								</Button>
							</div>
						</div>
					</Spin>
				);
			case "equipment-failures":
				return (
					<Spin
						className="maximo-antd-spin"
						wrapperClassName="maximo-loading-wrapper"
						spinning={!maximoEFRDataList || isMaximoEFRLoading}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								width: "100%",
							}}
						>
							<Search
								className="grey-search-bar"
								key="inSearch"
								placeholder="Search for incident id..."
								allowClear
								onSearch={(value: any) => {
									if (value === "") {
										setFilteredMaximoEFRDataList(null);
									} else {
										const filtered = maximoEFRDataList.filter((wo: any) =>
											wo.STN_CASENUM[0].includes(value)
										);
										setFilteredMaximoEFRDataList(filtered);
									}
								}}
							/>
							<div className="flex-column-table" style={{ width: "100%" }}>
								<Table
									locale={{
										emptyText: (
											<div style={{ padding: "20px" }}>
												<Empty
													description={
														isMaximoEFRLoading || !maximoEFRDataList
															? "Downloading Data"
															: "No Data"
													}
												/>
											</div>
										),
									}}
									bordered
									style={{ display: "flex", flexDirection: "column" }}
									dataSource={
										filteredMaximoEFRDataList || maximoEFRDataList || []
									}
									columns={MaximoEFRColumnKeys}
									rootClassName="digitaltwin-table"
									pagination={false}
								/>
							</div>
							<div style={{ padding: "16px", display: "flex" }}>
								<Button
									style={{ width: "fit-content", marginLeft: "auto" }}
									onClick={() => {
										setMaximoEFRLoading(true);
										const maxItems: any =
											maximoModificationDataList.length + 25;
										let modifiedList = currentMaximoKeyList.map(
											(s: any) => `'${s}'`
										);
										let assetNumArrayString = modifiedList.join(", ");
										getMaximoXMLData(
											null,
											currentMaximoKey,
											assetNumArrayString,
											rig_code,
											"STN_PORTAL_EFR",
											null,
											maxItems.toString(),
											abortControllerRef?.current?.signal
										)
											.then((element: any) => {
												// console.log(element);
												setMaximoModificationDataList(element?.INCIDENT || []);
											})
											.catch((error: any) => {
												console.log(error);
											})
											.finally(() => {
												setMaximoEFRLoading(false);
											});
									}}
								>
									Load More
								</Button>
							</div>
						</div>
					</Spin>
				);
			case "modifications":
				return (
					<Spin
						className="maximo-antd-spin"
						wrapperClassName="maximo-loading-wrapper"
						spinning={
							!maximoModificationDataList || isMaximoModificationsLoading
						}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								width: "100%",
							}}
						>
							<Search
								className="grey-search-bar"
								key="modSearch"
								placeholder="Search for modification id..."
								allowClear
								onSearch={(value: any) => {
									if (value === "") {
										setFilteredMaximoModificationDataList(null);
									} else {
										const filtered = maximoModificationDataList.filter(
											(wo: any) => wo.WONUM[0].includes(value)
										);
										setFilteredMaximoModificationDataList(filtered);
									}
								}}
							/>
							<div className="flex-column-table" style={{ width: "100%" }}>
								<Table
									locale={{
										emptyText: (
											<div style={{ padding: "20px" }}>
												<Empty
													description={
														isMaximoModificationsLoading ||
														!maximoModificationDataList
															? "Downloading Data"
															: "No Data"
													}
												/>
											</div>
										),
									}}
									bordered
									style={{ display: "flex", flexDirection: "column" }}
									dataSource={
										filteredMaximoModificationDataList ||
										maximoModificationDataList ||
										[]
									}
									columns={MaximoModificationColumnKeys}
									rootClassName="digitaltwin-table"
									pagination={false}
								/>
							</div>
							<div style={{ padding: "16px", display: "flex" }}>
								<Button
									style={{ width: "fit-content", marginLeft: "auto" }}
									onClick={() => {
										setMaximoModificationsLoading(true);
										const maxItems: any =
											maximoModificationDataList.length + 25;
										let modifiedList = currentMaximoKeyList.map(
											(s: any) => `'${s}'`
										);
										let assetNumArrayString = modifiedList.join(", ");
										getMaximoXMLData(
											null,
											currentMaximoKey,
											assetNumArrayString,
											rig_code,
											"STN_PORTAL_MR",
											null,
											maxItems.toString(),
											abortControllerRef?.current?.signal
										)
											.then((element: any) => {
												// console.log(element);
												setMaximoModificationDataList(element?.PLUSGMOC || []);
											})
											.catch((error: any) => {
												console.log(error);
											})
											.finally(() => {
												setMaximoModificationsLoading(false);
											});
									}}
								>
									Load More
								</Button>
							</div>
						</div>
					</Spin>
				);
		}
	};

	const MaximoMemo: any = useMemo(() => {
		return (
			<>
				{" "}
				<MaximoModal
					title={`Work order - ${maximoWORecord?.["WONUM"]?.[0]}`}
					type={"workOrder"}
					dataKey={"WORKORDER"}
					maximoRecord={maximoWORecord}
					handleMaximoModalClose={() => {
						setMaximoWORecord(null);
					}}
				/>
				<MaximoModal
					title={`Equipment failures - ${maximoEFRRecord?.["STN_CASENUM"]?.[0]}`}
					type={"incident"}
					dataKey={"INCIDENT"}
					maximoRecord={maximoEFRRecord}
					handleMaximoModalClose={() => {
						setMaximoEFRRecord(null);
					}}
				/>
				<MaximoModal
					title={`Modification - ${maximoModificationsRecord?.["WONUM"]?.[0]}`}
					type={"modifications"}
					dataKey={"PLUSGMOC"}
					maximoRecord={maximoModificationsRecord}
					handleMaximoModalClose={() => {
						setMaximoModificationsRecord(null);
					}}
				/>
			</>
		);
	}, [maximoWORecord, maximoEFRRecord, maximoModificationsRecord]);

	return (
		<>
			<Drawer
				open={rightDrawer}
				width={rightDrawerKey != "Asset" ? 280 : 640}
				placement="right"
				className={`digitaltwin-drawer-wrapper`}
				getContainer={false}
				mask={false}
				maskClosable={false}
				destroyOnClose={false}
				closable={false}
				title={
					isMaximoView ? (
						<div style={{ width: "100%" }}>
							<Tabs
								rootClassName="digitaltwin-right-drawer-tabs"
								className="digitaltwin-right-drawer-tabs"
								style={{ padding: "0 16px" }}
								activeKey={rightDrawerTabKey}
								onChange={(key: any) => {
									setRightDrawerTabKey(key);
									setFilteredMaximoEFRDataList(null);
									setFilteredMaximoModificationDataList(null);
									setFilteredMaximoWODataList(null);
								}}
								items={[
									{
										label: "Overview",
										key: "overview",
									},
									{
										label: "Maintenance",
										key: "maintenance",
									},
									{
										label: "Equipment failures",
										key: "equipment-failures",
									},
									{
										label: "Modifications",
										key: "modifications",
									},
								]}
							></Tabs>
						</div>
					) : (
						<div
							style={{
								padding: "8px 16px",
								width: "100%",
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
							}}
						>
							<span style={{ fontSize: "14px" }}>{setRightDrawerTitle()}</span>
							<Button
								// style={{ color: "#FFFFFFD9" }}
								type="text"
								ghost
								onClick={() => {
									setRightDrawer(false);
									setRightDrawerData(null);
									setRightDrawerKey("");
									setMaximoView(false);
									rightDrawerKeyRef.current = "";
									setSelectedKeys([]);
								}}
							>
								{GetAntIcon("close")}
							</Button>
						</div>
					)
				}
				style={{ position: "absolute" }}
				styles={{
					body: { padding: "0px", width: "100%" },
					header: {
						background: "#141414",
						padding: "0px",
						fontSize: "14px",
						borderBottom: "0px",
					},
				}}
				onClose={() => {
					setRightDrawerTabKey("overview");
				}}
				afterOpenChange={() => canvasRef.current.focus()}
			>
				{
					<div style={{ color: "white", height: "100%" }}>
						{setRightDrawerContent()}
					</div>
				}
			</Drawer>
			;{MaximoMemo}
		</>
	);
};

export default DigitalTwinRightDrawer;
