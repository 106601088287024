import { Form, Input, Modal, ModalProps, Typography } from "antd";
import React, { useCallback, useContext, useEffect } from "react";
import { ACTIONTYPES } from "../../reducers/measureReducer";
import { addMeasure, updateMeasure } from "../../services/api-server/measures";
import Emitter from "../../services/EventEmitter";
import { CustomDashboardContext, MeasureContext } from "../../contexts/context";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

interface NameDescriptionModalProps extends ModalProps {
	onChange?: Function;
	onSave?: any;
	closeModal?: any;
}

const { Text } = Typography;

const NameDescriptionModal = ({
	onChange = () => {},
	onSave,
	closeModal,
	...restProps
}: NameDescriptionModalProps) => {

	const { measures, state: customDashboardState } = useContext(CustomDashboardContext);
	const { state: measureState, dispatch: measureDispatch } =
		useContext(MeasureContext);

	const handleMeasureChange = (key: string, value: any) => {
		measureDispatch({
			type: ACTIONTYPES.MEASURE,
			payload: { ...measureState.measure, [key]: value },
		});
	}
	const user = useSelector((state: any) => state.user)


	const checkSavable = useCallback(
		(measure: any) => {
			const { id: measureId } = measureState.measure;
			const cleanedName = measure.name?.trim().toLowerCase();
			const found = measures?.find((_measure: any) => {
				const cleanedMeasureName = _measure?.name?.trim().toLowerCase();

				if (measureId) {
					return (
						(cleanedMeasureName === cleanedName || cleanedName === "") &&
						_measure?.id !== measureId
					);
				} else {
					return cleanedMeasureName === cleanedName || cleanedName === "";
				}
			});

			if (!found && cleanedName && cleanedName !== "") {
				measureDispatch({
					type: ACTIONTYPES.SAVABLE,
					payload: true,
				});
			} else if (found) {
				measureDispatch({
					type: ACTIONTYPES.NAME_ERR,
					payload: "Please provide a unique measure name",
				});
			} else if (cleanedName !== null && cleanedName === "") {
				measureDispatch({
					type: ACTIONTYPES.NAME_ERR,
					payload: "Please provide a measure name",
				});
			} else {
				measureDispatch({
					type: ACTIONTYPES.SAVABLE,
					payload: false,
				});
			}
		},
		[measures, measureState.measure]
	);

	const handleSaveMeasure = () => {
		const timeCreated = dayjs().toString();
		const updatedMeasure: any = { ...measureState.measure };

		measureDispatch({ type: ACTIONTYPES.SAVE_MEASURE_START });

		updatedMeasure.name = updatedMeasure?.name?.trim()?.replace(/\s+/g, " ")

		updatedMeasure.lastUpdatedAt = timeCreated
		updatedMeasure.lastUpdatedBy = user?.name || ''

		if (updatedMeasure?.description) {
			updatedMeasure.description = updatedMeasure?.description?.trim()?.replace(/\s+/g, " ")
		}

		// Check if measure was verified before
		const wasVerified = measures?.find(
			(measure: any) =>
				measure.id === measureState.measure.id && measure?.verified
		);

		if (!wasVerified) {
			updatedMeasure.verifiedAt = timeCreated
			updatedMeasure.verifiedBy = user?.name || ''
		}

		if (!updatedMeasure?.verified) {
			updatedMeasure.verifiedAt = ""
			updatedMeasure.verifiedBy = ""
		}

		// Determine either new measure or existing measure
		if (measureState.editMode) {
			// Existing measure
			updateMeasure(updatedMeasure, customDashboardState.menuKey).then((response: any) => {
				onSave(response.data);
				Emitter.emit("alert", {
					type: "success",
					message: "Your measure had been updated",
					description: "You have successfully updated a measure",
					timeout: 5000,
				});
			}).catch((error) => {
				Emitter.emit("alert", {
					type: "error",
					message: "Unsuccessful saving measure",
					description: "There was an error while saving measure",
					timeout: 5000,
				});
				console.error(error);
			})
				.finally(() => {
					measureDispatch({ type: ACTIONTYPES.RESET });
					closeModal();
				});
		} else {
			// New measure
			updatedMeasure.createdAt = timeCreated
			updatedMeasure.createdBy = user?.name || ''

			addMeasure(updatedMeasure, customDashboardState.menuKey).then((response: any) => {
				onSave(response.data);
				Emitter.emit("alert", {
					type: "success",
					message: measureState.editMode
						? "Your measure had been updated"
						: "Measure created successfully",
					description: measureState.editMode
						? "You have successfully updated a measure"
						: "You have successfully created a measure",
					timeout: 5000,
				});
			})
				.catch((error) => {
					Emitter.emit("alert", {
						type: "error",
						message: "Unsuccessful saving measure",
						description: "There was an error while saving measure",
						timeout: 5000,
					});
					console.error(error);
				})
				.finally(() => {
					measureDispatch({ type: ACTIONTYPES.RESET });
					closeModal();
				});
		}
	}

	useEffect(() => {
		if (measureState.dirty) checkSavable(measureState.measure);
	}, [measureState.dirty, measureState.measure]);

	return (
		<Modal
			{...restProps}
			title={`${measureState.editMode ? "Edit" : "New"} Measure`}
			className="name-description-modal"
			okText={"Save"}
			onOk={handleSaveMeasure}
			okButtonProps={{ disabled: !measureState.savable }}
			style={{ zIndex: 30005 }}
		>
			<Form layout="vertical">
				<Form.Item label={<Text>Name</Text>}>
					<Input
						style={measureState.errorMessage !== "" ? { marginBottom: 8 } : {}}
						placeholder="Measure 1"
						value={measureState.measure.name || ""}
						onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
							handleMeasureChange("name", ev.target.value);
						}}
						status={
							measureState.dirty
								? measureState.errorMessage === ""
									? ""
									: "error"
								: ""
						}
					/>
					{measureState.errorMessage !== "" && (
						<Text>{measureState.errorMessage}</Text>
					)}
				</Form.Item>
				<Form.Item
					label={
						<Text>
							Description{" "}
							<Text className="custom-optional-text">(optional)</Text>
						</Text>
					}
				>
					<Input
						placeholder="This is a description"
						value={measureState.measure.description || ""}
						onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
							handleMeasureChange("description", ev.target.value)
						}
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default NameDescriptionModal;
