import { v4 as uuid } from "uuid";
import { TargetProps } from "../components/TargetsConfig";

export type VisualState = {
	openModal: boolean;
	loading: boolean;
	data: Array<any>;
	fields: Array<any>;
	package: Object;
	visual: {
		data?: any;
		measure?: any;
		title?: string;
		subtitle?: string;
		id: string;
		indicator?: string;
		targets?: TargetProps;
		target_multiplier?: string;
		target_based_on?: string;
		drilldown?: string;
	};
	new: boolean;
};

export type VisualAction =
	| {
			type: "CREATE_PACKAGE";
	  }
	| {
			type: "MODAL";
			payload: boolean;
	  }
	| {
			type: "SELECT_MEASURE";
			payload: any;
	  }
	| {
			type: "DATA";
			payload: Array<any>;
	  }
	| {
			type: "LOADING";
			payload: boolean;
	  }
	| {
			type: "PACKAGE";
			payload: { name: string; value: string };
	  }
	| {
			type: "UPDATE_VISUAL";
			payload: { name: string; value: any };
	  }
	| {
			type: "UPDATE_DATA";
			payload: { name: string; value: any };
	  }
	| {
			type: "NEW_VISUAL";
	  }
	| {
			type: "EDIT_VISUAL";
			payload: any;
	  }
	| {
			type: "LOAD_VISUAL_START";
	  }
	| {
			type: "RESET_VISUAL_MODAL";
	  };

const INIITAL_STATE: VisualState = {
	openModal: false,
	loading: false,
	data: [],
	fields: [],
	package: {},
	visual: { id: "" },
	new: true,
};

const reducer = (state: VisualState, action: VisualAction) => {
	switch (action.type) {
		case "LOAD_VISUAL_START":
			return { ...state, loading: true };
		case "CREATE_PACKAGE":
			return { ...state, openModal: true };
		case "MODAL":
			if (action.payload === true) {
				return { ...state, openModal: action.payload };
			} else {
				// closing modal
				return {
					...state,
					openModal: action.payload,
					package: INIITAL_STATE.package,
					data: INIITAL_STATE.data,
					fields: INIITAL_STATE.fields,
					visual: INIITAL_STATE.visual,
				};
			}
		case "SELECT_MEASURE":
			return {
				...state,
				loading: true,
				visual: { ...state.visual, measure: action.payload },
				// package: INIITAL_STATE.package,
			};
		case "DATA":
			return {
				...state,
				data: action.payload,
				fields: Object.keys(action.payload[0]),
			};
		case "LOADING":
			return { ...state, loading: action.payload };
		case "PACKAGE":
			if (action.payload.name === "type") {
				return {
					...state,
					package: {
						...INIITAL_STATE.package,
						[action.payload.name]: action.payload.value,
					},
				};
			}
			return {
				...state,
				package: {
					...state.package,
					[action.payload.name]: action.payload.value,
				},
			};
		case "UPDATE_VISUAL":
			return {
				...state,
				visual: {
					...state.visual,
					[action.payload.name]: action.payload.value,
				},
			};

		case "EDIT_VISUAL":
			return {
				...state,
				openModal: true,
				visual: action.payload,
				new: false,
			};

		case "UPDATE_DATA":
			return {
				...state,
				visual: {
					...state.visual,
					data: {
						...state.visual.data,
						[action.payload.name]: action.payload.value,
					},
				},
			};

		case "NEW_VISUAL":
			const newVisual = createVisual();
			return { ...state, visual: newVisual, openModal: true, new: true };

		case "RESET_VISUAL_MODAL":
			return {
				...state,
				visual: INIITAL_STATE.visual,
				openModal: INIITAL_STATE.openModal,
				data: INIITAL_STATE.data,
			};

		default:
			return state;
	}
};

const createVisual = () => {
	return {
		id: uuid(),
		indicator: "above",
		target_multiplier: "none",
		targets: generateModesIntialData([
			"year",
			"quarter",
			"month",
			"week",
			"day",
		]),
	};
};

const generateModesIntialData = (modes: Array<string>) => {
	let data: TargetProps = {};
	modes.forEach((mode) => {
		data[mode] = { target: 0, tolerance: 0, tolerance_percent: 0 };
	});
	return data;
};

export { INIITAL_STATE, reducer };
