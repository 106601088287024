import { api } from "../../contexts/AuthContext";

export const getShares = () => {
	return new Promise((resolve, reject) => {
		api.get(`/deltashare/shares/`, {})
			.then((response) => {
				resolve(response.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const getSchemas = (share: string) => {
	return new Promise((resolve, reject) => {
		api.get(`/deltashare/schemas`, {
			params: {
				share,
			},
		})
			.then((response) => {
				resolve(response.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const getTables = (data: any) => {
	return new Promise((resolve, reject) => {
		api.get(`/deltashare/tables`, {
			params: {
				...data,
			},
		})
			.then((response) => {
				resolve(response.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const getAllTables = (share: string) => {
	return new Promise((resolve, reject) => {
		api.get(`/deltashare/alltables`, {
			params: {
				share,
			},
		})
			.then((response) => {
				resolve(response.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const getMetaData = (share: string, schema: string, table: string) => {
	return new Promise((resolve, reject) => {
		api.get(`/deltashare/metadata/`, {
			params: {
				share,
				schema,
				table,
			},
		})
			.then((response) => {
				resolve(response.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const getData = (table: string) => {
	return new Promise((resolve, reject) => {
		api.get(`/${table}/`, {})
			.then((response) => {
				resolve(response.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const getData2 = (
	share: string,
	schema: string,
	table: string,
	options: any
) => {
	return new Promise((resolve, reject) => {
		api.post(
			`/deltashare/query2?share=${share}&schema=${schema}&table=${table}`,
			options
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const duckQuery = (
	queryString?: string,
	restricted?: Array<any> // restricted datasets
) => {
	return new Promise((resolve, reject) => {
		api.post(`/deltashare/duckq`, {
			queryString,
			restricted,
		})
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(
					error?.response?.data ||
						"There is an error running your query"
				);
				console.log(
					error?.response?.data ||
						"There is an error running your query"
				);
			});
	});
};
