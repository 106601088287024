import { Button, Flex, Layout, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import RequestAccessScreen from "../components/RequestAccessScreen";
import usePermissions from "../hooks/usePermissions";
import ComponentManager from "../components/modal/ComponentManager";
import { AccessRequestProvider } from "../contexts/AccessRequestContext";
import RequestsBadge from "../components/RequestsBadge";
import { FullscreenExitOutlined, FullscreenOutlined } from "@ant-design/icons";
import { useMainContext } from "../contexts/MainContext";
import useUrlQuery from "../hooks/useUrlQuery";

const { Text } = Typography;
const { Header } = Layout;

const withComponentManager = (Component: React.FC) => {
	return (props: any) => {
		const mitem = props.params.mitem;

		// Query Params for handling open configuration manager depending on query url
		const query = useUrlQuery()
		const {
			isAdmin,
			isOwner,
			isViewer,
			loading: permissionLoading,
		} = usePermissions(mitem);
		const { component_access } = mitem;
		const managedAccessMode =
			!component_access || component_access !== "public";
		const hasAccess = isOwner || isViewer;
		const [open, setOpen] = useState(false);
		const updatedProps = { ...props, isOwner, isViewer, isAdmin };

		const { fullScreen, toggleFullScreen } = useMainContext();

		useEffect(() => {
			if (permissionLoading) {
				setOpen(false);
			} else {
				setOpen(isOwner ? query.get('am') === 'true' ? true : false : false);
			}
		}, [permissionLoading, isOwner, query]);

		if (permissionLoading)
			return (
				<Flex
					justify="center"
					align="center"
					style={{ height: "100%", width: "100%" }}
				>
					<Spin />
				</Flex>
			);

		// if (!managedAccessMode) {
		// 	return <Component {...updatedProps} />;
		// }

		return (
			<AccessRequestProvider menuKey={mitem?.key}>
				<Header style={{ padding: 16, border: "1px solid #112A45" }}>
					<Flex justify="space-between" align="center">
						<Text style={{ fontSize: 20 }}>{mitem.title}</Text>
						<Flex gap={8} align="center">
							{hasAccess && managedAccessMode && (
								<RequestsBadge show={isOwner}>
									<Button
										// icon={<SettingOutlined />}
										onClick={() => setOpen(true)}
									>
										Component manager
									</Button>
								</RequestsBadge>
							)}

							{hasAccess || !managedAccessMode ? (
								<Button
									onClick={toggleFullScreen}
									icon={
										fullScreen ? (
											<FullscreenExitOutlined />
										) : (
											<FullscreenOutlined />
										)
									}
								/>
							) : null}
						</Flex>
					</Flex>
				</Header>
				{!permissionLoading && (
					<>
						{hasAccess ? (
							<Component {...updatedProps} />
						) : (
							<RequestAccessScreen menuItem={mitem} />
						)}
					</>
				)}

				<ComponentManager
					menuItem={mitem}
					readonly={!isOwner}
					open={open}
					onCancel={() => setOpen(false)}
					onSave={() => setOpen(false)}
				/>
			</AccessRequestProvider>
		);
	};
};

export default withComponentManager;
