import { Popconfirm, Typography } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import useDashboards from "../hooks/useDashboards";
import { deleteVisual } from "../services/api-server/visuals";
import { deleteMeasure } from "../services/api-server/measures";
import { CustomDashboardContext } from "../contexts/context";
import Emitter from "../services/EventEmitter";

const { Link, Paragraph } = Typography;

interface MeasureDeleteHandlerProps {
	targetId: string;
}

const MeasureDeleteHandler = ({ targetId }: MeasureDeleteHandlerProps) => {
	const [occuranceInVisuals, setOccuranceInVisuals] = useState<Array<any>>([]);
	const [occuranceInDashboards, setOccuranceInDashboards] = useState<any[]>([]);
	const {
		dispatch: customDashboardDispatch,
		state: customDashboardState,
		visuals,
	} = useContext(CustomDashboardContext);
	const actionable = !customDashboardState.editMode;
	const { dashboards, saveDashboards } = useDashboards();

	const getDescription = () => {
		return (
			<>
				{occuranceInVisuals?.length !== 0 && (
					<Paragraph>
						There {occuranceInVisuals?.length > 1 ? "are" : "is"}{" "}
						{occuranceInVisuals?.length} visual
						{occuranceInVisuals?.length > 1 ? "s" : ""} using this, deleting
						this measure will delete visuals using this measure
					</Paragraph>
				)}
				{occuranceInDashboards?.length !== 0 && (
					<Paragraph>
						There {occuranceInDashboards?.length > 1 ? "are" : "is"}{" "}
						{occuranceInDashboards?.length} dashboard
						{occuranceInDashboards?.length > 1 ? "s" : ""} using this, deleting
						this measure will delete relevant visuals on the dashboard
						{occuranceInDashboards?.length > 1 ? "s" : ""}
					</Paragraph>
				)}
			</>
		);
	};

	const getOccuranceInVisuals = useCallback(() => {
		const occurred: Array<any> = [];
		visuals?.forEach((v: any) => {
			if (v?.measure === targetId) occurred.push(v);
		});
		setOccuranceInVisuals(occurred);
	}, [visuals, targetId]);

	const getOccuranceInDashboards = useCallback(() => {
		dashboards?.forEach((dashboard: any) => {
			const { dashboard_layout = [], key = "" } = dashboard;

			if (dashboard_layout !== null && dashboard_layout?.length !== 0) {
				dashboard_layout?.forEach((el: any) => {
					const { visual_id = "" } = el;

					if (occuranceInVisuals?.find((v: any) => v?.id === visual_id)) {
						setOccuranceInDashboards((prev) => {
							if (prev?.find((_d: any) => _d.key === key)) return prev;
							return [...prev, dashboard];
						});
					}
				});
			}
		});
	}, [dashboards, occuranceInVisuals]);

	const handleDelete = async () => {
		//  Handling dashboards
		const updatedDashboards = dashboards?.map((dashboard: any) => {
			const { dashboard_layout = [] } = dashboard;

			const updatedLayout = dashboard_layout?.filter((el: any) => {
				const { visual_id = "" } = el;
				const found = occuranceInVisuals?.find((v: any) => v.id === visual_id);
				if (found) {
					customDashboardDispatch({
						type: "REMOVE_FROM_DASHBOARD",
						payload: el?.i,
					});
				}
				return !found;
			});

			return { ...dashboard, dashboard_layout: updatedLayout };
		});

		// Handling visuals
		const deleteVisualResponse: Array<any> = [];
		for (const visual of occuranceInVisuals) {
			try {
				const response: any = await deleteVisual(visual.id, customDashboardState.menuKey);
				deleteVisualResponse.push(response);
				// console.log("Visual deleted:" + response);
			} catch (e) {
				// console.log(e);
			}
		}

		// Handling measures first
		try {
			const response = await deleteMeasure(targetId, customDashboardState.menuKey);
			await saveDashboards(updatedDashboards, customDashboardState.menuKey);

			Emitter.emit("alert", {
				type: "success",
				message: "Measure deleted successfully",
				description: "You have successfully deleted a measure",
				timeout: 5000,
			});

			// console.log("Measure deleted:" + response);
		} catch (e) {

			Emitter.emit("alert", {
				type: "error",
				message: "Unsuccessful measure deletion",
				description: "There was an error while deleting measure",
				timeout: 5000,
			});
			// console.log(e);
		}

	}

	useEffect(() => {
		getOccuranceInVisuals();
		getOccuranceInDashboards();
	}, [visuals, dashboards]);

	return (
		<Popconfirm
			overlayStyle={{ zIndex: 30003 }}
			title="Are you sure you want to delete this measure?"
			description={getDescription}
			onConfirm={handleDelete}
		>
			<Link disabled={!actionable}>Delete</Link>
		</Popconfirm>
	);
};

export default MeasureDeleteHandler;
