import { Space, Typography } from "antd";
import { ParagraphProps } from "antd/es/typography/Paragraph";
import { useEffect, useState } from "react";

interface CustomParagraphProps {
	maxRows?: number;
	children?: React.ReactNode;
}

const { Paragraph, Text, Link } = Typography;

const CustomParagraph = ({ children, maxRows = 3 }: CustomParagraphProps) => {
	const [expand, setExpand] = useState(false);

	useEffect(() => {
		setExpand(false);
	}, [children]);

	if (!children) return <Text style={{ color: "slategray" }}>null</Text>;

	if (!expand) {
		return (
			<Paragraph
				style={{ marginBottom: 0 }}
				ellipsis={{
					expandable: true,
					rows: maxRows,
					onExpand: () => setExpand(true),
					symbol: "More",
				}}
			>
				{children}
			</Paragraph>
		);
	}
	return (
		<Space style={{ width: "100%" }} align="start">
			<Paragraph style={{ marginBottom: 0 }}>
				{children}
				<Link onClick={() => setExpand(false)}> Less</Link>
			</Paragraph>
		</Space>
	);
};

export default CustomParagraph;
