import { Form, Select } from "antd";
import { useContext, useMemo } from "react";
import { capitalize } from "../utils/dataTools";
import { VisualContext } from "../contexts/context";

interface PlotInputsProps {}

const PlotInputs = ({}: PlotInputsProps) => {
	// CONTEXT
	const { state: visualState, dispatch: visualDispatch } =
		useContext(VisualContext);

	// MEMOS
	const getFieldOptions = useMemo(() => {
		return visualState.fields.map((field: any) => ({
			key: field,
			label: field,
			value: field,
		}));
	}, [visualState.fields]);

	const getTypeOptions = useMemo(() => {
		return [
			// "bar", "scatter", "pie", "indicator",
			"card",
		].map((type) => ({
			key: type,
			label: capitalize(type),
			value: type,
		}));
	}, []);

	// CALLBACKS
	const handleDataChange = (name: string, value: any) => {
		visualDispatch({ type: "UPDATE_DATA", payload: { name, value } });
	};

	const renderInputs = useMemo(() => {
		switch (visualState.visual?.data?.type) {
			case "scatter":
			case "bar":
				return (
					<>
						<Form.Item label="Y-axis">
							<Select
								dropdownStyle={{ zIndex: 30003 }}
								placeholder={"Search to select..."}
								options={getFieldOptions}
								value={visualState.visual?.data?.y || null}
								onChange={(value) => handleDataChange("y", value)}
							/>
						</Form.Item>
						<Form.Item label="X-axis">
							<Select
								dropdownStyle={{ zIndex: 30003 }}
								placeholder={"Search to select..."}
								options={getFieldOptions}
								value={visualState.visual?.data?.x || null}
								onChange={(value) => handleDataChange("x", value)}
							/>
						</Form.Item>
					</>
				);

			case "pie":
				return (
					<>
						<Form.Item label="Labels">
							<Select
								dropdownStyle={{ zIndex: 30003 }}
								options={getFieldOptions}
								value={visualState.visual?.data?.labels || null}
								placeholder={"Search to select..."}
								onChange={(value) => handleDataChange("labels", value)}
							/>
						</Form.Item>
						<Form.Item label="Values">
							<Select
								dropdownStyle={{ zIndex: 30003 }}
								options={getFieldOptions}
								value={visualState.visual?.data?.values || null}
								placeholder={"Search to select..."}
								onChange={(value) => handleDataChange("values", value)}
							/>
						</Form.Item>
					</>
				);
			case "card":
			case "indicator":
				return (
					<>
						<Form.Item label="Value">
							<Select
								dropdownStyle={{ zIndex: 30003 }}
								options={getFieldOptions}
								value={visualState.visual?.data?.value || null}
								placeholder={"Value"}
								onChange={(value) => handleDataChange("value", value)}
							/>
						</Form.Item>
						<Form.Item label="Measure Indicator Field">
							<Select
								dropdownStyle={{ zIndex: 30003 }}
								options={getFieldOptions}
								value={visualState.visual?.data?.indicator_value || null}
								placeholder={"Indicator field"}
								onChange={(value) => handleDataChange("indicator_value", value)}
							/>
						</Form.Item>
					</>
				);
			default:
				return <></>;
		}
	}, [visualState.visual, getFieldOptions]);

	if (!visualState.visual.measure) return null;

	return (
		<>
			<Form.Item label="Visual type">
				<Select
					options={getTypeOptions}
					dropdownStyle={{ zIndex: 30003 }}
					value={visualState.visual?.data?.type || null}
					placeholder={"Type"}
					onChange={(value) => handleDataChange("type", value)}
				/>
			</Form.Item>
			{visualState?.visual?.data?.type ? (
				<>
					{renderInputs}
					{/* {visualState?.visual?.data?.type !== "card" ? (
						<Form.Item label="Legend">
							<Select placeholder="Legend " />
						</Form.Item>
					) : null} */}
				</>
			) : null}
		</>
	);
};

export default PlotInputs;
