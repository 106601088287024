import { api } from "../../contexts/AuthContext";
import { idToken, Tenant } from "./_exports";

export const getDocumentList = (
	container: any,
	tags: any,
	roles: any,
	folder: any
) => {
	if (tags.length === 0 || !tags) {
		tags = undefined;
	}
	return new Promise((resolve, reject) => {
		api.get(`/azure-blob/getbloblist`, {
			params: {
				container: container,
				tags: tags,
				roles: roles,
				folder: folder,
				super_role: process.env.REACT_APP_SUPER_ADMIN_TAG,
			},
			headers: {
				Authorization: idToken,
			},
		})
			.then((res: any) => {
				// //console.log(res)
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const getContainers = () => {
	return new Promise((resolve, reject) => {
		api.get(`/azure-blob/getAllContainers`)
			.then((res: any) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const getDocument = (
	filename: any,
	containername: any,
	filetype: any
) => {
	// headers["responseType"] = "arraybuffer";
	return new Promise((resolve, reject) => {
		api.get(
			`/azure-blob/getblob?filename=${filename}&&containername=${containername}`
		)
			.then((res: any) => {
				// window.open(res.data, "_blank");

				resolve(res.data);
				// var blob = new Blob([res.data], { type: filetype });
				// var fileUrl = URL.createObjectURL(blob);
				// if (filetype === "application/pdf") {
				// 	window.open(fileUrl, "_blank");
				// 	resolve(true);
				// } else {
				// 	var link = document.createElement("a");
				// 	link.href = fileUrl;
				// 	link.download = filename;
				// 	link.click();
				// 	resolve(true);
				// }
			})
			.catch((error: any) => {
				reject(error);
			});
	});
};

export const getDocumentbyTags = (tagstring: any) => {
	// headers["responseType"] = "arraybuffer";
	return new Promise((resolve, reject) => {
		api.get(`/azure-blob/getblobTags?tagstring=${tagstring}`)
			.then((res: any) => {
				// var blob = new Blob([res.data], { type: "application/pdf" });
				// var fileUrl = URL.createObjectURL(blob);
				// window.open(res.data, "_blank");
				resolve(res.data);
				// resolve(true);
			})
			.catch((error: any) => {
				reject(error);
			});
	});
};

export const getFolders = () => {
	return new Promise((resolve, reject) => {
		api.get(`/documentmgt/${Tenant}`)
			.then((res: any) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const addFolder = (data: any) => {
	return new Promise((resolve, reject) => {
		api.post(`/documentmgt/${Tenant}`, data)
			.then((res: any) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const updateFolder = (data: any) => {
	return new Promise((resolve, reject) => {
		api.put(`/documentmgt/${Tenant}`, data)
			.then((res: any) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const getReleaseNotes = () => {
	return new Promise((resolve, reject) => {
		api.get(`/releasenotes`)
			.then((res: any) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const solrSearchDocs = (value: any) => {
	return new Promise((resolve, reject) => {
		api.get(`/search?searchValue=${value}`)
			.then((res: any) => {
				resolve(res.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
