import { Table, TableProps } from "antd";
import { useCallback, useContext, useMemo } from "react";
import { CustomDashboardContext, MeasureContext } from "../contexts/context";
import { ACTIONTYPES } from "../reducers/measureReducer";

interface SchemaTableProps extends TableProps<any> {}

const SchemaTable = ({ ...restProps }: SchemaTableProps) => {
	const { tables, schema_unauthorized = [] } = useContext(
		CustomDashboardContext
	);
	const { state: measureState, dispatch: measureDispatch } =
		useContext(MeasureContext);

	const onRow = useCallback((record: any) => {
		return {
			onClick: () => {
				measureDispatch({ type: ACTIONTYPES.SCHEMA, payload: record.key });
			},
			style: { cursor: "pointer" },
		};
	}, []);

	const dataSource: Array<any> = useMemo(() => {
		if (tables?.length === 0) return [];

		const schemas = tables
			?.map((tbl) => tbl?.schema)
			?.reduce((acc, curr) => {
				if (!acc.includes(curr)) acc.push(curr);
				return acc;
			}, [])
			?.filter((schema: string) => !schema_unauthorized?.includes(schema))
			?.map((schema: any) => {
				return { schema, key: schema };
			});

		if (measureState.searchText !== "")
			return schemas?.filter((el: any) =>
				el.schema.includes(measureState.searchText)
			);

		return schemas;
	}, [tables, measureState.searchText, schema_unauthorized]);

	const columns = useMemo(() => {
		if (dataSource.length === 0) return [];
		return [{ dataIndex: "schema", title: "Schema", key: "schema" }];
	}, [dataSource]);

	return (
		<Table
			{...restProps}
			bordered
			rootClassName="schema-table"
			size="small"
			dataSource={dataSource}
			columns={columns}
			onRow={onRow}
		/>
	);
};

export default SchemaTable;
