import { createContext, useContext, useEffect, useState } from "react";
import { getAccessRequests } from "../services/api-server/access_requests";
import { getUser } from "../services/api-server/usermgt";
import { socket } from "../utils/socket";

type AccessRequestContextProps = {
	accessRequests: Partial<AccessRequest>[];
	loading: boolean;
};

const AccessRequestContext = createContext<
	AccessRequestContextProps | undefined
>(undefined);

type AccessRequestProviderProps = {
	children: React.ReactNode;
	menuKey: string;
};

const AccessRequestProvider = ({
	children,
	menuKey,
}: AccessRequestProviderProps) => {
	const [requests, setRequests] = useState<Partial<AccessRequest>[]>([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const fetchRequests = async () => {
			try {
				const ars: any[] = []
				setLoading(true);

				const data = await getAccessRequests();
				let userMap: Record<string, string> = {}

				const filtered = data
					.filter((_data) => _data.menuKey === menuKey)

				for (const _data of filtered) {
					if (userMap[`${_data.requesterId}`]) {
						ars.push({ ..._data, email: userMap[`${_data.requesterId}`] })
					} else {
						const user = await getUser(_data.requesterId || "");
						userMap[`${_data.requesterId}`] = user?.email
						ars.push({ ..._data, email: user?.email })
					}
				}

				setRequests(ars);
			} catch (error) {
				// console.error(error);
			} finally {
				setLoading(false);
			}
		};

		fetchRequests();

		socket.on("REFRESH_ACCESS_REQUEST", fetchRequests);

		return () => {
			socket.off("REFRESH_ACCESS_REQUEST", fetchRequests);
		};
	}, [menuKey]);

	return (
		<AccessRequestContext.Provider
			value={{ accessRequests: requests, loading }}
		>
			{children}
		</AccessRequestContext.Provider>
	);
};

const useAccessRequestContext = () => {
	const context = useContext(AccessRequestContext);

	if (!context) {
		throw new Error(
			"useAccessRequestContext must be used within an AccessRequestProvider"
		);
	}

	return context;
};

export { AccessRequestProvider, useAccessRequestContext };
