import React, { useCallback, useContext, useMemo } from "react";
import Plot from "react-plotly.js";
import {
	Card,
	CardProps,
	Dropdown,
	Empty,
	Flex,
	MenuProps,
	Spin,
	Tooltip,
	Typography,
} from "antd";
import useSize2 from "../hooks/useSize2";
import { CustomDashboardContext, VisualContext } from "../contexts/context";
import { EditOutlined } from "@ant-design/icons";
import useVisualData from "../hooks/useVisualData";
import VisualDeleteHandler from "./VisualDeleteHandler";
import CustomStatistic from "./CustomStatistic";

const { Link } = Typography;

type VisualProps = CardProps & {
	visual?: any;
	plotStyle?: React.CSSProperties;
	staticPlot?: boolean;
	layoutId?: string;
	showEdit?: boolean;
	showDelete?: boolean;
	params?: any;
	showTarget?: boolean;
	showDescription?: boolean;
};

const Visual = ({
	visual,
	plotStyle,
	staticPlot = false,
	layoutId,
	showEdit,
	showDelete,
	params = { useTarget: false },
	showTarget = false, // a prop to determine when to show, in (e.g dahsboard, visual library, visual modal)
	showDescription = true, // a prop to have a tooltip on the visual to show description
	...restProps
}: VisualProps) => {
	const { height } = useSize2(`el-${layoutId}`);
	const { state: dashboardState, isOwner } = useContext(CustomDashboardContext);
	const actionable = !dashboardState.editMode;

	const { data: vData, isLoading } = useVisualData({ visual, params });
	const { dispatch: visualDispatch } = useContext(VisualContext);

	// CALLBACKS
	const handleEdit = useCallback(() => {
		visualDispatch({ type: "EDIT_VISUAL", payload: visual });
	}, [visual]);

	const dropdownMenu: MenuProps["items"] = useMemo(() => {
		const menu: MenuProps["items"] = [];
		if (showEdit)
			menu.push({
				label: <Link onClick={handleEdit}>Edit</Link>,
				type: "group",
			});
		if (showDelete)
			menu.push({
				label: <VisualDeleteHandler visual={visual} layoutId={layoutId} />,
				type: "group",
			});

		return menu;
	}, [layoutId, visual]);

	// RENDER
	const renderTitle = () => {
		if (visual?.title || visual?.subtitle) {
			return (
				<Card.Meta
					title={visual?.title}
					//  description={visual?.subtitle}
				/>
			);
		}
	};

	const renderBody = () => {
		if (isLoading) return null;

		if ((vData && vData?.length === 0) || !visual?.data?.type) {
			return (
				<Flex
					justify="center"
					align="center"
					style={{ height: "100%", width: "100%" }}
				>
					<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
				</Flex>
			);
		}

		if (visual?.data?.type === "card") {
			// to handle situations when user uses the predefined date ranges. (e.g day, month, week, quarter, year)
			const { useTarget } = params;

			return (
				<CustomStatistic
					valueField={visual?.data.value}
					valueObj={vData[0]}
					indicator_value={vData[0]?.[visual?.data?.indicator_value]}
					targets={showTarget && useTarget ? visual?.targets : null}
					unit={params?.unit}
					indicator={visual?.indicator}
					target_multiplier={visual?.target_multiplier}
					target_based_on={visual?.target_based_on}
				/>
			);
		} else {
			return (
				<Plot
					style={{
						width: "100%",
						// height: height - 48,
						...plotStyle,
					}}
					useResizeHandler
					data={[
						{
							type: visual?.data?.type,
							x: vData?.map((d: any) => d[visual?.data?.x]),
							y: vData?.map((d: any) => d[visual?.data?.y]),
							values: vData?.map((d: any) => d[visual?.data?.values]),
							labels: vData?.map((d: any) => d[visual?.data?.labels]),
							hole: 0.7,
							mode: "gauge+number",
							value: vData?.[0]?.[visual?.data?.value],
						},
					]}
					layout={{
						autosize: true,
						showlegend: false,
						plot_bgcolor: "rgb(17, 29, 44)",
						paper_bgcolor: "rgb(17, 29, 44)",
						// margin: staticPlot ? { l: 0, r: 0, t: 0, b: 0 } : {},
						margin: { l: 0, r: 0, t: 0, b: 0 },
						// legend: {
						// 	x: 0.5,
						// 	y: -0.3,
						// 	orientation: "h",
						// 	xanchor: "center",
						// 	yanchor: "bottom",
						// },
					}}
					config={{ staticPlot, displayModeBar: false }}
				/>
			);
		}
	};

	const getExtra = () => {
		if (!isOwner) return null;

		if (showEdit || (showDelete && dashboardState.editMode))
			return (
				<Dropdown disabled={!actionable} menu={{ items: dropdownMenu }}>
					<Link>
						<EditOutlined />
					</Link>
				</Dropdown>
			);
		return null;
	};

	return (
		<Spin spinning={isLoading} style={{ height: "100%" }}>
			<Tooltip title={showDescription && visual?.subtitle}>
				<Card
					{...restProps}
					title={renderTitle()}
					extra={getExtra()}
					style={{
						background: "rgb(17, 29, 44)",
						border: "2px solid #112A45",
						borderRadius: 2,
						...restProps.style,
					}}
					styles={{
						body: {
							height: height !== 0 ? height : undefined,
							display: "flex",
							...restProps.styles?.body,
						},
						header: {
							borderColor: "#112A45",
							borderWidth: 2,
							padding: "16px 24px",
							...restProps.styles?.header,
						},
					}}
					className="visual-card"
				>
					{renderBody()}
				</Card>
			</Tooltip>
		</Spin>
	);
};

export default Visual;
