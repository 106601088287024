import {
	Button,
	Checkbox,
	Collapse,
	Dropdown,
	InputNumber,
	Space,
	Typography,
} from "antd";
import { useCallback, useContext, useMemo } from "react";
import { ACTIONTYPES } from "../reducers/measureReducer";
import {
	bitOperators,
	checkTable,
	joins,
	replaceTable,
} from "../utils/queryBuilder";
import { GetAntIcon } from "../utils/ant_icons";
import { capitalize } from "../utils/dataTools";
import { Dictionary } from "lodash";
import { CustomDashboardContext, MeasureContext } from "../contexts/context";
import SelectClause from "./Clauses/SelectClause";
import { FromClause } from "./Clauses/FromClause";
import { WhereClause } from "./Clauses/WhereClause";
import { OrderByClause } from "./Clauses/OrderByClause";
import { GroupByClause } from "./Clauses/GroupByClause";

interface QueryBuilderProps {}

const { Link } = Typography;

const collapseLabel2: Dictionary<string> = {
	from: "From",
	select: "Select",
	where: "Where",
	orderBy: "Order By",
	groupBy: "Group By",
	limitHint: "Limit Hint",
};

const QueryBuilder = ({}: QueryBuilderProps) => {
	const { state, dispatch }: any = useContext(MeasureContext);
	const { tables } = useContext(CustomDashboardContext);
	const { measure } = state;
	const { queryOptions } = measure;

	const tableOptions = useMemo(() => {
		if (tables) {
			return tables?.map((table: any) => ({
				label: table.name,
				value: table.name,
				table,
			}));
		}
	}, [tables]);

	const handleChange = useCallback(
		(key: string, value: any) => {
			let options = { ...state.measure.queryOptions, [key]: value };
			options = replaceTable(options, state?.tables);
			options = checkTable(options);

			dispatch({
				type: ACTIONTYPES.UPDATE_MEASURE,
				payload: { key: "queryOptions", value: options },
			});
		},
		[state.measure, state.tables]
	);

	const collapseItems = useMemo(() => {
		const bitOps = bitOperators.map((op) => ({
			key: op,
			label: (
				<Link onClick={() => handleInsertClause("where", op.toUpperCase())}>
					{capitalize(op)}
				</Link>
			),
		}));

		const joinOps = joins.map((type) => ({
			key: type,
			label: (
				<Link onClick={() => handleInsertClause("from", type.toUpperCase())}>
					{capitalize(type)}
				</Link>
			),
		}));

		const handleInsertClause = (clause: string, value?: any) => {
			dispatch({ type: ACTIONTYPES.INSERT_CLAUSE, payload: { clause, value } });
		};

		const handleDeleteClause = (clause: string) => {
			dispatch({ type: ACTIONTYPES.DELETE_CLAUSE, payload: clause });
		};

		const getChildren = (clause: string) => {
			switch (clause) {
				case "groupBy":
					return (
						<Space style={{ width: "100%" }} direction="vertical">
							<GroupByClause
								entries={queryOptions?.groupBy?.columns}
								onChange={(entries) =>
									dispatch({
										type: ACTIONTYPES.UPDATE_QUERY_OPTIONS,
										payload: { key: "groupBy", value: entries },
									})
								}
							/>
							<Button
								onClick={() => handleInsertClause(clause)}
								size="small"
								type="primary"
								icon={GetAntIcon("plus")}
							>
								Add
							</Button>
						</Space>
					);
				case "select":
					return (
						<Space style={{ width: "100%" }} direction="vertical">
							<Space>
								Distinct
								<Checkbox
									onChange={(ev) =>
										handleChange(clause, {
											...queryOptions?.[clause],
											distinct: ev.target.checked,
										})
									}
								/>
							</Space>

							<SelectClause
								entries={queryOptions?.select?.columns}
								onChange={(entries) =>
									dispatch({
										type: ACTIONTYPES.UPDATE_QUERY_OPTIONS,
										payload: { key: "select", value: entries },
									})
								}
							/>
							<Button
								onClick={() => handleInsertClause(clause)}
								size="small"
								type="primary"
								icon={GetAntIcon("plus")}
							>
								Add
							</Button>
						</Space>
					);
				case "where":
					return (
						<Space style={{ width: "100%" }} direction="vertical">
							<WhereClause
								entries={queryOptions?.where?.columns}
								onChange={(entries) =>
									dispatch({
										type: ACTIONTYPES.UPDATE_QUERY_OPTIONS,
										payload: { key: "where", value: entries },
									})
								}
							/>
							<Dropdown
								overlayStyle={{ zIndex: 30003 }}
								menu={{
									items: bitOps,
								}}
							>
								<Button size="small" type="primary" icon={GetAntIcon("plus")}>
									Add
								</Button>
							</Dropdown>
						</Space>
					);
				case "orderBy":
					return (
						<Space style={{ width: "100%" }} direction="vertical">
							<OrderByClause
								entries={queryOptions?.orderBy?.columns}
								onChange={(entries) =>
									dispatch({
										type: ACTIONTYPES.UPDATE_QUERY_OPTIONS,
										payload: { key: "orderBy", value: entries },
									})
								}
							/>
							<Button
								onClick={() => handleInsertClause(clause)}
								size="small"
								type="primary"
								icon={GetAntIcon("plus")}
							>
								Add
							</Button>
						</Space>
					);
				case "from":
					return (
						<Space style={{ width: "100%" }} direction="vertical">
							<FromClause
								entries={queryOptions?.from?.tables}
								onChange={(entries) =>
									dispatch({
										type: ACTIONTYPES.UPDATE_QUERY_OPTIONS,
										payload: { key: "from", value: entries },
									})
								}
							/>

							<Dropdown
								overlayStyle={{ zIndex: 30003 }}
								menu={{
									items: joinOps,
								}}
							>
								<Button size="small" type="primary" icon={GetAntIcon("plus")}>
									Join
								</Button>
							</Dropdown>
						</Space>
					);
				case "limitHint":
					return (
						<Space style={{ width: "100%" }} direction="vertical">
							<InputNumber
								max={5000}
								onChange={(value) => handleChange(clause, value)}
								value={queryOptions?.[clause]}
							/>
						</Space>
					);

				default:
					return <></>;
			}
		};

		return Object.keys(queryOptions)?.map((clause) => {
			const settingMenuItems = [
				{
					key: "delete",
					label: <Link onClick={() => handleDeleteClause(clause)}>Delete</Link>,
				},
			];
			return {
				label: collapseLabel2[clause],
				children: getChildren(clause),
				extra:
					clause !== "from" ? (
						<Dropdown
							overlayStyle={{ zIndex: 30003 }}
							menu={{ items: settingMenuItems }}
						>
							{GetAntIcon("setting")}
						</Dropdown>
					) : null,
			};
		});
	}, [queryOptions, tableOptions]);

	return <Collapse items={collapseItems} />;
};

export default QueryBuilder;
