import { Flex, Form, Input, Modal, ModalProps, Typography } from "antd";
import { useState } from "react";
import { submitRequest } from "../../services/api-server/access_requests";
import { v4 as uuid } from "uuid";
import Emitter from "../../services/EventEmitter";
import { socket } from "../../utils/socket";
import { useForm } from "antd/es/form/Form";
import { useSelector } from "react-redux";
import { getAppUser } from "../../services/api-server/admin";
import { sendSubmissionEmail } from "../../services/api-server/sendgrid";

type RequestAccessModalProps = ModalProps & {
	menuItem?: any;
};

const { Text } = Typography;
const { TextArea } = Input;

const RequestAccessModal = ({
	menuItem,
	onCancel = () => {},
	...restProps
}: RequestAccessModalProps) => {
	const [form] = useForm();
	const [loading, setLoading] = useState(false);
	const user = useSelector((state: any) => state.user);
	const testRecipients = useSelector((state: any) => state.testRecipients);
	const mode = useSelector((state: any) => state.mode);

	const getOwners = async (owners: any = []) => {
		const ownerPromises = owners?.map(async (o: string) => {
			try {
				const user = await getAppUser(o);
				return {
					...user,
					removable: false,
				};
			} catch (error) {
				return;
			}
		});

		// Filtering deleted user from results
		return (await Promise.all(ownerPromises)).filter((o) => o);
	};

	const handleSendRequest = async (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		e.preventDefault();

		try {
			setLoading(true);

			const { comment } = await form.validateFields();
			const requestedBy = user?.name || "";
			const requesterId = user?.oid;
			const id = uuid();
			const menuKey = menuItem?.key;
			const owners = menuItem?.owners || [];

			const request: Partial<AccessRequest> = {
				id,
				requestedBy,
				requesterId,
				comment,
				menuKey,
			};

			await submitRequest(request);

			const receivers =
				mode === "testing"
					? testRecipients?.map((recipient: any) => recipient?.email)
					: (await getOwners(owners))?.map((owner: any) => owner?.mail);

			await sendSubmissionEmail({
				component: menuItem?.component,
				title: menuItem?.title,
				description: comment,
				receivers,
				redirect_url: `${window.location.origin}${window.location.pathname}?am=true`,
			});

			Emitter.emit("alert", {
				type: "success",
				message: "Request sent successfully!",
				description: "The component owner will get back to you shortly.",
				timeout: 5000,
			});

			socket.emit("ACCESS_REQUEST_STATUS_CHANGED");
			form.resetFields();
			onCancel(e);
		} catch (error) {
			// console.error(error);
		} finally {
			setLoading(false);
		}
	};

	const handleCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		form.resetFields();
		onCancel(e);
	};

	return (
		<Modal
			{...restProps}
			title={"Request access"}
			okText={"Send request"}
			onOk={handleSendRequest}
			okButtonProps={{ loading }}
			maskClosable={false}
			onCancel={handleCancel}
			centered
		>
			<Flex vertical gap={16}>
				<Text>Describe why you would like to access this component.</Text>
				<Form form={form} onFinish={submitRequest}>
					<Form.Item
						name={"comment"}
						rules={[
							{
								required: true,
								message:
									"Please provide a reason for requesting access to the component.",
								transform(value) {
									return typeof value === "string" ? value.trim() : value;
								},
							},
						]}
					>
						<TextArea
							placeholder={`I would like access to ${menuItem?.title} because...`}
						/>
					</Form.Item>
				</Form>
			</Flex>
		</Modal>
	);
};

export default RequestAccessModal;
