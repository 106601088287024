import React, { useMemo, useState } from "react";
import CodeMirror, { ReactCodeMirrorProps } from "@uiw/react-codemirror";
import { langs } from "@uiw/codemirror-extensions-langs";
import { CompletionSource, autocompletion } from "@codemirror/autocomplete";
import { getTheme, themes } from "../utils/codemirrrortheme";
import { parameters } from "../utils/queryBuilder";

interface CustomEditorProps extends ReactCodeMirrorProps {
	completions?: Array<any>;
}

const paramsMentions = parameters?.map((param) => ({
	label: `@${param.name}`,
	type: "variable",
}));

const CustomEditor = ({
	completions,
	value = "",
	...restProps
}: CustomEditorProps) => {
	const [theme, setTheme] = useState("vscodeDark");

	const options = useMemo(() => {
		return [...paramsMentions, ...(completions || [])];
	}, [completions]);

	const myCompletions = (context: any) => {
		// let word = context.matchBefore(/\w*/);
		let word = context.matchBefore(/[\@\w]*/);
		if (word.from == word.to && !context.explicit) return null;
		return {
			from: word.from,
			options: options,
			// [
			// 	{ displayLabel: "@Vessel ID", label: "@vessel_id", type: "variable" },
			// 	{ label: "match", type: "keyword" },
			// 	{ label: "hello", type: "variable", info: "(World)" },
			// 	{ label: "magic", type: "text", apply: "⠁⭒*.✩.*⭒⠁", detail: "macro" },
			// ],
		};
	};

	const extensions = [
		langs.sql(),
		autocompletion({ override: [myCompletions] }),
	];

	return (
		<CodeMirror
			{...restProps}
			theme={getTheme(theme)}
			style={{ width: "100%" }}
			extensions={extensions}
			basicSetup={{
				tabSize: 4,
				allowMultipleSelections: true,
			}}
			value={value}
		/>
	);
};

export default CustomEditor;
