import React, { useEffect, useState } from "react";
import { duckQuery } from "../services/api-server/deltashare";
import { socket } from "../utils/socket";
import Emitter from "../services/EventEmitter";

const useVessels = () => {
	const [vessels, setVessels] = useState<Array<any>>([]);
	const [loading, setLoading] = useState(false);

	const updateVessels = () => {
		setLoading(true);
		duckQuery("SELECT DISTINCT(*) FROM vessel")
			.then((data: any) => {
				setVessels(data?.response);
				setLoading(false);
			})
			.catch((error) => {
				// console.error(error);
				// window.location.href = window.location.href;
				// Emitter.emit("alert", {
				// 	type: "error",
				// 	message: "Unsuccessful query",
				// 	description: "Please refresh the page or try again later.",
				// });
			});
	};

	useEffect(() => {
		updateVessels();

		socket.on("DATA_UPDATED", updateVessels);

		return () => {
			socket.off("DATA_UPDATED", updateVessels);
		};
	}, []);

	return { vessels, loading };
};

export default useVessels;
