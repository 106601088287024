import { Table, TableProps } from "antd";
import { useCallback, useContext, useMemo } from "react";
import CustomParagraph from "./CustomParagraph";
import { CustomDashboardContext, MeasureContext } from "../contexts/context";
import { ACTIONTYPES } from "../reducers/measureReducer";
import { duckQuery } from "../services/api-server/deltashare";

interface DataSourceTableProps extends TableProps<any> {}

const { Column } = Table;

const DataSourceTable = ({ ...restProps }: DataSourceTableProps) => {
	const { tables, data_unauthorized = [] } = useContext(CustomDashboardContext);
	const { state: measureState, dispatch: measureDispatch } =
		useContext(MeasureContext);

	const dataSource = useMemo(() => {
		if (tables.length === 0) return [];
		// Filter by schema
		let filteredTables = tables.filter(
			(tbl: any) =>
				tbl.schema === measureState.selectedSchema &&
				!data_unauthorized?.includes(tbl?.name)
		);

		// When there is a filter
		if (measureState.searchText !== "") {
			filteredTables = filteredTables.filter((table: any) =>
				table.name.includes(measureState.searchText)
			);
		}

		const cleanedTable = filteredTables.map((tbl: any) => {
			const {
				metadata: { description = "" },
				name = "",
				id,
			} = tbl;
			return { key: id, id, name, description };
		});
		return cleanedTable;
	}, [measureState.selectedSchema, measureState.searchText]);

	const renderColumns = useMemo(() => {
		if (dataSource.length === 0) return [];
		const sampleRow = dataSource[0];
		const cols: any = [];

		for (const field in sampleRow) {
			if (field === "name")
				cols.push(<Column dataIndex={field} key={field} title={"Dataset"} />);
			if (field === "description") {
				const colRender = (value: any) => {
					return <CustomParagraph>{value}</CustomParagraph>;
				};
				cols.push(
					<Column
						dataIndex={field}
						key={field}
						title={"Description"}
						render={colRender}
					/>
				);
			}
		}

		return cols;
	}, [dataSource]);

	const onRow = useCallback((record: any) => {
		return {
			onClick: (e: any) => {
				if (e?.target?.tagName?.toLowerCase() !== "a") {
					const found = tables.find((tbl) => tbl.metadata.id === record.id);

					duckQuery(`SELECT * FROM "${record.name}" LIMIT 100`)
						.then((response) => {
							measureDispatch({
								type: ACTIONTYPES.TOP_HUNDRED_DATA,
								payload: response,
							});
						})
						.catch((error) => {
							// console.log(error);
						});

					measureDispatch({
						type: ACTIONTYPES.DATASET,
						payload: found,
					});
				}
			},
			style: { cursor: "pointer" },
		};
	}, []);

	return (
		<Table
			{...restProps}
			size="small"
			rootClassName="data-source-table"
			dataSource={dataSource}
			pagination={false}
			style={{ maxHeight: 400 }}
			bordered
			scroll={{ y: 319 }}
			onRow={onRow}
			title={() => "Datasets"}
		>
			{renderColumns}
		</Table>
	);
};

export default DataSourceTable;
