import { useCallback, useEffect, useState } from "react";
import { getVisuals } from "../services/api-server/visuals";
import Emitter from "../services/EventEmitter";

const useVisuals = () => {
	const [visuals, setVisuals] = useState<Array<any>>([]);
	const [loading, setLoading] = useState(false);

	const updateVisuals = useCallback(() => {
		getVisuals()
			.then((data: any) => {
				setVisuals(data || []);
			})
			.catch((error) => {
				// console.error(error);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	useEffect(() => {
		setLoading(true);
		// updates the visuals during the first render
		updateVisuals();

		// attaching a listener to update visuals
		Emitter.on("VISUALS_UPDATED", updateVisuals);

		// clean up
		return () => {
			Emitter.off("VISUALS_UPDATED", updateVisuals);
		};
	}, []);

	return { loading, visuals };
};

export default useVisuals;
