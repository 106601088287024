import { useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import { socket } from "./socket";

//Desktop Containers
import Map from "../containers/Map";
import PowerBI from "../containers/PowerBI";
import Comingsoon from "../containers/Comingsoon";
import SensorMapping from "../containers/SensorMapping";
import Documents from "../containers/Documents";
import DocumentTable from "../containers/DocumentTable";
import NoAccess from "../containers/NoAccess";
import KognifaiDashboard from "../containers/KognifaiDashboard";
import ASTEG from "../containers/ASTEG";
import CustomDashboard from "../containers/CustomDashboard";
import MenuManager from "../containers/admin/MenuManager";
import withComponentManager from "../hoc/withComponentManager";
import DigitalTwin from "../containers/Digitaltwin";
import UptimeKuma from "../containers/admin/UptimeKuma";

const EnhancedMap = withComponentManager(Map);
const EnhancedPowerBI = withComponentManager(PowerBI);
const EnhancedComingsoon = withComponentManager(Comingsoon);
const EnhancedSensorMapping = withComponentManager(SensorMapping);
const EnhancedDocuments = withComponentManager(Documents);
const EnhancedDocumentTable = withComponentManager(DocumentTable);
const EnhancedKognifaiDashboard = withComponentManager(KognifaiDashboard);
const EnhancedASTEG = withComponentManager(ASTEG);
// const EnhancedDefault = withComponentManager(Default);
const EnhancedCustomDashboard = withComponentManager(CustomDashboard);
const EnhancedDigitalTwin = withComponentManager(DigitalTwin);

export const ComponentList = [
	"None",
	"Map",
	"PowerBI",
	"Documents",
	"Real-time Analytics",
	"ScanMAX",
	"Custom Dashboard",
	"Digital Twin",
	"Sensor Mapping",
	// "Kognifai Dashboard",
	// "Document Folder",
	// "Coming Soon",
	// "Placeholder",
];

export const AdminComponentList = ["Menu Manager", "Uptime Kuma"];

export const setComponent = (
	component: any,
	title: any,
	key: any,
	params: any | null = null,
	role: any | null = null
) => {
	switch (component) {
		case "Map":
			return <EnhancedMap params={params} />;
		case "Digital Twin":
			return <EnhancedDigitalTwin params={params} role={role} />;
		case "PowerBI":
			return <EnhancedPowerBI params={params} title={title} />;
		case "Real-time Analytics":
			return <EnhancedKognifaiDashboard params={params} />;
		case "Documents":
			return <EnhancedDocuments params={params} title={title} />;
		case "Document Folder":
			return <EnhancedDocumentTable params={params} title={title} />;
		case "Coming Soon":
			return <EnhancedComingsoon params={params} />;
		case "ScanMAX":
			return <EnhancedASTEG params={params} />;
		// case "Placeholder":
		// 	return <EnhancedDefault params={params} role={role} />;
		case "Custom Dashboard":
			return <EnhancedCustomDashboard params={params} role={role} />;
		case "Sensor Mapping":
			return <EnhancedSensorMapping params={params} role={role} />;
		default:
			return <NoAccess text={"The page is not fully setup yet"} />;
	}
};

export const setAdminComponent = (
	component: any,
	params: any | null = null,
	role: any | null = null
) => {
	switch (component) {
		case "Menu Manager":
			return <MenuManager params={params} role={role} />;
		case "Uptime Kuma":
			return <UptimeKuma params={params} role={role} />;
		default:
			return <NoAccess text={"The page is not fully setup yet"} />;
	}
};

export const Pagenotfound = () => {
	const params = useLocation(); //:pagename
	return (
		<div>
			<p> No Component has been set on "{params.pathname}"</p>
		</div>
	);
};

type menuProps = {
	menuProps: any;
};

// Finding out current component hook, this is only used for all components available in the ComponentList
export const ComponentHook = ({ menuProps }: menuProps) => {
	const Location = useLocation();
	useEffect(() => {
		if (socket) {
			socket.emit("page", {
				pathname: Location.pathname,
				component: menuProps.component,
			});
		}
	}, [menuProps]);
	return <></>;
};
