import { DatePicker, DatePickerProps, Typography } from "antd";
import dayjs from "dayjs";
import { useCallback, useEffect } from "react";
import {
	ParameterType,
	parameters as parameterOptions,
} from "../utils/queryBuilder";
import useVessels from "../hooks/useVessels";
import CustomSelectDropdown from "./CustomSelectDropdown";

const { Text } = Typography;

interface QueryParametersProps {
	parameters?: any;
	onChange?: (params?: any) => void;
}

const datePickerProps: DatePickerProps = {
	style: { width: 216, borderRadius: 2 },
	popupStyle: { zIndex: 30003 },
};

const QueryParameters = ({
	parameters,
	onChange = () => {},
}: QueryParametersProps) => {
	const { vessels } = useVessels();
	const handleChange = useCallback(
		(key: string, value: any) => {
			const params = { ...parameters, [key]: value };
			onChange(params);
		},
		[parameters]
	);

	const getOptions = useCallback(() => {
		if (vessels.length === 0) return;
		return vessels.map((vessel: any) => ({
			label: vessel.name,
			value: vessel.id,
		}));
	}, [vessels]);

	// setting the default params upon first render
	useEffect(() => {
		const defaultVesselsParam = vessels?.map((vessel: any) => vessel.id);
		onChange({ ...parameters, vessel_id: defaultVesselsParam });
	}, [vessels]);

	return (
		<div
			style={{
				display: "grid",
				gridTemplateColumns: "auto 1fr",
				gap: 8,
				paddingLeft: 8,
			}}
		>
			{/* Dynamically rendering params inputs */}
			{parameterOptions?.map((param) => {
				const { name, type } = param;
				switch (type) {
					case ParameterType.INPUT:
						return (
							<>
								<Text className="query-params-label">@{name}: </Text>
								<CustomSelectDropdown
									value={parameters?.[name]}
									options={getOptions()}
									onChange={(value: any) => handleChange(name, value)}
									fieldNames={{ label: "Vessels", value: "value" }}
									style={{ width: 216 }}
									overlayStyle={{ zIndex: 30003 }}
								/>
							</>
						);

					case ParameterType.DATE:
						return (
							<>
								<Text className="query-params-label">@{name}: </Text>
								<DatePicker
									{...datePickerProps}
									onChange={(value, dateString) =>
										handleChange(name, dateString)
									}
									value={!parameters?.[name] ? null : dayjs(parameters?.[name])}
								/>
							</>
						);
					default:
						return null;
				}
			})}
		</div>
	);
};

export default QueryParameters;
