import { createContext } from "react";
import {
	CustomDashboardAction,
	CustomDashboardState,
	initialState,
} from "../reducers/customDashboardReducer";
import { INIITAL_STATE, VisualAction } from "../reducers/visualReducer";
import { INITIALSTATE } from "../reducers/measureReducer";

/**
 * context values to be stored and used in other areas.
 * makes sure everything that's needed are loaded before dashboard
 * functionalities (measure creation, visual creation) are ready to be used
 */

export const CustomDashboardContext = createContext<{
	state: CustomDashboardState;
	dispatch: any;
	visuals: Array<any>;
	visual_groups: Array<any>;
	loaded: boolean;
	measures: Array<any>;
	metadataLoading: boolean;
	tables: Array<any>;
	menu: Array<any>;
	data_unauthorized: Array<any>;
	schema_unauthorized: Array<any>;
	isAdmin: boolean;
	isOwner: boolean;
	isViewer: boolean;
}>({
	state: initialState,
	dispatch: (action: CustomDashboardAction) => {},
	visuals: [],
	visual_groups: [],
	loaded: false,
	measures: [],
	metadataLoading: false,
	tables: [],
	menu: [],
	data_unauthorized: [],
	schema_unauthorized: [],
	isAdmin: false,
	isOwner: false,
	isViewer: false,
});

/**
 * storing visual states and dispatch to prevent props drilling
 */
export const VisualContext = createContext({
	state: INIITAL_STATE,
	dispatch: (action: VisualAction) => {},
});

/**
 * storing measure states and dispatch to prevent props drilling
 */
export const MeasureContext = createContext({
	state: INITIALSTATE,
	dispatch: (action: any) => {},
});
