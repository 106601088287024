import { Tag, TagProps } from "antd";

interface FromTagProps extends TagProps {
	showJoin?: boolean;
}

const FromTag = ({ style, children, ...restProps }: FromTagProps) => {
	return (
		<Tag
			{...restProps}
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				...style,
			}}
			color="blue"
		>
			{children}
		</Tag>
	);
};

export default FromTag;
