import { useEffect, useState } from "react";
import Emitter from "../services/EventEmitter";
import { getMeasures } from "../services/api-server/measures";

const useMeasures = () => {
	const [measures, setMeasures] = useState<Array<any>>([]);
	const [loading, setLoading] = useState(false);

	const updateMeasures = () => {
		getMeasures()
			.then((data: any) => {
				setMeasures(data || []);
			})
			.catch((error) => {
				// console.error(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		setLoading(true);

		// updates the measures during the first render
		updateMeasures();

		// attaching a listenerto update visuals
		Emitter.on("MEASURES_UPDATED", updateMeasures);

		// clean up
		return () => {
			Emitter.off("MEASURES_UPDATED", updateMeasures);
		};
	}, []);

	return { measures, loading };
};

export default useMeasures;
