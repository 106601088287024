import { loadMenu } from "../services/api-server/menu";
import {
	getUserAppRoleAssignments,
	getAppRoles2,
} from "../services/api-server/admin";
import {
	getAllTables,
	getMetaData,
	getShares,
} from "../services/api-server/deltashare";
import _, { Dictionary } from "lodash";
import { addRole, getRoles } from "../services/api-server/roles";
import { v4 as uuid } from "uuid";
import {
	getAllUsers,
	getUser,
	updateUser,
} from "../services/api-server/usermgt";

import dayjs from "dayjs";
import { Action, registerAction } from "../services/api-server/actions";
import { TargetProps } from "../components/TargetsConfig";
import chroma, { hsl, rgb } from "chroma-js";
import { Tenant, url } from "../services/api-server/_exports";
import store from "../state/store";
import { api } from "../contexts/AuthContext";

export const checkMobile = () => {
	let check = false;
	// eslint-disable-next-line
	(function (a) {
		if (
			/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|ad|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
				a
			) ||
			/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikim|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
				a.substr(0, 4)
			)
		)
			check = true;
	})(navigator.userAgent || navigator.vendor);
	return check;
};

export const clearCookie = () => {
	api
		.get(`/clearCookies`)
		.then((res: any) => {})
		.catch(() => {});
};

export const sendErrorNotification = (errorMessage: any) => {
	api
		.post(`/errorNotification`, {
			errorMessage,
		})
		.then((_data: any) => {});
};

export const setErrorAlertMessages = (error: any) => {
	console.log(error);
	// Emitter.emit("alert", {
	// 	type: "error",
	// 	message: `Error ${error.code} - ${error.}`,
	// 	description: `The File fordoes not exist`,
	// 	timeout: 5000,
	// });
};

export const logout = (autoLogout: boolean = false) => {
	const EndPoint = Tenant;
	const LogoutURL: any = url + "/logout";
	clearCookie();
	if (autoLogout) {
		window.location.href = `/${EndPoint}/login`;
	} else {
		window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${encodeURI(
			LogoutURL
		)}`;
	}
};

export const checkRoles = (userRoles: any, menuRoles: any) => {
	if (userRoles?.includes(process.env.REACT_APP_SUPER_ADMIN_TAG)) {
		return true;
	} else if (menuRoles?.length === 0 || !menuRoles) {
		return true;
	} else {
		return menuRoles?.some((element: any) => userRoles?.includes(element));
	}
};

// returns true if same and false if not
export const deepCompare = (prevState: any, currState: any) => {
	// Check if both objects are the same type
	if (typeof prevState !== typeof currState) {
		return false;
	}

	// Handle simple data types and null
	if (prevState === currState) {
		return true;
	}

	// Check for null or undefined
	if (
		prevState === null ||
		currState === null ||
		prevState === undefined ||
		currState === undefined
	) {
		return false;
	}

	// Check if both are arrays
	if (Array.isArray(prevState) && Array.isArray(currState)) {
		if (prevState.length !== currState.length) {
			return false;
		}
		for (let i = 0; i < prevState.length; i++) {
			if (!deepCompare(prevState[i], currState[i])) {
				return false;
			}
		}
		return true;
	}

	// Check if both are objects
	if (typeof prevState === "object" && typeof currState === "object") {
		const keys1 = Object.keys(prevState);
		const keys2 = Object.keys(currState);

		if (keys1.length !== keys2.length) {
			return false;
		}

		for (let key of keys1) {
			if (!deepCompare(prevState[key], currState[key])) {
				return false;
			}
		}
		return true;
	}

	return false;
};

export const mapSidemenu = (menuItem: any) => {
	return new Promise((resolve, reject) => {
		loadMenu()
			.then((data: any) => {
				if (data !== undefined) {
					const menus = data.menu;
					const allNodes = getAllNodes(menus);
					const found = allNodes.find((node: any) => {
						const { key } = menuItem;
						const [, menuKey] = key.split("_");
						return node.key === menuKey;
					});
					resolve(found);
				}
			})
			.catch((error: any) => {
				reject(error.message);
			});
	});
};

export const getAllNodes = (menus: any, sidemenu: boolean = false) => {
	const endNodes: any = [];
	const traverse = (mItem: any, parentRoute: string = "") => {
		// Node has no children, it is an end node
		endNodes.push({
			...mItem,
			path: `${parentRoute}/${mItem?.route?.link}`,
		});
		// Node has children, recursively traverse each child
		mItem?.children?.forEach((child: any) => {
			traverse(child, `${parentRoute}/${mItem?.route?.link}`);
		});

		// include side menu items
		if (sidemenu) {
			if (mItem?.sidebarmenu || mItem?.sidebarmenu?.length > 0) {
				mItem?.sidebarmenu?.forEach((menu: any) => {
					if (menu?.children || menu?.children?.length > 0) {
						menu?.children?.forEach((sideChild: any) => {
							traverse(sideChild, `/${mItem?.route?.link}`);
						});
					}
				});
			}
		}
	};

	menus.forEach((mItem: any) => {
		traverse(mItem);
	});

	return endNodes;
};

export const isNumeric = (value: any) => {
	return /^\d+$/.test(value);
};

export const getAllMetaData = async () => {
	return new Promise((resolve, reject) => {
		getShares().then((shares: any) => {
			allTablesForEachShare(shares)
				.then((response: any) => {
					let filteredTables: Array<any> = [];
					if (Array.isArray(response)) {
						filteredTables = response;
					}

					allMetadataForEachTable(filteredTables)
						.then((response: any) => {
							const _tables: any[] = [];
							filteredTables?.forEach((table: any) => {
								const metadatas = response.find(
									(data: any) => table.id === data?.metaData?.id
								);
								if (metadatas) {
									const { metaData, schema } = metadatas;
									_tables.push({
										...table,
										metadata: metaData,
										fields: schema?.fields,
									});
								}
							});

							resolve(_tables);
						})
						.catch((err) => {
							reject(err);
						});
				})
				.catch((err) => {
					reject(err);
				});
		});
	});
};

export const createComponentRoles = async (menuItem: any) => {
	const roleTypes = ["owner", "viewer"];
	const shortId = crypto.randomUUID().toString().slice(0, 3);

	try {
		const roles: any = [];
		for (const role of roleTypes) {
			// structure of the role
			const roleObj = {
				id: uuid(),
				name: `${menuItem?.component?.replaceAll(" ", "_")}_${shortId}_${role}`,
				menuKey: menuItem?.key,
			};
			await addRole(roleObj);
			roles.push(roleObj);
		}
		return roles;
	} catch (err) {
		throw err;
	}
};

export const setComponentOwner = async (
	id: string,
	ownerRoleObject: any,
	componentRoles: any,
	menuKey: string
) => {
	const { user } = store.getState();
	// console.log({ user, id });

	const viewerRole = componentRoles?.find((role: any) =>
		role.name.includes("viewer")
	);
	const ownerRole = componentRoles?.find((role: any) =>
		role.name.includes("owner")
	);

	try {
		const mUser = await getUser(id);
		// console.log(mUser);

		let updatedUserRoles: Array<string> = mUser?.roles || [];
		// * Check if the user has the component owner role, if yes, then remove it
		updatedUserRoles = updatedUserRoles.filter(
			(role) => role !== viewerRole.id
		);
		// console.log({ updatedUserRoles });

		updatedUserRoles = _.uniq([...updatedUserRoles, ownerRole.id]);

		// console.log({ updatedUserRoles });

		await updateUser(id, { roles: updatedUserRoles });

		// console.log("here");

		const action: Action = {
			menuKey: ownerRole.menuKey,
			actionType: "OWNERSHIP_GRANTED",
			approvedDate: dayjs().toISOString(),
			user_id: id,
			approvedBy: user?.name,
		};
		await registerAction(action, menuKey);
	} catch (err) {
		console.log({ err });
	}
};

export const removeComponentOwner = async (
	id: string,
	ownerRoleObject: any,
	actioner: string,
	menuKey: string
) => {
	try {
		const mUser = await getUser(id);
		const updatedUserRoles = mUser?.roles?.filter(
			(role: any) => role !== ownerRoleObject?.id
		);
		await updateUser(id, { roles: updatedUserRoles });
		const action: Action = {
			menuKey: ownerRoleObject.menuKey,
			actionType: "OWNERSHIP_REVOKED",
			approvedDate: dayjs().toISOString(),
			user_id: id,
			approvedBy: actioner,
		};
		await registerAction(action, menuKey);
	} catch (err) {
		throw err;
	}
};

export const setComponentViewer = async (
	id: string,
	viewerRoleObject: any,
	actioner: string,
	componentRoles: any,
	menuKey: string
) => {
	const viewerRole = componentRoles?.find((role: any) =>
		role.name.includes("viewer")
	);
	const ownerRole = componentRoles?.find((role: any) =>
		role.name.includes("owner")
	);

	try {
		const mUser = await getUser(id);
		let updatedUserRoles: Array<string> = mUser.roles || [];

		// * Check if the user has the component owner role, if yes, then remove it
		updatedUserRoles = updatedUserRoles.filter(
			(role: any) => role !== ownerRole.id
		);

		updatedUserRoles = _.uniq([...updatedUserRoles, viewerRole.id]);
		await updateUser(id, { roles: updatedUserRoles });
		const action: Action = {
			menuKey: viewerRole.menuKey,
			actionType: "VIEWERSHIP_GRANTED",
			approvedDate: dayjs().toISOString(),
			user_id: id,
			approvedBy: actioner,
		};
		await registerAction(action, menuKey);
	} catch (err) {
		throw err;
	}
};

export const removeComponentViewer = async (
	id: string,
	viewerRoleObject: any,
	actioner: string,
	menuKey: string
) => {
	try {
		const mUser = await getUser(id);
		const updatedUserRoles = mUser?.roles?.filter(
			(role: string) => role !== viewerRoleObject.id
		);
		await updateUser(id, { roles: updatedUserRoles });
		const action: Action = {
			menuKey: viewerRoleObject.menuKey,
			actionType: "VIEWERSHIP_REVOKED",
			approvedDate: dayjs().toISOString(),
			user_id: id,
			approvedBy: actioner,
		};
		await registerAction(action, menuKey);
	} catch (err) {
		throw err;
	}
};

export const areArraysEqual = (array1: Array<any>, array2: Array<any>) => {
	// Check if the arrays have the same length
	if (array1.length !== array2.length) {
		return false;
	}

	// Check each element of the arrays
	for (let i = 0; i < array1.length; i++) {
		if (array1[i] !== array2[i]) {
			return false;
		}
	}

	// If no differences are found, the arrays are equal
	return true;
};

export const getComponentRoles = (menuItem: any): Promise<Array<any>> => {
	const { key = "" } = menuItem;
	return new Promise((resolve, reject) => {
		getRoles()
			.then((roles: any) => {
				const componentRoles = roles.filter(
					(role: any) => role.menuKey === key
				);

				if (componentRoles?.length === 0) {
					createComponentRoles(menuItem)
						.then((roles) => {
							resolve(roles);
						})
						.catch((err) => {
							reject(err);
						});
				} else {
					resolve(componentRoles);
				}
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const removeRolesFromUsers = async (menuKey: string) => {
	try {
		// get component roles (viewer and owner role)
		const mongoRoles: any = await getRoles();
		const componentRoles = mongoRoles?.filter(
			(role: any) => role?.menuKey === menuKey
		);

		// console.log(componentRoles);

		// get all mongo users
		const mongoUsers: any = await getAllUsers();
		// console.log(mongoUsers);

		// check through each each user's roles and remove roles
		// that matches the component roles
		for (const user of mongoUsers) {
			const { roles = [] } = user;
			const { oid = "" } = user;

			if (
				roles.find((role: string) =>
					componentRoles?.includes((roleObj: any) => roleObj.id === role)
				)
			) {
				const updatedUserRoles = roles?.filter((role: string) => {
					const found = componentRoles?.find((roleObj: any) => {
						const { id = "" } = roleObj;
						return id === role;
					});
					return !found;
				});

				// update user
				await updateUser(oid, { roles: updatedUserRoles });
			}
		}
	} catch (err) {
		throw err;
	}
};

// getting all tables from shares and returns a flatten array of tables
const allTablesForEachShare = async (shares: Array<string>) => {
	const results: Array<any> = [];
	for (const share of shares) {
		try {
			const result = await getAllTables(share);
			results.push(result);
		} catch (error: any) {
			if (error?.response?.status === 429) {
				return getAllTables(share);
			} else {
				// console.error(error);
			}
		}
	}
	return _.flatten(results);
};

// getting metadata for each table
const allMetadataForEachTable = async (tables: Array<any>) => {
	let metadatas: any[] = [];
	try {
		const promises = tables?.map((table) => {
			const { share = "", schema = "", name = "" } = table;

			return retryWithExponentialBackoff(() =>
				getMetaData(share, schema, name)
			);
		});

		metadatas = await Promise.all(promises);
		const metadatasWithoutFailed = metadatas?.filter((_metadata) => {
			if (_metadata?.error) {
				console.error(
					"Error fetching metadata:",
					_metadata?.error?.response?.config?.params?.table
				);
			}
			return !_metadata?.error;
		});

		return metadatasWithoutFailed;
	} catch (error: any) {
		console.error("Error fetching metadatas");
	}
};

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const retryWithExponentialBackoff = async <T>(
	fn: () => Promise<T>,
	retries = 5,
	delayTime = 1000
) => {
	let attempt = 0;

	while (attempt < retries) {
		try {
			return await fn();
		} catch (error: any) {
			if (error.response && error.response.status === 429) {
				attempt++;
				const backoffTime = delayTime * Math.pow(2, attempt);
				console.warn(`Retrying after ${backoffTime}ms due to 429 error`);
				await delay(backoffTime);
			} else {
				// Re-throw non-429 errors
				return { error };
			}
		}
	}

	throw new Error("Max retries reached");
};

export const filterUsers = async (users: Array<any>) => {
	try {
		const appRoles: any = await getAppRoles2();
		const adminRole = appRoles.find(
			(appRole: any) =>
				appRole.displayName === process.env.REACT_APP_SUPER_ADMIN_TAG
		);

		const promises = users?.map((user) => getUserAppRoleAssignments(user?.id));
		const responses: any[] = await Promise.all(promises);

		// Filter users that does not have the admin role
		const filteredUsers = users?.filter(
			(_, i) => !responses[i]?.some((a: any) => a.appRoleId === adminRole?.id)
		);

		return filteredUsers;
	} catch (err: any) {
		throw err;
	}
};

export const getPathsToLeafNodes: any = (menu: any, currentPath = []) => {
	let paths: any = [];

	for (const item of menu) {
		let newPath = [...currentPath, item.route.link];

		if (item.children && item.children.length > 0) {
			// Recursively traverse children
			paths = paths.concat(getPathsToLeafNodes(item.children, newPath));
		} else {
			// Found a leaf node
			paths.push(newPath);
		}
	}

	return paths;
};

export const addPathToMenu = (menu: any, currentPath = []) => {
	for (const item of menu) {
		item.path = [...currentPath, item?.route?.link];

		if (item.children && item.children.length > 0) {
			// Recursively update children
			addPathToMenu(item.children, item.path);
		}
	}
};

export const getQuarter = (date: dayjs.Dayjs = dayjs()) => {
	const month = date.month();

	let startMonth = 0;
	let endMonth = 0;

	if (month >= 0 && month <= 2) {
		startMonth = 0;
		endMonth = 2;
		// return 1;
	} else if (month >= 3 && month <= 5) {
		startMonth = 3;
		endMonth = 5;
		// return 2;
	} else if (month >= 6 && month <= 8) {
		startMonth = 6;
		endMonth = 8;
		// return 3;
	} else if (month >= 9 && month <= 11) {
		startMonth = 9;
		endMonth = 11;
		// return 4;
	}

	const startDate = date.set("month", startMonth).startOf("month");
	const endDate = date.set("month", endMonth).endOf("month");

	return { startDate, endDate };
};

export const updatePercentBasedOnTolerance = (
	data: TargetProps,
	unit: keyof TargetProps
): TargetProps => {
	const currentUnit: any = data[unit];
	const { target, tolerance } = currentUnit;
	if ((!target && target !== 0) || tolerance === undefined) {
		return {
			...data,
			[unit]: {
				...currentUnit,
				target,
				tolerance_percent: undefined,
				tolerance: undefined,
			},
		};
	} else {
		return {
			...data,
			[unit]: {
				...currentUnit,
				target,
				tolerance,
				tolerance_percent:
					tolerance === 0
						? 0
						: Number(Number((tolerance / target) * 100).toFixed(1)),
			},
		};
	}
};

export const updateToleranceBasedOnPercent = (
	data: TargetProps,
	unit: keyof TargetProps
): TargetProps => {
	const currentUnit: any = data[unit];
	const { target, tolerance_percent } = currentUnit;

	if ((!target && target !== 0) || tolerance_percent === undefined) {
		return {
			...data,
			[unit]: {
				...currentUnit,
				target,
				tolerance_percent: undefined,
				tolerance: undefined,
			},
		};
	} else {
		return {
			...data,
			[unit]: {
				...currentUnit,
				target,
				tolerance_percent,
				tolerance: Number(
					Number((target * tolerance_percent) / 100).toFixed(1)
				),
			},
		};
	}
};

export const optionsFilterSort = (a: any, b: any) => {
	if (a.label < b.label) {
		return -1;
	} else if (a.label > b.label) {
		return 1;
	}
	// a must be equal to b
	return 0;
};

export const getColorDifference = (a: string, b: string) => {
	const aColor = chroma(a);
	const bColor = chroma(b);

	const [aHue, aSat, aLight] = aColor.hsl();
	const [bHue, bSat, bLight] = bColor.hsl();

	const satDiff = aSat - bSat;
	const lightDiff = aLight - bLight;

	return [satDiff, lightDiff];
};

export const getColors = (color: string = "#164c7e") => {
	const cColor = chroma(color);

	const [hue, sat, light] = cColor.hsl();

	const [satDiff, lightDiff] = getColorDifference("#3E2069", "#1A1325");
	const [satHeadDiff, lightHeadDiff] = getColorDifference("#3E2069", "#24163A");

	const newSat = sat - satDiff;
	const newLight = light - lightDiff;
	const newHeadSat = sat - satHeadDiff;
	const newHeadLight = light - lightHeadDiff;

	const borderColor = cColor.hex();
	const backgroundColor = hsl(hue, newSat, newLight)
		.alpha(cColor.alpha())
		.hex();
	const headerColor = hsl(hue, newHeadSat, newHeadLight).alpha(0.3).hex();

	const [red, green, blue, alpha] = chroma(backgroundColor).rgba();
	const overlay_alpha = 0.3;
	const newRed = (1 - overlay_alpha) * red + overlay_alpha * alpha;
	const newGreen = (1 - overlay_alpha) * green + overlay_alpha * alpha;
	const newBlue = (1 - overlay_alpha) * blue + overlay_alpha * alpha;
	const newAlpha = 1 - (1 - alpha) * (1 - overlay_alpha);

	const innerBackgroundColor = rgb(newRed, newGreen, newBlue, newAlpha).hex();

	return { borderColor, backgroundColor, headerColor, innerBackgroundColor };
};

export const getGridHeight = (
	h: number,
	rowH: number = 80,
	marginH: number = 10
) => {
	return rowH * h + marginH * (h - 1);
};

export const getNodes = (items: any[], onlyEnd: boolean = true) => {
	const nodes: any[] = [];

	const traverse = (item: any) => {
		if (item?.children && item?.children?.length > 0) {
			item?.children?.forEach((child: any) => traverse(child));
		} else {
			nodes.push(item);
		}
	};

	const traverseAll = (item: any) => {
		nodes.push(item);
		if (item?.children && item?.children?.length > 0) {
			item?.children?.forEach((child: any) => traverseAll(child));
		}
	};

	items?.forEach(onlyEnd ? traverse : traverseAll);

	return nodes;
};

export const findActiveMenuItems = (
	menu: any[],
	paths: string[],
	depth: number = 1
) => {
	let activeMenuItems: any[] = [];
	for (const menuItem of menu) {
		if (paths[depth - 1] === menuItem.route.link) {
			activeMenuItems.push(menuItem);
			if (depth === paths.length) {
				// Stop searching deeper if reached the desired depth
				return activeMenuItems;
			} else if (menuItem.children) {
				const activeChildren = findActiveMenuItems(
					menuItem.children,
					paths,
					depth + 1
				);
				if (activeChildren.length > 0) {
					activeMenuItems = activeMenuItems.concat(activeChildren);
					// Stop searching deeper into other branches if active items found
					return activeMenuItems;
				}
			}
		}
	}
	return activeMenuItems;
};

export const filterMenu = (
	menu: any[],
	userRoles: string[] = [],
	currUser?: any
): any[] => {
	const { user } = store.getState();

	const uRoles = new Set(user?.roles);

	let filteredMenu: any[] = [];

	const traverse = (menuItem: any): any | null => {
		const isRoleViewer = menuItem?.roles?.some((role: string) =>
			uRoles.has(role)
		);
		const isOwner = menuItem?.owners?.find(
			(userId: any) => userId === user?.oid
		);
		const isViewer = menuItem?.viewers?.find(
			(userId: any) => userId === user?.oid
		);
		if (
			menuItem.component_access !== "restricted_hidden" ||
			isOwner ||
			isViewer ||
			isRoleViewer
		) {
			if (menuItem.children && menuItem.children.length > 0) {
				const filteredChildren = menuItem.children
					.map(traverse)
					.filter((child: any) => child !== null);

				if (filteredChildren && filteredChildren?.length !== 0)
					return {
						...menuItem,
						children: filteredChildren,
					};
			} else {
				return { ...menuItem }; // Ensure children is an empty array if there are none
			}
		}
		return null;
	};

	menu.forEach((menuItem) => {
		const result = traverse(menuItem);
		if (result !== null) {
			filteredMenu.push(result);
		}
	});

	return filteredMenu;
};

export const saveToLS = (key: string, value: any) => {
	if (localStorage) {
		localStorage.setItem(key, JSON.stringify({ [key]: value }));
	}
};

export const getFromLS = (key: string) => {
	let ls: Dictionary<string> = {};
	if (localStorage) {
		try {
			ls = JSON.parse(localStorage.getItem(key) || "");
		} catch (error) {
			console.error("Error getting item from local storage:", error);
		}
	}

	return ls[key];
};

export const removeFromLS = (key: string) => {
	if (localStorage.getItem(key) !== null) {
		try {
			localStorage.removeItem(key);
		} catch (error) {
			console.error("Error removing item from local storage:", error);
		}
	}
};

export const isEqual = (a: any, b: any) => {
	return JSON.stringify(a) === JSON.stringify(b);
};

// Finding the parent menu item of a giving menu key
export const findParent = (menu: any, key: string) => {
	let found: any = null;
	let parent: any = null;
	const traverse = (menuItem: any, level: number = 1) => {
		if (found) return;

		if (menuItem?.key === key) {
			found = parent;
		}

		if (menuItem?.children && menuItem?.children?.length !== 0) {
			menuItem?.children?.forEach((child: any) => traverse(child, level + 1));
		}

		if (menuItem?.sidebarmenu && menuItem?.sidebarmenu?.length !== 0) {
			menuItem?.sidebarmenu?.forEach((mItem: any) => {
				if (mItem?.children && mItem?.children?.length !== 0) {
					mItem?.children?.forEach((_mItem: any) => traverse(_mItem));
				}
			});
		}
	};

	menu?.forEach((mItem: any) => {
		parent = mItem;
		traverse(mItem);
	});

	return found;
};
