import React, { CSSProperties, useContext } from "react";
import { Visual } from "../types/Visual";
import { CustomDashboardContext } from "../contexts/context";
import useQuery from "../hooks/useQuery";
import { Flex, Spin, Tooltip } from "antd";
import CustomStatistic from "./CustomStatistic";
import Plot from "react-plotly.js";

type VizProps = {
	visual: Visual;
	style?: CSSProperties;
	onClick?: React.MouseEventHandler<HTMLElement>;
};

const Viz = ({ visual, style }: VizProps) => {
	const {
		state: customDashboardState,
		dispatch: customDashboardDispatch,
		measures,
		data_unauthorized,
	} = useContext(CustomDashboardContext);

	const measure = measures?.find(
		(_measure) => _measure?.id === visual?.measure
	);

	const { data, loading } = useQuery({
		query: measure?.queryStatement,
		unauthorized_table: data_unauthorized,
		params:
			Object.entries(customDashboardState?.sliceValues).length !== 0
				? customDashboardState?.sliceValues
				: measure?.params,
	});

	return loading ? (
		<Flex justify="center" align="center" style={{ height: "100%" }}>
			<Spin />
		</Flex>
	) : (
		<Tooltip title={visual.drilldown ? "" : "Currently no drill-down mapped"}>
			<div style={{ height: "100%", width: "100%" }}>
				{visual?.data?.type === "card" ? (
					<CustomStatistic
						onClick={() => {
							if (visual.drilldown)
								customDashboardDispatch({
									type: "SHOW_DRILLDOWN",
									payload: visual.drilldown,
								});
						}}
						valueField={visual?.data?.value}
						valueObj={data?.[0]}
						targets={visual.targets}
						unit={customDashboardState?.sliceValues?.unit}
						indicator_value={data?.[0]?.[visual?.data?.indicator_value]}
						indicator={visual?.indicator}
						measure={measure}
						target_multiplier={visual?.target_multiplier}
						target_based_on={visual?.target_based_on}
					/>
				) : (
					<Plot
						style={{ height: "100%", width: "100%" }}
						data={[
							{
								values: data?.map((d: any) => d[visual?.data?.values]),
								labels: data?.map((d: any) => d[visual?.data?.labels]),
								x: data?.map((d: any) => d[visual?.data?.x]),
								y: data?.map((d: any) => d[visual?.data?.y]),
								type: visual?.data?.type || "scatter",
								textinfo: "none",
								hole: 0.8,
							},
						]}
						layout={{
							autosize: true,
							margin: { b: 10, l: 10, r: 10, t: 10 },
							showlegend: false,
							paper_bgcolor: style?.background,
							plot_bgcolor: style?.background,
							xaxis: { showgrid: false, showticklabels: false },
							yaxis: { zeroline: false, showticklabels: false },
							annotations: [{ visible: false }],
						}}
						config={{ staticPlot: true }}
						useResizeHandler
					/>
				)}
			</div>
		</Tooltip>
	);
};

export default Viz;
